
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import "./level-phase-containers.css";

export default defineComponent({
  name: "SPECIAL",
  setup() {
    const store = useStore();
    const getColor = (stat: string) => {
      const diff = store.state.build.SPECIAL[stat].onDrugs - store.state.build.SPECIAL[stat].value
      if (!diff) {
        return ''
      } else if (diff > 0) {
        return 'color: #d7ffa6;'
      } else {
        return 'color: #ffeab7;'
      }
    }
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const isImplantAvailable = (stat: string) => {
      return store.state.build.SPECIAL[stat].value < 10 &&
        !store.state.build.SPECIAL[stat].implanted &&
        store.state.build.info.currentLevel >= 30
    }
    const isImplanted = (stat: string) => {
      return store.state.build.SPECIAL[stat].implanted
    }
    const implantSPECIAL = (stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'implantSPECIAL',
        data: { stat }
      });
    }
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      SPECIAL: store.state.build.SPECIAL,
      getColor,
      showInfo,
      isImplantAvailable,
      isImplanted,
      implantSPECIAL
    };
  },
});
