import dict from "@/data/dict";

const encodeCommand = (logItem: any) => {
  const { command: commandName, data } = logItem;
  let encodedString = '';
  if (commandName === 'createChar') {
    // return `${data}/`
    return '';
  } else if (commandName === 'modifySkill') {
    if (['+', '-'].includes(data.modifier)) {
      const commandId = dict.encode.Commands[commandName];
      const skillId = dict.encode.Skills[data.skill];
      const multiplier = data.multiplier.toString(36);

      encodedString = `${commandId}${skillId}${multiplier}`;
    } else if (['book', 'boost'].includes(data.modifier)) {
      const commandId = dict.encode.Commands[commandName + data.modifier];
      const skillId = dict.encode.Skills[data.skill];
      encodedString = `${commandId}${skillId}`;
    }
  } else if (commandName === 'levelUp') {
    const commandId = dict.encode.Commands[commandName];
    const multiplier = data.multiplier === -1 ? '0' : data.multiplier.toString(36);

    encodedString = `${commandId}${multiplier}`;
  } else if (commandName === 'tagPerk') {
    const commandId = dict.encode.Commands[commandName + data.category];
    const perkId = dict.encode[data.category][data.perk];

    // fail safe for non-existing boost
    if (!perkId) {
      return ''
    }

    encodedString = `${commandId}${perkId}`;
  } else if (commandName === 'implantSPECIAL') {
    const commandId = dict.encode.Commands[commandName];
    const statId = dict.encode.SPECIAL[data.stat];

    encodedString = `${commandId}${statId}`;
    // wip
  } else if (commandName === 'implantCombat') {
    const commandId = dict.encode.Commands[commandName];
    const implantId = dict.encode.CombatImplants[data.implant]

    encodedString = `${commandId}${implantId}`;
  } else if (commandName === 'takeProfession') {
    const commandId = dict.encode.Commands[commandName];
    const professionId = dict.encode.Professions[data.profession]

    encodedString = `${commandId}${professionId}`;
  }
  if (encodedString.length === 1) {
    encodedString = '$' + encodedString
  } else if (encodedString.length === 3) {
    encodedString = '-' + encodedString
  } else if (encodedString.length === 4) {
    encodedString = '_' + encodedString
  } else if (encodedString.length === 5) {
    encodedString = ',' + encodedString
  } else if (encodedString.length > 5) {
    encodedString = '#' + encodedString + '#'
  }

  return encodedString;
}
const encodeCommandLog = (log: any[]) => log.map(logItem => encodeCommand(logItem)).join('');

const decodeCommand = (encodedCommand: string) => {
  const commandData: { [key: string]: any } = {
    command: '',
    data: {}
  };

  const [commandId, value] = encodedCommand.split('');
  const commandName: string = dict.decode.Commands[commandId];
  if (commandName) {
    if (commandName.startsWith('modifySkill')) {
      const decodedSkill = dict.decode.Skills[value];

      if (decodedSkill) {
        commandData.command = 'modifySkill';
        commandData.data.skill = decodedSkill;

        if (commandName.endsWith('book')) {
          commandData.data.modifier = 'book';
          commandData.data.multiplier = 10;
        } else if (commandName.endsWith('boost')) {
          commandData.data.modifier = 'boost';
          commandData.data.multiplier = 1;
        } else {
          commandData.data.modifier = '+';
          commandData.data.multiplier = parseInt(encodedCommand.substring(2), 36);
        }
      } else {
        commandData.command = 'error';
      }

    } else if (commandName.startsWith('tagPerk')) {
      const perkCategory = commandName.substring(7)
      const perkKey = encodedCommand.substring(1)
      const decodedPerk = dict.decode[perkCategory][perkKey];
      if (decodedPerk) {
        commandData.command = 'tagPerk';
        commandData.data.perk = decodedPerk;
        commandData.data.category = perkCategory;
      } else if (perkCategory === 'MysteryBoosts') {
        commandData.command = 'skip'
      }
    } else if (commandName.startsWith('implant')) {
      commandData.command = commandName;
      if (commandName === 'implantSPECIAL') {
        commandData.data.stat = dict.decode.SPECIAL[value];
      } else {
        commandData.data.implant = dict.decode.CombatImplants[value];
      }
    } else if (commandName === 'levelUp') {
      commandData.command = commandName;
      commandData.data.multiplier = parseInt(encodedCommand.substring(1), 36) || -1;
    } else if (commandName === 'takeProfession') {
      commandData.command = commandName;
      commandData.data.profession = dict.decode.Professions[value]
    }
  } else {
    commandData.command = 'error';
  }
  return commandData;
}

const decodeCommandLog = (log: string) => {
  let currentCommand = '';
  let commandLength = 2;
  const separators = ['$', '', '-', '_', ',', '#'];
  const commandLogArray = [];

  for (let i = 0; i < log.length; i++) {
    const character = log[i];

    if (separators.includes(character)) {
      const separatorIndex = separators.indexOf(character);
      commandLength = separatorIndex + 1;
      commandLogArray.push(currentCommand)
      currentCommand = '';
      continue;
    } else {
      if (currentCommand.length < commandLength) {
        currentCommand += character;
        if (i === log.length - 1) {
          commandLogArray.push(currentCommand)
        }
      } else if (currentCommand.length === commandLength) {
        commandLogArray.push(currentCommand);
        currentCommand = character;
        commandLength = 2;
      }
    }

  }

  return commandLogArray.map(command => decodeCommand(command));
}

const decodeBuildString = (buildString: string) => {
  const decodedData = {
    SPECIAL: [],
    Skills: [],
    Traits: [],
  }
  const special = buildString.substring(0, 7).split('').map(char => parseInt(char, 36));

  if (!special || special.length !== 7 || special.some(stat => stat < 1 || stat > 10)) {
    // special error
    decodedData.SPECIAL = ['error']
  } else {
    decodedData.SPECIAL = special;
  }

  const skills = buildString.substring(7, 10).split('').map(char => dict.decode.Skills[char])

  if (!skills || skills.length !== 3 || skills.some(stat => !stat)) {
    // skills error
    decodedData.Skills = ['error']
  } else {
    decodedData.Skills = skills;
  }

  const traits = buildString.substring(10).split('').map(char => dict.decode.Traits[char])

  if (!traits || traits.length > 2 || traits.some(stat => !stat)) {
    // traits error
    decodedData.Traits = ['error']
  } else {
    decodedData.Traits = traits;
  }

  return decodedData
}

const decodeLevelString = (levelString: string) => {
  let commandString = levelString
  const [gearDrugs, commands] = levelString.split('.')

  const decodedData = {
    gear: [],
    drugs: [],
    commandLog: []
  }

  if (commands) {
    commandString = commands
    const headGearCode = gearDrugs[0]
    const bodyGearCode = gearDrugs[1]
    const drugsCode = gearDrugs.substring(2)

    const headGearKey = dict.decode.HeadGear[headGearCode]
    const bodyGearKey = dict.decode.BodyGear[bodyGearCode]

    const drugsList = parseInt(drugsCode, 36)
      .toString(2)
      .substring(1)
      .split('')
      .reduce((acc, val, index) => {
        if (val === '1') {
          acc.push(dict.drugsList[index])
        }
        return acc
      }, [])

    decodedData.gear.push(headGearKey, bodyGearKey)
    decodedData.drugs = drugsList
  }
  decodedData.commandLog = decodeCommandLog(commandString)
  return decodedData
}

export default {
  encodeCommand,
  encodeCommandLog,
  decodeCommand,
  decodeCommandLog,
  decodeBuildString,
  decodeLevelString
}