
import { useStore } from "vuex";
import { defineComponent } from "vue";
import "./level-phase-containers.css";

export default defineComponent({
  name: "NavMenuRight",
  setup() {
    const store = useStore();
    return {
      state: store.state,
      ui: store.state.ui,
    };
  },
});
