<template>
  <div class="skill-subpanel-container">
    <span class="skill-subpanel-navbar">
      <span
        :class="
          activeSection === 'Books'
            ? 'skill-subpanel-navbar-item nav-item-selected'
            : 'skill-subpanel-navbar-item'
        "
        @click="toggleActiveSection('Books')"
        >Books</span
      >
      <span
        :class="
          activeSection === 'Professions'
            ? 'skill-subpanel-navbar-item nav-item-selected'
            : 'skill-subpanel-navbar-item'
        "
        @click="toggleActiveSection('Professions')"
        >Professions</span
      >
      <span
        :class="
          activeSection === 'Boosts'
            ? 'skill-subpanel-navbar-item nav-item-selected'
            : marshalBoostTagged () ? 'skill-subpanel-navbar-item' : 'skill-subpanel-navbar-item blink'
        "
        @click="toggleActiveSection('Boosts')"
        >Boosts</span
      >
    </span>
    <Books v-if="activeSection === 'Books'" />
    <Professions v-if="activeSection === 'Professions'" />
    <Boosts v-if="activeSection === 'Boosts'" />
  </div>
</template>
<script>
import { ref } from "vue";
import Books from "./Books.vue";
import Professions from "./Professions.vue";
import Boosts from "./Boosts.vue";
import { useStore } from 'vuex';

export default {
  components: {
    Books,
    Professions,
    Boosts,
  },
  setup() {
    const store = useStore();
    const activeSection = ref("Boosts");
    const toggleActiveSection = (value) => {
      activeSection.value = value;
    };
    const marshalBoostTagged = () => {
      return store.state.build.info.marshalBoost
    }
    return {
      state: store.state,
      activeSection,
      toggleActiveSection,
      marshalBoostTagged
    };
  },
};
</script>
<style scoped>
.skill-subpanel-container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 6px;
  background-color: rgba(192, 188, 188, 0.219);
  border: 1px solid rgba(255, 255, 255, 0.245);
  /* border: 1px solid black; */
  border-radius: 5px;
  height: 86px;
}
.skill-subpanel-navbar {
  display: flex;
  justify-content: space-evenly;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 12px;
  width: 100%;
}
.skill-subpanel-navbar-item {
  cursor: pointer;
  text-align: center;
  max-height: 12px;
  margin: 0;
  padding: 0;
}
.skill-subpanel-navbar-item:hover {
  text-shadow: 0px 0px 10px #000000;
  color: #dbffd0;
}
.nav-item-selected {
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 10px #85fc5a;
}
</style>