
import { useStore } from "vuex";
import { defineComponent, onMounted } from "vue";
import "./level-phase-containers.css";

export default defineComponent({
  name: "Support Perks",
  setup() {
    const store = useStore();
    const getButtonStyle = (category: string, perk: string) => {
      const perkData = store.state.build[category][perk];
      if (perkData.tagged) {
        return "perk-button perk-tagged";
      } else if (perkData.available) {
        return "perk-button perk-available";
      } else {
        return "perk-button perk-not-available";
      }
    };
    const tagPerk = (category: string, perk: string): void => {
      store.dispatch('handleIncomingCommand', {
        command: 'tagPerk',
        data: { category, perk }
      });
    };
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      getButtonStyle,
      tagPerk,
      showInfo,
    };
  },
});
