
import { useStore } from "vuex"
import { defineComponent, reactive, watch, ref } from "vue"
import { defaultStatNamesMap, defaultBonusStats, getItemPrefixData } from "@/data/gear-utils"
import '@/styles/range-slider.css'

export default defineComponent({
  name: "Craft Panel",
  props: ['item'],
  emits: ["equip"],
  components: {},
  setup() {
    const store = useStore()
    const itemSlot = store.state.craftedItem.slot
    const statNamesMap = {...defaultStatNamesMap}
    const bonusStats = reactive({ ...defaultBonusStats })
    const usedStats = ref([])
    const disableStats = ref(false)
    const equipAfter = ref('0')

    const getItemTypeData = () => {
      return getItemPrefixData(usedStats.value.length)
    }

    const getButtonText = () => {
      switch(itemSlot) {
        case 'head':
          return equipAfter.value === '1' ? 'CRAFT & EQUIP HELMET' : 'CRAFT HELMET'
        case 'body':
          return equipAfter.value === '1' ? 'CRAFT & EQUIP ARMOR' : 'CRAFT ARMOR'
        case 'weapon':
          return equipAfter.value === '1' ? 'CRAFT & EQUIP WEAPON' : 'CRAFT ARMOR'
        default:
          return 'CRAFT ITEM'
      }
    }

    const craftItem = () => {
      if (usedStats.value.length) {
        const craftedItem = {...store.state.craftedItem}
        const addedStats = {}
        usedStats.value.forEach(stat => addedStats[stat[0]] = +stat[1])
        craftedItem.bonusStats = addedStats
        craftedItem.prefix = getItemTypeData().prefix
        store.dispatch('saveCraftedItem', craftedItem)
        if (equipAfter.value === '1') {
          store.dispatch('equipItem', {
            slot: craftedItem.slot,
            key: craftedItem.image,
            item: craftedItem
          })
        }
      }
    }

    watch(bonusStats, (from, to) => {
      const stats = Object.entries(to).filter(stat => +stat[1] > 0)
      usedStats.value = stats
      disableStats.value = stats.length >= 5
    })

    return {
      state: store.state,
      itemSlot,
      bonusStats,
      disableStats,
      usedStats,
      getItemTypeData,
      statNamesMap,
      craftItem,
      equipAfter,
      getButtonText
    }
  },
})
