export const Inventory = {
  head: {
    NoHelmet: {
      name: 'No Helmet',
      slot: 'head',
      image: 'NoHelmet',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 0,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    LeatherHelmet: {
      name: 'Leather Helmet',
      slot: 'head',
      image: 'LeatherHelmet',
      avatar: '',
      baseStats: {
        AC: 5,
        NoDR: 20,
        NoDT: 0,
        LaDR: 20,
        LaDT: 0,
        FiDR: 10,
        FiDT: 0,
        PlDR: 10,
        PlDT: 0,
        ExDR: 20,
        ExDT: 0,
        ElDR: 15,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -5,
        Weight: 0.4,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatLeatherHelmet: {
      name: 'Combat Leather Helmet',
      slot: 'head',
      image: 'CombatLeatherHelmet',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 30,
        NoDT: 2,
        LaDR: 20,
        LaDT: 0,
        FiDR: 25,
        FiDT: 2,
        PlDR: 10,
        PlDT: 0,
        ExDR: 20,
        ExDT: 0,
        ElDR: 15,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -5,
        Weight: 0,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    LeatherArmorHelmet: {
      name: 'Leather Armor Helmet',
      slot: 'head',
      image: 'LeatherArmorHelmet',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 25,
        NoDT: 2,
        LaDR: 20,
        LaDT: 0,
        FiDR: 20,
        FiDT: 0,
        PlDR: 10,
        PlDT: 0,
        ExDR: 20,
        ExDT: 0,
        ElDR: 15,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -5,
        Weight: 0.82,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    LeatherArmorHelmetMark2: {
      name: 'Leather Armor Helmet MkII',
      slot: 'head',
      image: 'LeatherArmorHelmetMark2',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 25,
        NoDT: 3,
        LaDR: 20,
        LaDT: 1,
        FiDR: 25,
        FiDT: 1,
        PlDR: 10,
        PlDT: 1,
        ExDR: 25,
        ExDT: 1,
        ElDR: 20,
        ElDT: 1,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -5,
        Weight: 0.9,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    MetalMask: {
      name: 'Metal Mask',
      slot: 'head',
      image: 'MetalMask',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 30,
        NoDT: 4,
        LaDR: 55,
        LaDT: 4,
        FiDR: 10,
        FiDT: 4,
        PlDR: 20,
        PlDT: 4,
        ExDR: 25,
        ExDT: 4,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 2.267,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    MetalMaskMark2: {
      name: 'Metal Mask MkII',
      slot: 'head',
      image: 'MetalMaskMark2',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 35,
        NoDT: 4,
        LaDR: 55,
        LaDT: 5,
        FiDR: 15,
        FiDT: 4,
        PlDR: 25,
        PlDT: 4,
        ExDR: 30,
        ExDT: 4,
        ElDR: 10,
        ElDT: 1,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 2,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    TeslaHelmet: {
      name: 'Tesla Helmet',
      slot: 'head',
      image: 'TeslaHelmet',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 20,
        NoDT: 4,
        LaDR: 90,
        LaDT: 19,
        FiDR: 10,
        FiDT: 4,
        PlDR: 80,
        PlDT: 10,
        ExDR: 20,
        ExDT: 4,
        ElDR: 80,
        ElDT: 12,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 3,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatHelmet: {
      name: 'Combat Helmet',
      slot: 'head',
      image: 'CombatHelmet',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 40,
        NoDT: 5,
        LaDR: 35,
        LaDT: 5,
        FiDR: 25,
        FiDT: 3,
        PlDR: 35,
        PlDT: 3,
        ExDR: 40,
        ExDT: 5,
        ElDR: 30,
        ElDT: 2,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatHelmetMark2: {
      name: 'Combat Helmet MkII',
      slot: 'head',
      image: 'CombatHelmetMark2',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 7,
        LaDR: 40,
        LaDT: 6,
        FiDR: 35,
        FiDT: 5,
        PlDR: 35,
        PlDT: 4,
        ExDR: 45,
        ExDT: 9,
        ElDR: 35,
        ElDT: 3,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    BrotherhoodCombatHelmet: {
      name: 'Brotherhood Combat Helmet',
      slot: 'head',
      image: 'BrotherhoodCombatHelmet',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 8,
        LaDR: 45,
        LaDT: 4,
        FiDR: 50,
        FiDT: 7,
        PlDR: 40,
        PlDT: 5,
        ExDR: 35,
        ExDT: 4,
        ElDR: 40,
        ElDT: 8,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    NCRRangerHelmet: {
      name: 'NCR Ranger Helmet',
      slot: 'head',
      image: 'NCRRangerHelmet',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 7,
        LaDR: 40,
        LaDT: 6,
        FiDR: 35,
        FiDT: 5,
        PlDR: 35,
        PlDT: 4,
        ExDR: 45,
        ExDT: 9,
        ElDR: 35,
        ElDT: 3,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    DesertCombatHelmet: {
      name: 'Desert Combat Helmet',
      slot: 'head',
      image: 'DesertCombatHelmet',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 5,
        LaDR: 35,
        LaDT: 5,
        FiDR: 30,
        FiDT: 4,
        PlDR: 35,
        PlDT: 3,
        ExDR: 40,
        ExDT: 62,
        ElDR: 35,
        ElDT: 2,
        RadResistance: 0,
        CritChance: -15,
        CritPower: -15,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    EnclaveCombatHelmet: {
      name: 'Enclave Combat Helmet',
      slot: 'head',
      image: 'EnclaveCombatHelmet',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 6,
        LaDR: 40,
        LaDT: 6,
        FiDR: 35,
        FiDT: 5,
        PlDR: 35,
        PlDT: 4,
        ExDR: 55,
        ExDT: 3,
        ElDR: 45,
        ElDT: 9,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    EnclaveCombatHelmetMark2: {
      name: 'Enclave Combat Helmet MkII',
      slot: 'head',
      image: 'EnclaveCombatHelmetMark2',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 8,
        LaDR: 70,
        LaDT: 8,
        FiDR: 50,
        FiDT: 7,
        PlDR: 60,
        PlDT: 7,
        ExDR: 40,
        ExDT: 8,
        ElDR: 60,
        ElDT: 6,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -10,
        Weight: 1,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    PowerArmorHelmet: {
      name: 'Power Armor Helmet',
      slot: 'head',
      image: 'PowerArmorHelmet',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 12,
        LaDR: 80,
        LaDT: 18,
        FiDR: 60,
        FiDT: 12,
        PlDR: 40,
        PlDT: 10,
        ExDR: 50,
        ExDT: 20,
        ElDR: 40,
        ElDT: 6,
        RadResistance: 0,
        CritChance: -20,
        CritPower: -10,
        Weight: 4.082,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    AdvancedPowerArmorHelmet: {
      name: 'Advanced Power Armor Helmet',
      slot: 'head',
      image: 'AdvancedPowerArmorHelmet',
      avatar: '',
      baseStats: {
        AC: 30,
        NoDR: 60,
        NoDT: 16,
        LaDR: 90,
        LaDT: 19,
        FiDR: 70,
        FiDT: 14,
        PlDR: 50,
        PlDT: 13,
        ExDR: 60,
        ExDT: 20,
        ElDR: 50,
        ElDT: 7,
        RadResistance: 0,
        CritChance: -30,
        CritPower: -15,
        Weight: 4.082,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    Goggles: {
      name: 'Goggles',
      slot: 'head',
      image: 'Goggles',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    MirroredShades: {
      name: 'Mirrored Shades',
      slot: 'head',
      image: 'MirroredShades',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {
        CH: 1
      }
    },
    Spectacles: {
      name: 'Spectacles',
      slot: 'head',
      image: 'Spectacles',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    BallGag: {
      name: 'Ball Gag',
      slot: 'head',
      image: 'BallGag',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    PsychicNullifier: {
      name: 'Psychic Nullifier',
      slot: 'head',
      image: 'PsychicNullifier',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
  },
  body: {
    VaultSuit: {
      name: 'Vault Suit',
      slot: 'body',
      image: 'VaultSuit',
      avatar: '',
      baseStats: {
        AC: 0,
        NoDR: 0,
        NoDT: 0,
        LaDR: 0,
        LaDT: 0,
        FiDR: 0,
        FiDT: 0,
        PlDR: 0,
        PlDT: 0,
        ExDR: 0,
        ExDT: 0,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 0,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    Robe: {
      name: 'Robe',
      slot: 'body',
      image: 'Robe',
      avatar: '',
      baseStats: {
        AC: 5,
        NoDR: 15,
        NoDT: 0,
        LaDR: 25,
        LaDT: 0,
        FiDR: 10,
        FiDT: 0,
        PlDR: 10,
        PlDT: 0,
        ExDR: 15,
        ExDT: 0,
        ElDR: 40,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1.535,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    PurpleRobe: {
      name: 'Purple Robe',
      slot: 'body',
      image: 'PurpleRobe',
      avatar: '',
      baseStats: {
        AC: 5,
        NoDR: 15,
        NoDT: 0,
        LaDR: 25,
        LaDT: 0,
        FiDR: 10,
        FiDT: 0,
        PlDR: 10,
        PlDT: 0,
        ExDR: 15,
        ExDT: 0,
        ElDR: 40,
        ElDT: 0,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 1.535,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    BlackRobe: {
      name: 'Black Robe',
      slot: 'body',
      image: 'BlackRobe',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 25,
        NoDT: 2,
        LaDR: 20,
        LaDT: 0,
        FiDR: 35,
        FiDT: 4,
        PlDR: 10,
        PlDT: 0,
        ExDR: 30,
        ExDT: 2,
        ElDR: 30,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -5,
        CritPower: -5,
        Weight: 3.175,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    PoliceUniform: {
      name: 'Police Uniform',
      slot: 'body',
      image: 'PoliceUniform',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 35,
        NoDT: 5,
        LaDR: 30,
        LaDT: 5,
        FiDR: 35,
        FiDT: 6,
        PlDR: 30,
        PlDT: 4,
        ExDR: 35,
        ExDT: 7,
        ElDR: 30,
        ElDT: 5,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 5,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    Pants: {
      name: 'Pants',
      slot: 'body',
      image: 'Pants',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 40,
        NoDT: 6,
        LaDR: 65,
        LaDT: 7,
        FiDR: 30,
        FiDT: 4,
        PlDR: 50,
        PlDT: 5,
        ExDR: 40,
        ExDT: 6,
        ElDR: 50,
        ElDT: 3,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 4,
      },
      statsAllowed: false,
      prefix: '',
      bonusStats: {}
    },
    LeatherJacket: {
      name: 'Leather Jacket',
      slot: 'body',
      image: 'LeatherJacket',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 20,
        NoDT: 0,
        LaDR: 20,
        LaDT: 0,
        FiDR: 20,
        FiDT: 0,
        PlDR: 10,
        PlDT: 0,
        ExDR: 20,
        ExDT: 0,
        ElDR: 30,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -5,
        CritPower: -5,
        Weight: 3,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatLeatherJacket: {
      name: 'Combat Leather Jacket',
      slot: 'body',
      image: 'CombatLeatherJacket',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 25,
        NoDT: 2,
        LaDR: 20,
        LaDT: 0,
        FiDR: 40,
        FiDT: 4,
        PlDR: 10,
        PlDT: 0,
        ExDR: 30,
        ExDT: 2,
        ElDR: 30,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -5,
        CritPower: -5,
        Weight: 3,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    LeatherArmor: {
      name: 'Leather Armor',
      slot: 'body',
      image: 'LeatherArmor',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 25,
        NoDT: 3,
        LaDR: 20,
        LaDT: 0,
        FiDR: 30,
        FiDT: 3,
        PlDR: 10,
        PlDT: 0,
        ExDR: 30,
        ExDT: 3,
        ElDR: 30,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -5,
        Weight: 4,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    LeatherArmorMark2: {
      name: 'Leather Armor MkII',
      slot: 'body',
      image: 'LeatherArmorMark2',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 30,
        NoDT: 4,
        LaDR: 20,
        LaDT: 0,
        FiDR: 35,
        FiDT: 4,
        PlDR: 10,
        PlDT: 0,
        ExDR: 35,
        ExDT: 4,
        ElDR: 40,
        ElDT: 1,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -5,
        Weight: 5,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    ThermalLeatherArmor: {
      name: 'Thermal Leather Armor',
      slot: 'body',
      image: 'ThermalLeatherArmor',
      avatar: '',
      baseStats: {
        AC: 10,
        NoDR: 30,
        NoDT: 4,
        LaDR: 20,
        LaDT: 0,
        FiDR: 75,
        FiDT: 4,
        PlDR: 10,
        PlDT: 0,
        ExDR: 35,
        ExDT: 4,
        ElDR: 40,
        ElDT: 1,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -5,
        Weight: 5,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    MetalJacket: {
      name: 'Metal Jacket',
      slot: 'body',
      image: 'MetalJacket',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 30,
        NoDT: 4,
        LaDR: 20,
        LaDT: 0,
        FiDR: 35,
        FiDT: 4,
        PlDR: 10,
        PlDT: 0,
        ExDR: 35,
        ExDT: 4,
        ElDR: 40,
        ElDT: 1,
        RadResistance: 0,
        CritChance: 0,
        CritPower: 0,
        Weight: 16,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    MetalArmor: {
      name: 'Metal Armor',
      slot: 'body',
      image: 'MetalArmor',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 25,
        NoDT: 2,
        LaDR: 75,
        LaDT: 6,
        FiDR: 10,
        FiDT: 0,
        PlDR: 20,
        PlDT: 4,
        ExDR: 20,
        ExDT: 1,
        ElDR: 0,
        ElDT: 0,
        RadResistance: 0,
        CritChance: -5,
        CritPower: -10,
        Weight: 16,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    MetalArmorMark2: {
      name: 'Metal Armor MkII',
      slot: 'body',
      image: 'MetalArmorMark2',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 30,
        NoDT: 3,
        LaDR: 80,
        LaDT: 7,
        FiDR: 10,
        FiDT: 0,
        PlDR: 25,
        PlDT: 4,
        ExDR: 25,
        ExDT: 2,
        ElDR: 10,
        ElDT: 1,
        RadResistance: 0,
        CritChance: -5,
        CritPower: -10,
        Weight: 16,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    TeslaArmor: {
      name: 'Tesla Armor',
      slot: 'body',
      image: 'TeslaArmor',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 25,
        NoDT: 3,
        LaDR: 85,
        LaDT: 10,
        FiDR: 10,
        FiDT: 0,
        PlDR: 75,
        PlDT: 10,
        ExDR: 20,
        ExDT: 1,
        ElDR: 80,
        ElDT: 12,
        RadResistance: 20,
        CritChance: -5,
        CritPower: -10,
        Weight: 16,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    BridgekeepersRobes: {
      name: 'Bridgekeeper\'s Robes',
      slot: 'body',
      image: 'BridgekeepersRobes',
      avatar: '',
      baseStats: {
        AC: 15,
        NoDR: 45,
        NoDT: 5,
        LaDR: 60,
        LaDT: 8,
        FiDR: 25,
        FiDT: 4,
        PlDR: 50,
        PlDT: 4,
        ExDR: 40,
        ExDT: 6,
        ElDR: 50,
        ElDT: 2,
        RadResistance: 0,
        CritChance: -10,
        CritPower: -10,
        Weight: 5,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatArmor: {
      name: 'Combat Armor',
      slot: 'body',
      image: 'CombatArmor',
      avatar: '',
      baseStats: {
        AC: 20,
        NoDR: 40,
        NoDT: 5,
        LaDR: 60,
        LaDT: 6,
        FiDR: 20,
        FiDT: 3,
        PlDR: 50,
        PlDT: 4,
        ExDR: 40,
        ExDT: 5,
        ElDR: 45,
        ElDT: 2,
        RadResistance: 20,
        CritChance: -10,
        CritPower: -10,
        Weight: 9,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    NCRRangerArmor: {
      name: 'NCR Ranger Armor',
      slot: 'body',
      image: 'NCRRangerArmor',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 7,
        LaDR: 65,
        LaDT: 7,
        FiDR: 25,
        FiDT: 4,
        PlDR: 50,
        PlDT: 5,
        ExDR: 40,
        ExDT: 6,
        ElDR: 50,
        ElDT: 3,
        RadResistance: 20,
        CritChance: -10,
        CritPower: -10,
        Weight: 9,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    DesertCombatArmor: {
      name: 'Desert Combat Armor',
      slot: 'body',
      image: 'DesertCombatArmor',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 5,
        LaDR: 60,
        LaDT: 6,
        FiDR: 20,
        FiDT: 3,
        PlDR: 50,
        PlDT: 4,
        ExDR: 65,
        ExDT: 9,
        ElDR: 45,
        ElDT: 2,
        RadResistance: 20,
        CritChance: -15,
        CritPower: -15,
        Weight: 9,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    CombatArmorMark2: {
      name: 'Combat Armor MkII',
      slot: 'body',
      image: 'CombatArmorMark2',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 6,
        LaDR: 65,
        LaDT: 7,
        FiDR: 25,
        FiDT: 4,
        PlDR: 50,
        PlDT: 5,
        ExDR: 40,
        ExDT: 6,
        ElDR: 50,
        ElDT: 3,
        RadResistance: 20,
        CritChance: -10,
        CritPower: -10,
        Weight: 11,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    BrotherhoodArmor: {
      name: 'Brotherhood Armor',
      slot: 'body',
      image: 'BrotherhoodArmor',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 8,
        LaDR: 70,
        LaDT: 8,
        FiDR: 35,
        FiDT: 7,
        PlDR: 60,
        PlDT: 7,
        ExDR: 40,
        ExDT: 8,
        ElDR: 60,
        ElDT: 6,
        RadResistance: 20,
        CritChance: -10,
        CritPower: -10,
        Weight: 11,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    EnclaveArmor: {
      name: 'Enclave Armor',
      slot: 'body',
      image: 'EnclaveArmor',
      avatar: '',
      baseStats: {
        AC: 25,
        NoDR: 40,
        NoDT: 6,
        LaDR: 70,
        LaDT: 8,
        FiDR: 25,
        FiDT: 4,
        PlDR: 55,
        PlDT: 6,
        ExDR: 40,
        ExDT: 6,
        ElDR: 55,
        ElDT: 5,
        RadResistance: 20,
        CritChance: -10,
        CritPower: -10,
        Weight: 11,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {}
    },
    PowerArmor: {
      name: 'Power Armor',
      slot: 'body',
      image: 'PowerArmor',
      avatar: '',
      baseStats: {
        AC: 30,
        NoDR: 40,
        NoDT: 12,
        LaDR: 80,
        LaDT: 18,
        FiDR: 50,
        FiDT: 12,
        PlDR: 40,
        PlDT: 10,
        ExDR: 50,
        ExDT: 20,
        ElDR: 40,
        ElDT: 12,
        RadResistance: 30,
        CritChance: -15,
        CritPower: -15,
        Weight: 39,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {
        ST: 3
      }
    },
    HardenedPowerArmor: {
      name: 'Hardened Power Armor',
      slot: 'body',
      image: 'HardenedPowerArmor',
      avatar: '',
      baseStats: {
        AC: 30,
        NoDR: 50,
        NoDT: 13,
        LaDR: 90,
        LaDT: 19,
        FiDR: 70,
        FiDT: 14,
        PlDR: 50,
        PlDT: 13,
        ExDR: 60,
        ExDT: 20,
        ElDR: 50,
        ElDT: 13,
        RadResistance: 30,
        CritChance: -15,
        CritPower: -15,
        Weight: 55,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {
        ST: 3
      }
    },
    AdvancedPowerArmor: {
      name: 'Advanced Power Armor',
      slot: 'body',
      image: 'AdvancedPowerArmor',
      avatar: '',
      baseStats: {
        AC: 30,
        NoDR: 50,
        NoDT: 15,
        LaDR: 90,
        LaDT: 19,
        FiDR: 70,
        FiDT: 16,
        PlDR: 60,
        PlDT: 15,
        ExDR: 65,
        ExDT: 20,
        ElDR: 60,
        ElDT: 15,
        RadResistance: 30,
        CritChance: -15,
        CritPower: -15,
        Weight: 41,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {
        ST: 3
      }
    },
    AdvancedPowerArmorMark2: {
      name: 'Advanced Power Armor MkII',
      slot: 'body',
      image: 'AdvancedPowerArmorMark2',
      avatar: '',
      baseStats: {
        AC: 30,
        NoDR: 60,
        NoDT: 18,
        LaDR: 90,
        LaDT: 19,
        FiDR: 60,
        FiDT: 16,
        PlDR: 60,
        PlDT: 18,
        ExDR: 70,
        ExDT: 20,
        ElDR: 75,
        ElDT: 15,
        RadResistance: 30,
        CritChance: -15,
        CritPower: -15,
        Weight: 46,
      },
      statsAllowed: true,
      prefix: '',
      bonusStats: {
        ST: 3
      }
    },
  },
  melee: {},
  throwing: {},
  smallGuns: {},
  bigGuns: {},
  energyWeapons: {},
  ammo: {}
}