<template>
  <div
    class="table-wrapper"
  >
    <table>
      <tr>
        <th scope="row" class="no-border">Level</th>
        <td>{{ state.build.info.currentLevel }}</td>
        <td>
            <div>
              <span style="color: #85fc5a;">HP:</span>
              {{ Math.round(state.build.CharStats.HitPoints.value) }}
            </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="no-border">XP</th>
        <td>{{ getLevelData.xp }}</td>
        <td rowspan="6">
          <div style="min-width: 65px;">
            <img :src="getAvatar()" alt="" />
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="no-border" :title="`XP needed to level up: ${getLevelData.step}`">Next Lvl</th>
        <td :title="`XP needed to level up: ${getLevelData.step}`">{{ getLevelData.next }}</td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Trait 1</th>
        <td style="min-width: 110px;">{{ state.build.info.Traits[0] || 'None' }}</td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Trait 2</th>
        <td style="min-width: 110px;">{{ state.build.info.Traits[1] || 'None' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, defineComponent } from "vue"
export default defineComponent({
 name: "CharacterTable",
 components: {},
 setup() {
   const store = useStore()
   const getLevelData = computed(() => {
     const xp = store.state.build.info.currentLevel * ((store.state.build.info.currentLevel - 1) * 1000) / 2
     const step = store.state.build.info.currentLevel * 1000
     return {
       xp,
       step,
       next: xp + step,
     }
   })
   
   const getAvatar = () => {
    return store.getters['getAvatar'].image
   };
   
    return {
      state: store.state,
      getLevelData,
      getAvatar
   }
 },
})
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
}
table {
  background-color: rgba(192, 188, 188, 0.219);
  width: 95%;
  margin: auto;
  row-gap: 0px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 3px;
}
td {
  border: 1px solid rgba(255, 255, 255, 0.285);
  border-radius: 3px;
  font-size: 16px;
  line-height: 16px;
  text-shadow: 1px 1px 3px black;
  font-weight: normal;
  color: white;
}
th {
  background: transparent;
  font-weight: normal;
}
tr, td {
  height: 17px;
}
tr:nth-child(2n + 2) {
  background: rgba(45, 54, 38, 0.37);
}
.no-border {
  border: 1px transparent;
  border-radius: 3px;
}
</style>