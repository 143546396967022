
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import "./level-phase-containers.css";
import CharacterTable from "./charStats/CharacterTable.vue";
import CharStatsTable from "./charStats/CharStatsTable.vue";
import ResistanceTable from "./charStats/ResistanceTable.vue";
import GearTable from "./charStats/GearTable.vue";
import EquipPanel from "./charStats/EquipPanel.vue";
import CraftPanel from "./charStats/CraftPanel.vue";



export default defineComponent({
  name: "CharStats",
  components: {
    CharacterTable,
    CharStatsTable,
    ResistanceTable,
    GearTable,
    EquipPanel,
    CraftPanel
},
  setup() {
    const store = useStore();
    const panelState = ref('default')
    const equipGear = (slot: string) => {
      panelState.value = slot ? slot : 'default'
    }
    return {
      state: store.state,
      equipGear,
      panelState
    };
  },
});
