
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import "../level/level-phase-containers.css";

export default defineComponent({
  name: "Control Panel",
  setup() {
    const store = useStore();
    const toggleLoadMenu = () => {
      store.dispatch('toggleLoadMenu')
    }
    const toggleSaveMenu = () => {
      store.dispatch('toggleSaveMenu')
    }
    const toggleInventoryMenu = () => {
      store.dispatch('toggleInventoryMenu')
    }
    const resetBuild = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'resetBuild',
        data: null
      });
      
    }

    const disableSave = computed(() => {
      return !(
        store.state.build.info.taggedSkills === 3 &&
        store.state.build.SPECIAL.PL.value === 0
      );
    });
    return {
      state: store.state,
      ui: store.state.ui,
      resetBuild,
      toggleLoadMenu,
      toggleSaveMenu,
      toggleInventoryMenu,
      disableSave
    };
  },
});
