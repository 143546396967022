<template>
  <div
    class="table-wrapper"
  >
    <table>
      <!-- FIRST ROW -->
      <tr>
        <td
          colspan="4"
          class="th-character-name"
          @click="compactMode = !compactMode"
          :title="compactMode ? 'Click to expand' : 'Click to collapse'"
        >
          <div class="role-bg">{{ char.role.toUpperCase() }}</div>
          <div class="character-name">{{ char.name }}</div>
          <div class="character-class" v-if="char.class">{{ char.class }}</div>
        </td>
      </tr>

      <!-- SECOND ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">S</th>
        <td class="td-special-value">{{ SPECIAL[0] }}</td>
        <th scope="row" class="no-border td-skill-value bg-dark">Skills</th>
        <td class="no-border width-75">
            <div style="width: 100%;">
              <span style="color: #85fc5a;">Level:</span>
              {{ char.level }}
            </div>
        </td>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
        <th scope="row" class="no-border width-25 bg-dark">Class</th>
        <td colspan="3" class="td-class">{{ char.class }}</td>
      </tr>
      
      <!-- THIRD ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">P</th>
        <td class="td-special-value">{{ SPECIAL[1] }}</td>
        <td class="td-skill-value">{{ state.build.Skills[Skills[0]].name }}</td>
        <td class="no-border">
            <div>
              <span style="color: #85fc5a;">HP:</span>
              {{ Math.round(char.HP) }}
            </div>
        </td>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
        <th scope="row" class="no-border width-25 bg-dark">Role</th>
        <td colspan="3" class="td-role">{{ char.role }}</td>
      </tr>
      
      <!-- FOURTH ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">E</th>
        <td class="td-special-value">{{ SPECIAL[2] }}</td>
        <td class="td-skill-value">{{ state.build.Skills[Skills[1]].name }}</td>
        <td rowspan="5" style="min-width: 65px; position: relative;">
          <div class="image-panel">
            <div class="icons-container mb-10">
              <div class="icon-svg info-icon" @click="viewMode = viewMode === 'info' ? 'regular' : 'info'">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,3H14V6H10V3H7A2,2 0 0,0 5,5V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V5A2,2 0 0,0 17,3M12,8A2,2 0 0,1 14,10A2,2 0 0,1 12,12A2,2 0 0,1 10,10A2,2 0 0,1 12,8M16,16H8V15C8,13.67 10.67,13 12,13C13.33,13 16,13.67 16,15V16M13,5H11V1H13V5M16,19H8V18H16V19M12,21H8V20H12V21Z" />
                </svg>
              </div>
              <div class="icon-svg" :title="char.levelString.length > 215 ? 'The generated link is longer than allowed and it will be cut short to its maximum length. The build may then load with errors' : 'Copy a link to this build'" @click="copyLinkToClipboard">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12S8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5S19.66 2 18 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12S4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91S20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08M18 4C18.55 4 19 4.45 19 5S18.55 6 18 6 17 5.55 17 5 17.45 4 18 4M6 13C5.45 13 5 12.55 5 12S5.45 11 6 11 7 11.45 7 12 6.55 13 6 13M18 20C17.45 20 17 19.55 17 19S17.45 18 18 18 19 18.45 19 19 18.55 20 18 20Z" />
                </svg>
              </div>
              <div class="icon-svg" title="Copy the save slot as JSON" @click="copyCharToClipboard">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                </svg>
              </div>
              <div :class="preset ? 'icon-svg-disabled delete-icon' : 'icon-svg delete-icon'" :title="preset ? 'Cannot delete presets' : 'Delete this build'" @click="preset ? null : showDeleteMenu = !showDeleteMenu">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path :fill="preset ? 'gray' : 'currentColor'" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                </svg>
              </div>
            </div>
            <div class="avatar-wrapper">
              <img :src="getAvatar(char.avatar)" alt="" />
            </div>
          </div>
        </td>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
        <th scope="row" class="no-border width-25 bg-dark">Author</th>
        <td colspan="2" class="td-author" :title="char.author">{{ char.author }}</td>
        <td style="width: 65px; position: relative;">
          <div>
            <div class="icons-container">
              <div class="icon-svg info-icon" @click="viewMode = viewMode === 'info' ? 'regular' : 'info'">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,3H14V6H10V3H7A2,2 0 0,0 5,5V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V5A2,2 0 0,0 17,3M12,8A2,2 0 0,1 14,10A2,2 0 0,1 12,12A2,2 0 0,1 10,10A2,2 0 0,1 12,8M16,16H8V15C8,13.67 10.67,13 12,13C13.33,13 16,13.67 16,15V16M13,5H11V1H13V5M16,19H8V18H16V19M12,21H8V20H12V21Z" />
                </svg>
              </div>
              <div class="icon-svg" :title="char.levelString.length > 215 ? 'The generated link is longer than allowed and it will be cut short to its maximum length. The build may then load with errors' : 'Copy a link to this build'" @click="copyLinkToClipboard">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12S8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5S19.66 2 18 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12S4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91S20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08M18 4C18.55 4 19 4.45 19 5S18.55 6 18 6 17 5.55 17 5 17.45 4 18 4M6 13C5.45 13 5 12.55 5 12S5.45 11 6 11 7 11.45 7 12 6.55 13 6 13M18 20C17.45 20 17 19.55 17 19S17.45 18 18 18 19 18.45 19 19 18.55 20 18 20Z" />
                </svg>
              </div>
              <div class="icon-svg" title="Copy the save slot as JSON" @click="copyCharToClipboard">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                </svg>
              </div>
              <div :class="preset ? 'icon-svg-disabled delete-icon' : 'icon-svg delete-icon'" :title="preset ? 'Cannot delete presets' : 'Delete this build'" @click="preset ? null : showDeleteMenu = !showDeleteMenu">
                <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                  <path :fill="preset ? 'gray' : 'currentColor'" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                </svg>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <!-- FIFTH ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">C</th>
        <td class="td-special-value">{{ SPECIAL[3] }}</td>
        <td class="td-skill-value">{{ state.build.Skills[Skills[2]].name }}</td>
      </tr>
        <tr v-else-if="!compactMode && viewMode === 'info'">
        <td colspan="4" rowspan="4" class="td-notes">
          <div class="div-notes">
            {{ char.notes }}
          </div>
        </td>
      </tr>

      <!-- SIXTH ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'" class="tr-notes">
        <th scope="row" class="no-border width-25 bg-dark">I</th>
        <td class="td-special-value">{{ SPECIAL[4] }}</td>
        <th scope="row" class="td-special-value bg-dark">Traits</th>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
      </tr>
      
      <!-- SEVENTH ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">A</th>
        <td class="td-special-value">{{ SPECIAL[5] }}</td>
        <td class="td-skill-value">{{ (Traits.length && state.build.Traits[Traits[0]].name) || '-' }}</td>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
      </tr>

      <!-- EIGHT ROW -->
      <tr v-if="!compactMode && viewMode === 'regular'">
        <th scope="row" class="no-border width-25 bg-dark">L</th>
        <td class="td-special-value">{{ SPECIAL[6] }}</td>
        <td class="td-skill-value">{{ (Traits.length > 1 && state.build.Traits[Traits[1]].name) || '-' }}</td>
      </tr>
      <tr v-else-if="!compactMode && viewMode === 'info'">
      </tr>
      
      <!-- NINTH ROW -->
      <tr class="bg-dark" v-if="!showDeleteMenu">
        <th colspan="4">
          <div class="load-buttons-container">
            <div class="load-button-header">Load:</div>
            <div class="load-button bg-dark" @click="loadToLevel(0)" title="Click to load the build before creation">0</div>
            <div class="load-button bg-dark" @click="loadToLevel(1)" title="Click to load at level 1">1</div>
            <div :class="'load-button bg-dark' + (char.level < 24 ? ' disabled-button' : '')" @click="loadToLevel(24)" title="Click to load at level 24">24</div>
            <div :class="'load-button bg-dark' + (char.level <= 1 ? ' disabled-button' : '')" @click="loadToLevel(char.level)" :title="'Click to load at level ' + char.level">{{ char.level || '-' }}</div>
          </div>
        </th>
      </tr>
      
      <!-- LAST ROW -->
      <tr class="bg-dark" v-if="showDeleteMenu">
        <th colspan="4">
          <div class="load-buttons-container">
            <div class="delete-button bg-dark" @click="deleteCharacter">DELETE</div>
            <div class="cancel-button bg-dark" @click="showDeleteMenu = !showDeleteMenu">Cancel</div>
          </div>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { defineComponent, ref } from "vue"
import logUtils from "@/utils/logUtils";
export default defineComponent({
 name: "CharacterTable",
 props: ['char', 'preset'],
 components: {},
 setup(props) {
   const { SPECIAL, Skills, Traits } = logUtils.decodeBuildString(props.char.buildString);

   const store = useStore()
   const showDeleteMenu = ref(false)
   const viewMode = ref('regular')
   const regularMode = ref(true)
   const compactMode = ref(false)
   const infoMode = ref(false)

   const getAvatar = (image) => {
      let img;
      try {
        img = require(`@/assets/img/gear/avatars/${image}.gif`);
      } catch (e) {
        img = require("@/assets/img/gear/avatars/VaultSuit.gif");
      }
      return img;
    };

    const loadToLevel = (level) => {
      store.dispatch('loadSavedCharacter', props.char)
      store.dispatch('loadToLevel', level)
    }

    const deleteCharacter = () => {
      store.dispatch('deleteCharacter', props.char.name)
    }

    const copyLinkToClipboard = () => {
      let url = 'https://geck.onrender.com/' + props.char.buildString + '/' + props.char.levelString
      if (url.length > 256) {
        url = url.substring(0, 257)
      }
      navigator.clipboard.writeText(url)
    }
    const copyCharToClipboard = () => {
      const charString = JSON.stringify(props.char)
      navigator.clipboard.writeText(charString)
    }
   
    return {
      state: store.state,
      getAvatar,
      showDeleteMenu,
      viewMode,
      regularMode,
      compactMode,
      infoMode,
      SPECIAL,
      Skills,
      Traits,
      loadToLevel,
      deleteCharacter,
      copyLinkToClipboard,
      copyCharToClipboard
   }
 },
})
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
table {
  background-color: rgba(192, 188, 188, 0.219);
  width: 95%;
  margin: auto;
  row-gap: 0px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 3px;
}
table:hover {
  border: 1px solid rgba(145, 255, 55, 0.728);
}
td {
  border: 1px solid rgba(255, 255, 255, 0.285);
  border-radius: 3px;
  font-size: 14px;
  line-height: 14px;
  text-shadow: 1px 1px 3px black;
  font-weight: normal;
  color: white;
}
th {
  background: transparent;
  font-weight: normal;
}
tr, td {
  height: 16px;
}
svg {
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
}
.no-border {
  border: 1px transparent;
  border-radius: 3px;
}
.td-class, .td-role, .td-author, .td-notes {
  text-align: left;
  padding-left: 5px;
}
.div-notes {
  max-height: 85px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.td-notes {
  height: 85px;
}
.td-author {
  max-width: 133px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.td-special-value {
  width: 25px;
}
.td-skill-value {
  width: 125px;
  font-size: 14px;
  white-space: nowrap;
}
.th-character-name {
  position: relative;
  font-size: 16px;
  color: #dbdb34;
  height: 36px;
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}
.character-name, .character-class {
  max-width: 275px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.character-class {
  font-size: 14px;
  color: #d6d6a1;
  margin-top: 8px;
}
.role-bg {
  position: absolute;
  color: #dddd19ce;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 20px;
  line-height: 20px;
  top: 10px;
  left: 4px;
  z-index: -1;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.width-25 {
  width: 25px;
}
.width-75 {
  width: 75px;
}
.bg-dark {
  background: rgba(45, 54, 38, 0.37);
}
.load-buttons-container {
  display: flex;
  justify-content: space-around;
  /* min-height: 20px; */
}
.load-button-header {
  padding: 0;
  margin: 6px 0px;
  height: 16px;
  line-height: 16px;
}
.load-button {
  padding: 0;
  width: 40px;
  height: 16px;
  line-height: 16px;
  margin: 5px 0px;
  color: #ffffff;
  font-family: "Fixedsys Excelsior 3.01", Helvetica, Arial;
  text-shadow: 1px 1px rgb(48, 41, 41);
  border: 1px solid #85fc5a;
  border-radius: 3px;
}
.load-button:focus {
  box-shadow: none;
  outline: none;
}
.load-button:hover {
  border: 1px solid white;
  border-radius: 1px;
  cursor: pointer;
  width: 40px;
  color: khaki;
}

.delete-button {
  border: 1px solid #de9d9d;
  color: #de9d9d;
  border-radius: 1px;
  width: 40%;
  height: 16px;
  line-height: 16px;
  margin: 5px 0px;
}
.cancel-button {
  border: 1px solid #2ef800;
  color: #62f740;
  border-radius: 1px;
  width: 40%;
  height: 16px;
  line-height: 16px;
  margin: 5px 0px;
}
.delete-button:focus, .cancel-button:focus {
  box-shadow: none;
  outline: none;
}
.delete-button:hover {
  border: 1px solid #dcb6b6;
  border-radius: 1px;
  color: #dcb6b6;
  cursor: pointer;
}
.cancel-button:hover {
  border: 1px solid #99f185;
  border-radius: 1px;
  color: #99f185;
  cursor: pointer;
}
.image-panel {
  display: flex;
  flex-direction: column;
  height: 106px;
}
.avatar-wrapper {
  min-width: 45px;
  max-height: 65px;
}
.icons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 75px;
}
.mb-10 {
  margin-bottom: 10px;
}
.icons-flex-col {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.icon-svg {
  height: 16px;
  color: khaki;
}
.icon-svg-disabled {
  height: 16px;
  color: grey;
}
.icon-svg:hover {
  color: #ededc1;
  cursor: pointer;
}
.info-icon {
  color: khaki;
}
.delete-icon {
  color: #de9d9d;
}
.delete-icon:hover {
  color: #dcb6b6;
}
.disabled-button, .disabled-button:hover {
  background-color: grey;
  cursor: initial;
  border: 1px solid transparent;
  color: rgb(187, 187, 187);
}
</style>