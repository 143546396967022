<template>
  <div class="select-skill-container">
    <div class="select-skill-skillname">{{ getSelectedSkillData().name }}</div>
    <div class="select-skill-tagged" v-if="getSelectedSkillData().key">
      {{ getSelectedSkillData().tagged ? "tagged" : "" }}
    </div>
    <div>
      <button
        v-if="getSelectedSkillData().key"
        :class="'select-skill-minus' + (getDisabledButton('-') ? '' : ' box-shadow-transition-red')"
        :disabled="getDisabledButton('-')"
        @click="modifySkill('-')"
      >
        <span class="button-span">--</span>
      </button>
      <div class="select-skill-skillpercent" v-if="getSelectedSkillData().key">
        {{
          getSelectedSkillData().value ? getSelectedSkillData().value + "%" : ""
        }}
      </div>
      <button
        v-if="getSelectedSkillData().key"
        :class="'select-skill-plus' + (getDisabledButton('+') ? '' : ' box-shadow-transition-green')"
        :disabled="getDisabledButton('+')"
        @click="modifySkill('+')"
      >
        <span class="button-span">+</span>
      </button>
      <div class="select-skill-progress" v-if="getSelectedSkillData().key" @click="changeProgressBarStyle()">
        <div class="progress-div" :style="getSelectedSkillData().progressStyle">
          <div class="progress-div-text">{{ getSelectedSkillData().progressText }}</div>
        </div>
      </div>
      <div class="select-skill-multiplier-button-container" v-if="getSelectedSkillData().key">
        <div
          class="select-skill-multiplier-button"
          :style="
            multiplier === 1 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(1)"
        >
          x1
        </div>
        <div
          class="select-skill-multiplier-button"
          :style="
            multiplier === 5 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(5)"
        >
          x5
        </div>
        <div
          class="select-skill-multiplier-button"
          :style="
            multiplier === 10 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(10)"
        >
          x10
        </div>
        <div
          class="select-skill-multiplier-button"
          :style="
            multiplier === 100 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(100)"
        >
          x100
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import getThresholdData from '@/utils/getSkillThreshold'

export default {
  name: "Select Skill",
  setup() {
    const store = useStore();
    const multiplier = ref(1);
    const toggleMultiplier = (value) => {
      multiplier.value = value;
    };
    const getSelectedSkillData = () => {
      const skillData = {
        name: "Select Skill",
        key: "",
        value: 0,
        maxValue: 0,
        tagged: false,
        progress: 0,
        progressStyle: '',
        progressText: '',
        cost: 0
      };
      Object.keys(store.state.build.Skills).forEach((skill) => {
        if (store.state.build.Skills[skill].selected) {
          skillData.name = store.state.build.Skills[skill].name;
          skillData.key = skill;
          skillData.value = store.state.build.Skills[skill].value;
          skillData.maxValue = store.state.build.Skills[skill].maxValue;
          skillData.tagged = store.state.build.Skills[skill].tagged;
          skillData.cost = getThresholdData(store.state.build.Skills[skill].value).cost
        }
      });
      skillData.progress = Math.floor((skillData.value / skillData.maxValue) * 100)
      if (skillData.progress < skillData.maxValue) {
        skillData.progressStyle = `width: ${skillData.progress}%;`
      } else {
        skillData.progressStyle = `width: ${skillData.progress}%; border: none;`
      }
      const activeStyle = store.state.ui.progressBarStyle.activeStyle
      switch (activeStyle) {
        case 'default':
          skillData.progressText = `${skillData.value} / ${skillData.maxValue} ( ${skillData.progress}% )`
          break
        case 'currentAndMax':
          skillData.progressText = `${skillData.value} / ${skillData.maxValue}`
          break
        case 'percent':
          skillData.progressText = `${skillData.progress}%`
          break
        case 'none':
          break
      }
      return skillData;
    };
    const modifySkill = (modifier) => {
      const data = {
        skill: getSelectedSkillData().key,
        modifier,
        multiplier: multiplier.value,
      };
      store.dispatch('handleIncomingCommand', {
        command: 'modifySkill',
        data: data
      });
    };
    const changeProgressBarStyle = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'changeProgressBarStyle',
        data: null
      });
    }
    const getDisabledButton = (modifier) => {
      if (modifier === '-') {
        return store.state.build.Skills[getSelectedSkillData().key].stepSkillValue === 0
      } else if (modifier === '+') {
        return store.state.build.info.availableSkillPoints < getSelectedSkillData().cost
      }
    }
    return {
      state: store.state,
      multiplier,
      toggleMultiplier,
      getSelectedSkillData,
      modifySkill,
      changeProgressBarStyle,
      getDisabledButton
    };
  },
};
</script>
<style scoped>
.select-skill-container {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: rgba(192, 188, 188, 0.219);
  /* border: 1px solid black; */
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 5px;
  height: 100px;
  width: 95%;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
}
.select-skill-skillname {
  display: inline-block;
  width: 100%;
  height: 14px;
  font-size: 16px;
  text-shadow: 2px 2px 3px rgb(44, 43, 43);
  margin-bottom: 3px;
  color: #f9ffb6;
}
.select-skill-tagged {
  font-size: 12px;
  color: khaki;
  max-height: 12px;
  min-height: 12px;
  margin-bottom: 3px;
}
.select-skill-minus,
.select-skill-plus {
  display: inline-block;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  background: transparent;
  padding: 0px;
  width: 26px;
  height: 26px;
  line-height: 1px;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 22px;
  font-weight: 700;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 5px;
}
.button-span {
  text-align: center;
  padding: 0px;
  line-height: 2px;
}
.select-skill-minus {
  letter-spacing: -1px;
  color: #de9d9dc6;
}
.select-skill-plus {
  color: rgba(107, 179, 111, 0.775);
}
.select-skill-minus:enabled:hover {
  color: rgb(255, 144, 144);
}
.select-skill-plus:enabled:hover {
  color: rgb(103, 235, 110);
}
.select-skill-plus:enabled:active,
.select-skill-minus:enabled:active {
  transform: scale(0.95);
}

button:disabled,
button[disabled]{
  cursor: not-allowed;
  color: #a5a5a5;
}
.select-skill-progress {
  position: relative;
  display: block;
  margin: auto;
  margin-top: 3px;
  width: 95%;
  height: 16px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 3px;
  background-color: rgba(81, 134, 81, 0.158);
}
.progress-div {
  max-width: 135px;
  background-color: rgba(80, 168, 80, 0.747);
  height: 14px;
  border: 1px solid rgba(233, 233, 233, 0.747);
  border-radius: 2px 2px 2px 2px;
}
.progress-div-text {
  position: absolute;
  top: 1px;
  left: 0px;
  width: 100%;
  right: auto;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 11px;
  color: white;
  letter-spacing: 1px;
}
.progress-div-text:hover {
  cursor: pointer;
}
.select-skill-multiplier-button {
  display: inline-block;
  cursor: pointer;
  width: 22%;
  margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
}
.select-skill-multiplier-button:hover {
  background-color: rgb(174, 180, 176);
}
.select-skill-multiplier-button:first-child {
  border-radius: 4px 0px 0px 4px;
}
.select-skill-multiplier-button:last-child {
  border-radius: 0px 4px 4px 0px;
}
.select-skill-skillpercent {
  display: inline-block;
  color: white;
  width: 60px;
  font-size: 22px;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  -webkit-text-stroke: 1px black;
}
</style>