export default {
  info: {
    taggedSkills: 0,
    taggedTraits: 0
  },
  Default: {
    InfoPanel: {
      name: 'Info Panel',
      key: 'InfoPanel',
      image: 'Info Panel',
      type: 'Default',
      description: 'This panel:',
      modifies: [],
      modifiedBy: [],
      notes: [
        'displays info about things when you hover over them',
        'provides a wiki link to the shown item (if available)',
        'provides links to the FOnline Reloaded community (top-left)',
        'displays latest version info or refresh the app (top-right)',
        'displays Update prompts'
      ],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Main_Page'
    },
    SPECIAL: {
      name: 'S.P.E.C.I.A.L.',
      key: 'SPECIAL',
      image: 'Info Panel',
      type: 'Default',
      description: 'In the SPECIAL character system, there are 7 primary statistics. Their first letters form the name of the system itself.',
      modifies: [],
      modifiedBy: [],
      notes: [
        "Primary statistics are numerical representations of characters' inherent abilities.",
        'The primary statistics form the base for all derived statistics.',
      ],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/SPECIAL'
    },
    Skills: {
      name: 'Skills',
      key: 'Skills',
      image: 'Info Panel',
      type: 'Default',
      description: 'There are 17 skills in FOnline: Reloaded. At the beginning of the game you have to choose your 3 tagged skills. The skills you want to pick depends on who you want to become. Each time you level up, you will gain some skill points. You can spend these points to increase your skills',
      modifies: [],
      modifiedBy: [],
      notes: [
        "Fewer skill points are required to increase the three tagged skills.",
        'Weapon skill is a major factor determining your Chance to hit.',
      ],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/SPECIAL'
    },
    Traits: {
      name: 'Traits',
      key: 'Traits',
      image: 'Info Panel',
      type: 'Default',
      description: 'Traits are optional qualities of your character that can be selected upon its creation.',
      modifies: [],
      modifiedBy: [],
      notes: [
        'Unlike perks, traits give you not only bonuses, but also penalties.',
        'It is not possible to lose any of your traits, and neither is it possible to gain new ones once the character creation process has been completed.',
        'There is a limit to the number of traits that one character may have, currently set at 2.'
      ],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Traits'
    },
  },
  SPECIAL: {
    ST: {
      name: 'STRENGTH',
      key: 'ST',
      image: 'STRENGTH',
      type: 'SPECIAL',
      description: "Raw physical strength. A high Strength is good for physical Characters.",
      modifies: [
        'Hit Points',
        'Carry Weight',
        'Melee Damage',
        'Skill: Close Combat',
        'Perk: Adrenaline Rush',
        'Perk: Stonewall',
        'Perk: Gain Strength',
        'Perk: Iron Limbs'
      ],
      modifiedBy: ['Buffout', 'Psycho', 'Jet', 'Implant', 'Gain ST', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Strength'
    },
    PE: {
      name: 'PERCEPTION',
      key: 'PE',
      image: 'PERCEPTION',
      type: 'SPECIAL',
      description: "The ability to see, hear, taste and notice unusual things. A high Perception is important for a sharpshooter.",
      modifies: [
        'Sight',
        'Sequence',
        'Hit Chance',
        'Initial distance in random encounters',
        'Skill: First Aid',
        'Skill: Doctor',
        'Skill: Lockpick',
        'Skill: Traps',
        'Perk: Gain Perception',
        'Support Perk: Cautious Nature'
      ],
      modifiedBy: ['Cigs', 'Psycho', 'Beer', 'Implant', 'Gain PE', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Perception'

    },
    EN: {
      name: 'ENDURANCE',
      key: 'EN',
      image: 'ENDURANCE',
      type: 'SPECIAL',
      description: "Stamina and physical Toughness. A Character with a high Endurance will survive where others may not.",
      modifies: [
        'Hit Points',
        'Poison Resistance',
        'Radiation Resistance',
        'Healing Rate',
        'Hit Points per level',
        'Skill: Outdoorsman',
        'Perk: Toughness',
        'Perk: Even Tougher',
        'Perk: Gain Endurance',
        'Perk: Man of Steel',
        'Perk: Iron Limbs',
        'Support Perk: Strong Back',
        'Support Perk: Snakeater',
      ],
      modifiedBy: ['Buffout', 'Psycho', 'Implant', 'Gain EN', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Endurance'

    },
    CH: {
      name: 'CHARISMA',
      key: 'CH',
      image: 'CHARISMA',
      type: 'SPECIAL',
      description: "A combination of appearance and charm. A high Charisma is important for Characters that want to influence people with words.",
      modifies: [
        'NPC reactions',
        'Prices',
        'Party Points',
        'Skill: Speech',
        'Skill: Barter',
        'Perk: Gain Charisma',
        'Support Perk: Harmless',
      ],
      modifiedBy: ['Cigs', 'Jet', 'Beer', 'Mentats', 'Implant', 'Gain CH', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Charisma'

    },
    IN: {
      name: 'INTELLIGENCE',
      key: 'IN',
      image: 'INTELLIGENCE',
      type: 'SPECIAL',
      description: "Knowledge, wisdom and the ability to think quickly. A high Intelligence is important for any character.",
      modifies: [
        'Skill Points per level',
        'Dialogue options',
        'Skill: First Aid',
        'Skill: Doctor',
        'Skill: Science',
        'Skill: Repair',
        'Skill: Outdoorsman',
        'Perk: Sharpshooter',
        'Perk: Medic',
        'Perk: Gain Intelligence',
        'Support Perk: Dead Man Walking',
        'Support Perk: Swift Learner',
        'Support Perk: Faster Healing',
        'Support Perk: Rad Resistance',
        'Support Perk: Educated',
      ],
      modifiedBy: ['Mentats', 'Implant', 'Gain IN', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Intelligence'

    },
    AG: {
      name: 'AGILITY',
      key: 'AG',
      image: 'AGILITY',
      type: 'SPECIAL',
      description: "Coordination and the ability to move well. A high Agility is important for any active character.",
      modifies: [
        'Action Points',
        'Armor Class',
        'Skill: Small Guns',
        'Skill: Big Guns',
        'Skill: Energy Weapons',
        'Skill: Close Combat',
        'Skill: Throwing',
        'Skill: Sneak',
        'Skill: Lockpick',
        'Skill: Steal',
        'Skill: Traps',
        'Perk: Quick Pockets',
        'Perk: Quick Recovery',
        'Perk: Gain Agility',
        'Perk: Action Boy',
        'Perk: Livewire',
        'Perk: Dodger',
      ],
      modifiedBy: ['Buffout', 'Nuka Cola', 'Implant', 'Gain AG', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Agility'

    },
    LK: {
      name: 'LUCK',
      key: 'LK',
      image: 'LUCK',
      type: 'SPECIAL',
      description: "Fate. Karma. An extremely high or low Luck will affect the Character - somehow. Events and situations will be changed by how lucky (or unlucky) your Character is.",
      modifies: [
        'Critical Chance',
        'Aimed attacks',
        'Severity of critical misses',
        'Chance to avoid armor bypass when receiving damage',
        'Amount of Hit Points healed by First Aid skill',
        'Chance for critical fail/success when using First Aid skill',
      ],
      modifiedBy: ['Implant', 'Gain LK', 'Armor'],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Luck'

    },
    PL: {
      name: 'CHARACTER POINTS',
      key: 'PL POINTS',
      image: 'CHARACTER POINTS',
      type: 'SPECIAL',
      description: "A pool of available points you must distribute among your SPECIAL stats. Available only when registering a new character.",
      modifies: ['Your SPECIAL'],
      modifiedBy: [],
      notes: [],
      requirements: 'Must be spent on creation',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Registration_Menu'

    },
  },
  Skills: {
    SmallGuns: {
      name: 'Small Guns',
      key: 'SmallGuns',
      image: 'SmallGuns',
      type: 'Skills',
      description: "The use, care and general knowledge of small firearms - pistols, SMGs and rifles.",
      modifies: [
        'Perk: More Critical',
        'Perk: Weapon Handling',
        'Perk: Even More Criticals',
        'Perk: Sharpshooter',
        'Perk: Pyromaniac',
        'Perk: Bonus Ranged Damage',
        'Perk: Better Criticals',
        'Perk: More Ranged Damage',
        'Perk: Bonus Rate of Attack',
        'Boost: Marshal Small Guns',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Small_Guns'

    },
    BigGuns: {
      name: 'Big Guns',
      key: 'BigGuns',
      image: 'BigGuns',
      type: 'Skills',
      description: "The operation and maintenance of really big guns - miniguns, rocket launchers, flamethrowers and such.",
      modifies: [
        'Perk: More Critical',
        'Perk: Weapon Handling',
        'Perk: Even More Criticals',
        'Perk: Sharpshooter',
        'Perk: Pyromaniac',
        'Perk: Bonus Ranged Damage',
        'Perk: Better Criticals',
        'Perk: More Ranged Damage',
        'Perk: Bonus Rate of Attack',
        'Boost: Marshal Big Guns',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Big_Guns'

    },
    EnergyWeapons: {
      name: 'Energy Weapons',
      key: 'EnergyWeapons',
      image: 'EnergyWeapons',
      type: 'Skills',
      description: "The care and feeding of energy-based weapons. How to arm and operate weapons that use laser or plasma technology.",
      modifies: [
        'Perk: More Critical',
        'Perk: Weapon Handling',
        'Perk: Even More Criticals',
        'Perk: Sharpshooter',
        'Perk: Pyromaniac',
        'Perk: Bonus Ranged Damage',
        'Perk: Better Criticals',
        'Perk: More Ranged Damage',
        'Perk: Bonus Rate of Attack',
        'Boost: Marshal Energy Weapons',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Energy_Weapons'

    },
    CloseCombat: {
      name: 'Close Combat',
      key: 'CloseCombat',
      image: 'CloseCombat',
      type: 'Skills',
      description: "A combination of martial arts, non-ranged weapons in hand-to-hand, or melee combat - knives, sledgehammers, spears, clubs and so on.",
      modifies: [
        'Perk: More Critical',
        'Perk: In Your Face!',
        'Perk: Even More Criticals',
        'Perk: Sharpshooter',
        'Perk: Pyromaniac',
        'Perk: Close Combat Master',
        'Perk: Better Criticals',
        'Perk: Dodger',
        'Perk: Bonus Rate of Attack',
        'Boost: Marshal Close Combat',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Close_Combat'

    },
    Scavenging: {
      name: 'Scavenging',
      key: 'Scavenging',
      image: 'Scavenging',
      type: 'Skills',
      description: "The ability to search through waste, junk, etc., for something that can be saved or used. The more time you spend on searching, the better you are at it.",
      modifies: [],
      modifiedBy: [],
      notes: ['Scavenging is a passive skill - meaning it can only be raised by using it and not by spending Skill Points.'],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Scavenging'

    },
    Throwing: {
      name: 'Throwing',
      key: 'Throwing',
      image: 'Throwing',
      type: 'Skills',
      description: "The skill of muscle-propelled ranged weapons. Throwing knives, spears, and grenades.",
      modifies: [
        'Perk: More Critical',
        'Perk: Weapon Handling',
        'Perk: Even More Criticals',
        'Perk: Sharpshooter',
        'Perk: Pyromaniac',
        'Perk: Bonus Ranged Damage',
        'Perk: Heave Ho!',
        'Perk: Better Criticals',
        'Perk: More Ranged Damage',
        'Perk: Bonus Rate of Attack',
        'Boost: Marshal Throwing',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Throwing'

    },
    FirstAid: {
      name: 'First Aid',
      key: 'FirstAid',
      image: 'FirstAid',
      type: 'Skills',
      description: "General healing skill. Used to heal small cuts, abrasions and other minor ills. In game terms, the use of First Aid can heal more hit points over time than just rest.",
      modifies: [
        'Perk: Medic',
        'Perk: Field Medic',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/First_Aid'

    },
    Doctor: {
      name: 'Doctor',
      key: 'Doctor',
      image: 'Doctor',
      type: 'Skills',
      description: "The healing of major wounds and crippled limbs. Without this skill, it will take a much longer period of time to restore crippled limbs to use.",
      modifies: [
        'Perk: Medic',
        'Perk: Field Medic',
        'Support Perk: Dead Man Walking',
        'Support Perk: Faster Healing',
        'Support Perk: Rad Resistance',
        'Profession: Doctor 1',
        'Profession: Doctor 2',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Doctor'

    },
    Sneak: {
      name: 'Sneak',
      key: 'Sneak',
      image: 'Sneak',
      type: 'Skills',
      description: "Quiet movement, and the ability to remain unnoticed. If successful, you will be much harder to locate. You cannot run and sneak at the same time, unless you take Silent Running perk.",
      modifies: [
        'Perk: Silent Running',
        'Perk: Ghost',
        'Perk: Silent Death',
        'Support Perk: Stealth Girl',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Sneak'

    },
    Lockpick: {
      name: 'Lockpick',
      key: 'Lockpick',
      image: 'Lockpick',
      type: 'Skills',
      description: "Lockpick is a skill of opening locks without the proper key.",
      modifies: [
        'Support Perk: Treasure Hunter',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Lockpick'

    },
    Steal: {
      name: 'Steal',
      key: 'Steal',
      image: 'Steal',
      type: 'Skills',
      description: "The ability to make the things of others your own. Can be used to steal from NPCs or places.",
      modifies: [
        'Support Perk: Thief',
        'Support Perk: Harmless',
        'Support Perk: Pickpocket',
        'Support Perk: Master Thief',
      ],
      modifiedBy: [],
      notes: ['It does NOT work on players!!!'],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Steal'

    },
    Traps: {
      name: 'Traps',
      key: 'Traps',
      image: 'Traps',
      type: 'Skills',
      description: "The Traps skill governs the player's ability to find and remove traps. The skill also has an impact on the player's ability to handle timed explosives properly. This excludes grenades, as they are handled under the Throwing skill.",
      modifies: [
        'Support Perk: Light Step',
        'Support Perk: Demolition Expert',
        'Profession: Demolition Expert 1',
        'Profession: Demolition Expert 2',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Traps'

    },
    Science: {
      name: 'Science',
      key: 'Science',
      image: 'Science',
      type: 'Skills',
      description: "Covers a variety of high technology skills, such as computers, biology, physics and geology.",
      modifies: [
        'Support Perk: Dismantler',
        'Support Perk: Swift Learner',
        'Support Perk: Educated',
        'Support Perk: Demolition Expert',
        'Profession: Energy Expert 1',
        'Profession: Energy Expert 2',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Science'

    },
    Repair: {
      name: 'Repair',
      key: 'Repair',
      image: 'Repair',
      type: 'Skills',
      description: "The practical application of the Science skill for fixing broken equipment, machinery and electronics.",
      modifies: [
        'Support Perk: Mr Fixit',
        'Support Perk: Stealth Girl',
        'Profession: Small Guns 1',
        'Profession: Big Guns 1',
        'Profession: Armorer 1',
        'Profession: Small Guns 2',
        'Profession: Big Guns 2',
        'Profession: Armorer 2',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Repair'

    },
    Speech: {
      name: 'Speech',
      key: 'Speech',
      image: 'Speech',
      type: 'Skills',
      description: "The ability to communicate in practical and efficient manner. The skill of convincing others that your position is correct. The ability to lie and not get caught.",
      modifies: [
        'Support Perk: Sex Appeal',
        'Support Perk: Magnetic Personality',
        'Support Perk: Speaker',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Speech'

    },
    Barter: {
      name: 'Barter',
      key: 'Barter',
      image: 'Barter',
      type: 'Skills',
      description: "Trading and trade-related tasks. The ability to get better prices for items you sell, and lower prices for items you buy.",
      modifies: [
        'Support Perk: Negotiator',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Barter'

    },
    Gambling: {
      name: 'Gambling',
      key: 'Gambling',
      image: 'Gambling',
      type: 'Skills',
      description: "The knowledge and practical skills related to wagering. The skill at cards, dice and other games.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Gambling is currently DISABLED!'
      ],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gambling'

    },
    Outdoorsman: {
      name: 'Outdoorsman',
      key: 'Outdoorsman',
      image: 'Outdoorsman',
      type: 'Skills',
      description: "Practical knowledge of the outdoors, and the ability to live off the land. The knowledge of plants and animals. This skill increases the traveling speed on the world map and a chance to avoid Random Encounters.",
      modifies: [
        'Support Perk: Gecko Skinning',
        'Support Perk: Cautious Nature',
        'Support Perk: Ranger',
        'Support Perk: Scout',
        'Support Perk: Explorer',
        'Support Perk: Pathfinder',
      ],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Outdoorsman'

    },
  },
  Traits: {
    FastMetabolism: {
      name: 'Fast Metabolism',
      key: 'FastMetabolism',
      image: 'FastMetabolism',
      type: 'Traits',
      description: "Your metabolic rate is twice as normal.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        'doubled healing rate (every 30s instead of every 60s)',
      ],
      penalties: [
        'halved duration of drug effects',
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Fast_Metabolism'

    },
    Bruiser: {
      name: 'Bruiser',
      key: 'Bruiser',
      image: 'Bruiser',
      type: 'Traits',
      description: "A little slower, but a little bigger. You may not hit as often, but they will feel it when you do!",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+4 Strength',
        'doubled base melee damage'
      ],
      penalties: [
        '-2 to maximum Action Points'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Bruiser'

    },
    OneHander: {
      name: 'One Hander',
      key: 'OneHander',
      image: 'OneHander',
      type: 'Traits',
      description: "One of your hands is very dominant. You excel with single-handed weapons, but two-handed weapons cause a problem.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+20% to chance to hit when using Single-handed weapons',
        '+5 flat damage for Single-handed range weapons',
      ],
      penalties: [
        '-40% to chance to hit when using Two-handed weapons'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/One_Hander'

    },
    Finesse: {
      name: 'Finesse',
      key: 'Finesse',
      image: 'Finesse',
      type: 'Traits',
      description: "Your attacks show a lot of finesse. You don't do as much damage, but you cause more critical hits.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+10% to critical chance',
        "reduces enemy's base armor critical chance mod by 25%"
      ],
      penalties: [
        "+30 to your target's Damage Resistance"
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Finesse'

    },
    Kamikaze: {
      name: 'Kamikaze',
      key: 'Kamikaze',
      image: 'Kamikaze',
      type: 'Traits',
      description: "By not paying attention to any threats, you do more damage to enemies but your Damage Resistances are lowered.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'This Trait no longer affects Armor Class.'
      ],
      requirements: '',
      bonuses: [
        '+10% final damage'
      ],
      penalties: [
        '-10 to you Damage Resistance of every type'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Kamikaze'

    },
    HeavyHanded: {
      name: 'Heavy Handed',
      key: 'HeavyHanded',
      image: 'HeavyHanded',
      type: 'Traits',
      description: "You swing harder, not better. Your attacks are very brutal, but lack finesse. You rarely cause a good critical, but you always do more Unarmed damage.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Changed Strength roll from -5, -4, -3, -1, 0 to -6, -5, -4, -3, -2'
      ],
      requirements: '',
      bonuses: [
        'Strength roll (modified by used weapon) to knock down your opponent',
        '+5 flat damage'
      ],
      penalties: [
        '-20 to critical power'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Heavy_Handed'

    },
    FastShot: {
      name: 'Fast Shot',
      key: 'FastShot',
      image: 'FastShot',
      type: 'Traits',
      description: "You don't have time to aim for an targeted attack, because you attack faster than normal people and your bullets can randomly hit different body parts.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '-1 AP cost for single shots (Single-handed weapons with aim mode)',
        '-2 AP cost for single shots (Two-handed weapons with aim mode)',
        'Attacks will now have a chance to hit random body part (20% unaimed, 10% for eyes, head, each arm, each leg, torso and groin)'
      ],
      penalties: [
        'Not possible to make aimed shots'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Fast_Shot'

    },
    BloodyMess: {
      name: 'Bloody Mess',
      key: 'BloodyMess',
      image: 'BloodyMess',
      type: 'Traits',
      description: "By some strange twist of fate, people around you die violently. You always see the worst way a person can die.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        'Violent death animations.'
      ],
      penalties: [
        '100% chance to kill target if your damage gets him on negative HP but you can be killed like that too'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Bloody_Mess'

    },
    Jinxed: {
      name: 'Jinxed',
      key: 'Jinxed',
      image: 'Jinxed',
      type: 'Traits',
      description: "The good thing is that everyone around you has more critical failures in combat, the bad thing is so do you!",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '50% chance for critical failure on missed attack both for you and your opponent'
      ],
      penalties: [
        '50% chance for critical failure on missed attack both for you and your opponent'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Jinxed'

    },
    GoodNatured: {
      name: 'Good Natured',
      key: 'GoodNatured',
      image: 'GoodNatured',
      type: 'Traits',
      description: "There is a positive aura about you. People are more likely to follow you, but you can't own any slaves.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+50 party points'
      ],
      penalties: [
        "Can't own slaves"
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Good_Natured'

    },
    ChemReliant: {
      name: 'Chem Reliant',
      key: 'ChemReliant',
      image: 'ChemReliant',
      type: 'Traits',
      description: "The drug effects last twice as long, but you regain only 2/3 the normal Hit Points while using medicines.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        'doubled durration of drugs effects'
      ],
      penalties: [
        '-1/3 to current hit points healed with medicines'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Chem_Reliant'

    },
    Bonehead: {
      name: 'Bonehead',
      key: 'Bonehead',
      image: 'Bonehead',
      type: 'Traits',
      description: "A thicker than average cranium means you can shrug off hits to the head that would floor others. Unfortunately that doesn't leave much room up there.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '-10 to critical roll of attacks aimed to your head/eyes'
      ],
      penalties: [
        '-1 Intelligence'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Bonehead'

    },
    Skilled: {
      name: 'Skilled',
      key: 'Skilled',
      image: 'Skilled',
      type: 'Traits',
      description: "Since you spend more time improving your skills than a normal person, you gain additional skill points per level but you gain a perk every four levels.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+5 to skill points per level'
      ],
      penalties: [
        'New perk every 4 instead of 3 levels - total of 6 Perks instead of 8'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Skilled'

    },
    Loner: {
      name: 'Loner',
      key: 'Loner',
      image: 'Loner',
      type: 'Traits',
      description: "You appreciate nature more than company of others.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+10% to any experience points gained'
      ],
      penalties: [
        "Can't have followers"
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Loner'

    },
  },
  Perks: {
    MoreCritical: {
      name: 'More Critical',
      key: 'MoreCritical',
      image: 'MoreCritical',
      type: 'Perks',
      description: "With this perk, you are 5% more likely to cause critical hits in combat than without it.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'SG or BG or EW or CC or Throwing ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/More_Critical'
    },
    QuickPockets: {
      name: 'Quick Pockets',
      key: 'QuickPockets',
      image: 'QuickPockets',
      type: 'Perks',
      description: "You have learned to pack your equipment better. Equipping and unequipping up items is done at half its normal AP cost. Reloading cost reduced to 1 AP.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Agility ≥ 5',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Quick_Pockets'
    },
    AdrenalineRush: {
      name: 'Adrenaline Rush',
      key: 'AdrenalineRush',
      image: 'AdrenalineRush',
      type: 'Perks',
      description: "Adrenaline Rush increases one's damage thresholds and damage resistances depending on what percentage of his/her total hit points the player has temporarily lost. The increase is not permanent and lasts only as long as the player's current hit points remain at a value equal to or lower than that which grants the bonus. Adrenaline Rush increases player's resistance to all six types of damage.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Strength ≥ 5',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Adrenaline_Rush'
    },
    QuickRecovery: {
      name: 'Quick Recovery',
      key: 'QuickRecovery',
      image: 'QuickRecovery',
      type: 'Perks',
      description: "You are quick at recovering from being knocked down",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Agility ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Quick_Recovery'
    },
    WeaponHandling: {
      name: 'Weapon Handling',
      key: 'WeaponHandling',
      image: 'WeaponHandling',
      type: 'Perks',
      description: "You can wield weapons much larger than normally allowed. You gain a +2 bonus to your Strength for the purposes of Strength checks when trying to wield weaponry.",
      modifies: [],
      modifiedBy: [],
      notes: ['This perk effectively modifies your Strength for the purposes of Hit Chance calculation.', 'This perk does not affect Strength checks for throwing range.'],
      requirements: 'SG or BG or EW or Throwing ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Weapon_Handling'
    },
    InYourFace: {
      name: 'In Your Face',
      key: 'InYourFace',
      image: 'InYourFace',
      type: 'Perks',
      description: "You cut an intimidating figure, and people cower when you are near. Opponents have a 50% chance of missing you when in an adjacent square.",
      modifies: [],
      modifiedBy: [],
      notes: ['This Perk only works when you are unarmed or holding a Close Combat weapon.', 'This Perk works up to a 2-hex range, if you use a Close Combat weapon that has a 2-hex range, like a Spear, Sledgehammer, etc.'],
      requirements: 'Close Combat ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/In_Your_Face!'
    },
    EvenMoreCriticals: {
      name: 'Even More Criticals',
      key: 'EvenMoreCriticals',
      image: 'EvenMoreCriticals',
      type: 'Perks',
      description: "Taking this perk increases your critical hit chance by 10%.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'SG or BG or EW or CC or Throwing ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Even_More_Criticals'
    },
    SilentRunning: {
      name: 'Silent Running',
      key: 'SilentRunning',
      image: 'SilentRunning',
      type: 'Perks',
      description: "With this perk, you now have the ability to move quickly and still remain quiet. You can sneak and run at the same time. Without this perk, you would automatically stop sneaking if you ran.",
      modifies: [],
      modifiedBy: [],
      notes: ['It is probably the most useful perk that a sneaker can have.'],
      requirements: 'Sneak ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Silent_Running'
    },
    Toughness: {
      name: 'Toughness',
      key: 'Toughness',
      image: 'Toughness',
      type: 'Perks',
      description: "When you are tough, you take less damage. This perk adds 5% to all Damage Resistances and 1 to all your Damage Threshold.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Endurance ≥ 4',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Toughness'
    },
    Sharpshooter: {
      name: 'Sharpshooter',
      key: 'Sharpshooter',
      image: 'Sharpshooter',
      type: 'Perks',
      description: "You have a talent for hitting things at longer distances. This perk expands your field of vision by 6 hexes, and increases your chance to hit by 8% and -50% to your target's armor's (both head and body) critical chance modifiers for aimed attacks only",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'IN ≥ 3 & SG/BG/EW/CC/Throwing ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Sharpshooter'
    },
    Pyromaniac: {
      name: 'Pyromaniac',
      key: 'Pyromaniac',
      image: 'Pyromaniac',
      type: 'Perks',
      description: "You do 25% more fire-based damage.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'SG or BG or EW or CC or Throwing ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Pyromaniac'
    },
    CloseCombatMaster: {
      name: 'Close Combat Master',
      key: 'CloseCombatMaster',
      image: 'CloseCombatMaster',
      type: 'Perks',
      description: "+15 critical chance for melee/unarmed attacks and +10 melee damage",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Close Combat ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Close_Combat_Master'
    },
    BonusRangedDamage: {
      name: 'Bonus Ranged Damage',
      key: 'BonusRangedDamage',
      image: 'BonusRangedDamage',
      type: 'Perks',
      description: "Your training in firearms and other ranged weapons has made you more deadly in ranged combat. For each level of this perk, you do +3 damage with ranged weapons.",
      modifies: [],
      modifiedBy: [],
      notes: ['This +3 points of damage is per bullet. Thus this perk works best with gun that burst a lot of bullets, the more bullets per burst the better.'],
      requirements: 'SG or BG or EW or Throwing ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Bonus_Ranged_Damage'
    },
    EvenTougher: {
      name: 'Even Tougher',
      key: 'EvenTougher',
      image: 'EvenTougher',
      type: 'Perks',
      description: "This Perk increases your normal Damage Threshold by 3, and normal Damage Resistances by 10%.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Endurance ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Even_Tougher'
    },
    Stonewall: {
      name: 'Stonewall',
      key: 'Stonewall',
      image: 'Stonewall',
      type: 'Perks',
      description: "You are much less likely to be knocked down in combat.",
      modifies: [],
      modifiedBy: [],
      notes: ['Stonewall perk now protects against knockdown from grenades, rocket explosions and shotgun bursts, chance to avoid knockdown is based on Strength.', 'See more info on wiki'],
      requirements: 'Strength ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Stonewall'
    },
    Medic: {
      name: 'Medic',
      key: 'Medic',
      image: 'Medic',
      type: 'Perks',
      description: "The Medic perk halves the cooldowns for using the First Aid and Doctor skills, and the ability to remove knockout effect with Doctor skill.",
      modifies: [],
      modifiedBy: [],
      notes: ['This is by far the most useful Perk a doctor can have.'],
      requirements: 'IN ≥ 3 & First Aid & Doctor ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Medic'
    },
    HeaveHo: {
      name: 'HeaveHo',
      key: 'HeaveHo',
      image: 'HeaveHo',
      type: 'Perks',
      description: "Heave Ho! gives you a +2 bonus to Strength (up to 10) for purposes of determining Range with thrown weapons only. This perk will increase a weapon's maximum Range by 6.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Throwing ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Heave_Ho!'
    },
    GainAgility: {
      name: 'Gain Agility',
      key: 'GainAgility',
      image: 'GainAgility',
      type: 'Perks',
      description: "With this perk you gain +2 to your Agility.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 AG, but if you have 9 AG, this perk will only raise your AG by 1, not by 2, for a maximum of 10.'],
      requirements: 'Agility ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Agility'
    },
    GainCharisma: {
      name: 'Gain Charisma',
      key: 'GainCharisma',
      image: 'GainCharisma',
      type: 'Perks',
      description: "With this perk you gain +2 to your Charisma.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 CH, but if you have 9 CH, this perk will only raise your CH by 1, not by 2, for a maximum of 10.'],
      requirements: 'Agility ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Charisma'
    },
    GainEndurance: {
      name: 'Gain Endurance',
      key: 'GainEndurance',
      image: 'GainEndurance',
      type: 'Perks',
      description: "With this perk you gain +2 to your Endurance.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 EN, but if you have 9 EN, this perk will only raise your EN by 1, not by 2, for a maximum of 10.'],
      requirements: 'Endurance ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Endurance'
    },
    GainIntelligence: {
      name: 'Gain Intelligence',
      key: 'GainIntelligence',
      image: 'GainIntelligence',
      type: 'Perks',
      description: "With this perk you gain +2 to your Intelligence.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 IN, but if you have 9 IN, this perk will only raise your IN by 1, not by 2, for a maximum of 10.'],
      requirements: 'Intelligence ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Intelligence'
    },
    GainLuck: {
      name: 'Gain Luck',
      key: 'GainLuck',
      image: 'GainLuck',
      type: 'Perks',
      description: "With this perk you gain +2 to your Luck.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 LK, but if you have 9 LK, this perk will only raise your LK by 1, not by 2, for a maximum of 10.'],
      requirements: 'Luck ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Luck'
    },
    GainPerception: {
      name: 'Gain Perception',
      key: 'GainPerception',
      image: 'GainPerception',
      type: 'Perks',
      description: "With this perk you gain +2 to your Perception.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 PE, but if you have 9 PE, this perk will only raise your PE by 1, not by 2, for a maximum of 10.'],
      requirements: 'Perception ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Perception'
    },
    GainStrength: {
      name: 'Gain Strength',
      key: 'GainStrength',
      image: 'GainStrength',
      type: 'Perks',
      description: "With this perk you gain +2 to your Strength.",
      modifies: [],
      modifiedBy: [],
      notes: ['The requirement for this perk is having less than 10 ST, but if you have 9 ST, this perk will only raise your ST by 1, not by 2, for a maximum of 10.'],
      requirements: 'Strength ≤ 9',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gain_Strength'
    },
    BetterCriticals: {
      name: 'Better Criticals',
      key: 'BetterCriticals',
      image: 'BetterCriticals',
      type: 'Perks',
      description: "The critical hits you cause in combat are more devastating. You gain a 20% bonus for ranged attacks (+25% bonus for melee/unarmed attacks) on the Critical Hit table, almost ensuring that more damage will be done. This does not affect the chance to cause a Critical Hit.",
      modifies: [],
      modifiedBy: [],
      notes: ['This perk is particularly useful for snipers.'],
      requirements: 'SG or BG or EW or CC or Throwing ≥ 175%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Better_Critical'
    },
    Ghost: {
      name: 'Ghost',
      key: 'Ghost',
      image: 'Ghost',
      type: 'Perks',
      description: "This perk should grant buff mentioned below, but obstacles and walls do not have sneak bonus implemented yet. The Ghost perk gives a +30 bonus to Sneak whenever the player who took it finds himself within 5 hexes from a wall.",
      modifies: [],
      modifiedBy: [],
      notes: ['The perk will not increase the skill past its 300% cap.'],
      requirements: 'Sneak ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Ghost'
    },
    Lifegiver1: {
      name: 'Lifegiver 1',
      key: 'Lifegiver1',
      image: 'Lifegiver1',
      type: 'Perks',
      description: "With each respective level of this perk, you gain an additional 30 Hit Points. This is in addition to the Hit Points you already gain per level based on of your Endurance.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'level requirement only',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Lifegiver'
    },
    ActionBoy1: {
      name: 'Action Boy',
      key: 'ActionBoy1',
      image: 'ActionBoy',
      type: 'Perks',
      description: "Each level of Action Boy gives you an additional action point to spend every combat turn. You can use these generic APs on any task.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Agility ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Action_Boy'
    },
    ActionBoy2: {
      name: 'Action Boy',
      key: 'ActionBoy2',
      image: 'ActionBoy',
      type: 'Perks',
      description: "Each level of Action Boy gives you an additional action point to spend every combat turn. You can use these generic APs on any task.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Agility ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Action_Boy'
    },
    Lifegiver2: {
      name: 'Lifegiver 2',
      key: 'Lifegiver2',
      image: 'Lifegiver2',
      type: 'Perks',
      description: "With each respective level of this perk, you gain an additional 30 Hit Points. This is in addition to the Hit Points you already gain per level based on of your Endurance.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'level requirement only',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Lifegiver'
    },
    Livewire: {
      name: 'Livewire',
      key: 'Livewire',
      image: 'Livewire',
      type: 'Perks',
      description: "You have learned to harness your extra energy and in battle you are a blur. With this Perk, your AC derived from Agility gets doubled.",
      modifies: [],
      modifiedBy: [],
      notes: ['Does not work with two-handed guns from Small Guns, Big Guns, or Energy Weapons.'],
      requirements: 'Agility ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Livewire'
    },
    ManОfSteel: {
      name: 'Man Оf Steel',
      key: 'ManОfSteel',
      image: 'ManОfSteel',
      type: 'Perks',
      description: "You are as tough as they come. Critical hits just don’t seem to affect you as much as they do others. This perk gives -10 to enemy's crit power roll.",
      modifies: [],
      modifiedBy: [],
      notes: ["Bonehead trait does the same thing, but for head and eyes only, and so the effects of both will stack for -20 to enemy's crit power to head and eyes."],
      requirements: 'Endurance ≥ 8',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Man_of_Steel'
    },
    FieldMedic: {
      name: 'Field Medic',
      key: 'FieldMedic',
      image: 'Medic',
      type: 'Perks',
      description: "This perk gives you the following advantages:",
      modifies: [],
      modifiedBy: [],
      notes: ['+20 to +40 HP healed when using First Aid skill', '+5% chance to score critical success when using First Aid', "You can see other players' First Aid and Doctor Cooldowns in on-cursor awareness", 'When you use First Aid/Doctor on other players, your cooldown is applied to them (If you are on cooldown, you still can heal people that are not on cooldown)', 'You cannot use your own cooldowns when healing other players. This means that you will not be able to use FA/Doctor on people who are on cooldown even if your own cooldown is 0.'],
      requirements: 'Medic perk & First Aid & Doctor ≥ 175%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Field_Medic'
    },
    MoreRangedDamage: {
      name: 'More Ranged Damage',
      key: 'MoreRangedDamage',
      image: 'MoreRangedDamage',
      type: 'Perks',
      description: "Your advanced training in firearms and other ranged weapons has made you even more deadly in ranged combat. You do +4 damage with ranged weapons. This is a per-bullet bonus, so you get +4 damage per bullet. Thus this perk goes best with burst guns, the more bullets per burst the better.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'SG, BG, EW or Throwing ≥ 200%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/More_Ranged_Damage'
    },
    SilentDeath: {
      name: 'Silent Death',
      key: 'SilentDeath',
      image: 'SilentDeath',
      type: 'Perks',
      description: "While sneaking, if you attack a critter(whether it's Player or NPC) from behind, you will always cause a critical hit with a +10 bonus on the critical power roll. Silent Death is that kind of perk.",
      modifies: [],
      modifiedBy: [],
      notes: ['Silent Death does not affect grenades and only works for players that are either unarmed or equipped with a one-handed weapon with single-shot mode (SMG does not count, only pistols). So unarmed, melee, pistols or throwing weapons (not grenades).'],
      requirements: 'Sneak ≥ 175%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Silent_Death'
    },
    IronLimbs: {
      name: 'Iron Limbs',
      key: 'IronLimbs',
      image: 'IronLimbs',
      type: 'Perks',
      description: "With this perk, you get all of these rolls:",
      modifies: [],
      modifiedBy: [],
      notes: ['EN - 3 to avoid leg or arm cripple', 'ST - 4 to avoid weapon drop'],
      requirements: 'Strength ≥ 6 & Endurance ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Iron_Limbs'
    },
    Dodger1: {
      name: 'Dodger 1',
      key: 'Dodger1',
      image: 'Dodger1',
      type: 'Perks',
      description: "-5% to final hit chance (works only with melee / unarmed / throwing weapons in both hands)",
      modifies: [],
      modifiedBy: [],
      notes: ['The 2nd rank of Dodger has replaced its earlier version, Dodger+.'],
      requirements: 'Agility ≥ 8 & Close Combat ≥ 175',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dodger'
    },
    Dodger2: {
      name: 'Dodger 2',
      key: 'Dodger2',
      image: 'Dodger2',
      type: 'Perks',
      description: "-5% to final hit chance (works only with melee / unarmed / throwing weapons in both hands)",
      modifies: [],
      modifiedBy: [],
      notes: ['The 2nd rank of Dodger has replaced its earlier version, Dodger+.'],
      requirements: 'Agility ≥ 10 & Close Combat ≥ 175',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dodger'
    },
    Lifegiver3: {
      name: 'Lifegiver 3',
      key: 'Lifegiver3',
      image: 'Lifegiver2',
      type: 'Perks',
      description: "With each respective level of this perk, you gain an additional 30 Hit Points. This is in addition to the Hit Points you already gain per level based on of your Endurance.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'level requirement only',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Lifegiver'
    },
    BonusRateОfAttack: {
      name: 'Bonus Rate Оf Attack',
      key: 'BonusRateОfAttack',
      image: 'BonusRateОfAttack',
      type: 'Perks',
      description: "This Perk allows you to shoot and punch a little faster and still remain as accurate as before. Each attack costs 1 AP less to perform. This Perk applies to all sorts of attacks with any weapon, including guns, throwing, and melee.",
      modifies: [],
      modifiedBy: [],
      notes: ['This perk is a combination of two classic but retired perks: Bonus Rate of Fire and Bonus HtH Attacks.'],
      requirements: 'SG or BG or EW or CC or Throwing ≥ 180%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Bonus_Rate_of_Attack'
    },
  },
  SupportPerks: {
    Awareness: {
      name: 'Awareness',
      key: 'Awareness',
      image: 'Awareness',
      type: 'SupportPerks',
      description: "With Awareness, you are given detailed information about any critters you examine. You see their exact Hit Points and information about any Weapon they are equipped with.",
      modifies: [],
      modifiedBy: [],
      notes: ['Awareness is a Support Perk.', 'Characters start with Awareness by default.', 'Upon hovering the cursor over any critters, a player who gained Awareness is presented with the following information: the number of its Hit Points, whether it is Injured or not, the name of the Weapon it is wielding, the Armor type it is wearing.', 'Awareness setting on PCs (press F9 to enable/disable) and NPCs (press F7 to enable/disable)'],
      requirements: 'None - every character starts with it.',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Awareness'
    },
    PackRat: {
      name: 'Pack Rat',
      key: 'PackRat',
      image: 'PackRat',
      type: 'SupportPerks',
      description: "You are efficient at arranging your inventory in general. This makes it much easier to carry that little extra you've always needed.",
      modifies: [],
      modifiedBy: [],
      notes: ['Pack Rat adds a bonus to your Carry Weight capacity, equal to an additional 1/3 (excluding Perks).'],
      requirements: 'level requirement only',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Pack_Rat'
    },
    StrongBack: {
      name: 'Strong Back',
      key: 'StrongBack',
      image: 'StrongBack',
      type: 'SupportPerks',
      description: "You can carry much more than an ordinary person.",
      modifies: [],
      modifiedBy: [],
      notes: ['The Strong Back perk increases your Carry Weight capacity by 22 kilograms.'],
      requirements: 'Endurance ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Strong_Back'
    },
    MrFixit: {
      name: 'Mr Fixit',
      key: 'MrFixit',
      image: 'MrFixit',
      type: 'SupportPerks',
      description: "Mr. Fixit is able to Repair items to a higher standard than his peers.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Repair ≥ 120%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Mr._Fixit'
    },
    Dismantler: {
      name: 'Dismantler',
      key: 'Dismantler',
      image: 'Dismantler',
      type: 'SupportPerks',
      description: "You're the kind of a person who always finds spare screws after dismantling a clock and putting it back together. You recycle twice the usual number of base resources whenever dismantling an item.",
      modifies: [],
      modifiedBy: [],
      notes: ['With Dismantler, the amount of resources received from dismantling items gets doubled. Officially approved by the King of Scotland, this perk allows you to sleep soundly, knowing that no disassembled weapon went to waste.'],
      requirements: 'Science ≥ 120%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dismantler'
    },
    DeadManWalking: {
      name: 'Dead Man Walking',
      key: 'DeadManWalking',
      image: 'DeadManWalking',
      type: 'SupportPerks',
      description: "Like Rasputin, it takes a lot to finally finish you off. Your hit points can drop to twice the normally allowed negative value before you finally die.",
      modifies: [],
      modifiedBy: [],
      notes: ['Despite its name, the perk does not enable you to actually walk despite being (un)dead; rather than that, it allows you to stay alive a little longer than you would have without it, increasing your negative hit point capacity from 19 to 39.'],
      requirements: 'Intelligence ≥ 5 & Doctor ≥ 50%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dead_Man_Walking'
    },
    SwiftLearner: {
      name: 'Swift Learner',
      key: 'SwiftLearner',
      image: 'SwiftLearner',
      type: 'SupportPerks',
      description: "You are indeed a Swift Learner with this perk, as it gives you a +10% bonus whenever you earn experience points.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Intelligence ≥ 6 & Science ≥ 50%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Swift_Learner'
    },
    FasterHealing: {
      name: 'Faster Healing',
      key: 'FasterHealing',
      image: 'FasterHealing',
      type: 'SupportPerks',
      description: "With this perk, you get a +5 bonus to your Healing Rate.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Intelligence ≥ 6 & Doctor ≥ 75%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Faster_Healing'
    },
    RadResistance: {
      name: 'Rad Resistance',
      key: 'RadResistance',
      image: 'RadResistance',
      type: 'SupportPerks',
      description: "You know how to avoid radiation and its bad effects more effectively. This perk adds 30% to your Rad Resistance and 20% to your Poison Resistance.",
      modifies: [],
      modifiedBy: [],
      notes: ["Rad Resistance is a support perk that requires both Doctor skill 100, and Intelligence 7. If your IN is 5 or less, you won't be able to get this perk by using Mentats and/or special armor. However, if your IN is 6, a Logic Co-Processor (Intelligence implant) will work."],
      requirements: 'Intelligence ≥ 7 & Doctor ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Rad_Resistance'
    },
    Educated: {
      name: 'Educated',
      key: 'Educated',
      image: 'Educated',
      type: 'SupportPerks',
      description: "Educated adds +2 Skill Points when you gain a new experience level. This perk works best when acquired early in your adventure.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Intelligence ≥ 8 & Science ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Educated'
    },
    Snakeater: {
      name: 'Snakeater',
      key: 'Snakeater',
      image: 'Snakeater',
      type: 'SupportPerks',
      description: "Yum! Tastes like chicken. This perk gives you a 30% bonus to your Poison Resistance and a 20% bonus to your Rad Resistance.",
      modifies: [],
      modifiedBy: [],
      notes: ['Snakeater is a support perk obtained after successful completion of the Collect the Radscorpion Eggs quest.', 'The Outdoorsman 75 requirement may not be accurate. Players have reported getting this perk with only OD 34%.', 'In this tool the Outdoorsman requirement for this support perk is ignored!'],
      requirements: 'Endurance ≥ 6',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Snakeater'
    },
    GeckoSkinning: {
      name: 'Gecko Skinning',
      key: 'GeckoSkinning',
      image: 'GeckoSkinning',
      type: 'SupportPerks',
      description: "You have the knowledge of how to skin geckos properly in order to get their pelts.",
      modifies: [],
      modifiedBy: [],
      notes: ["If you start in Lander's Point, you can get this perk from the 'Old Trapper' near the hunting grounds.", 'The easiest way to get this perk is talking to the Travelling Merchant in the Hub. He can teach you Gecko Skinning perk for 500 caps (no skills required), and also can show location where to find some geckos.'],
      requirements: 'Outdoorsman ≥ 50%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Gecko_Skinning'
    },
    CautiousNature: {
      name: 'Cautious Nature',
      key: 'CautiousNature',
      image: 'CautiousNature',
      type: 'SupportPerks',
      description: "You are more alert outdoors and enemies are less likely to sneak up on you. With this perk you get a +3 bonus to your Perception in random encounters when determining placement.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Perception ≥ 6 & Outdoorsman ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Cautious_Nature'
    },
    Ranger: {
      name: 'Ranger',
      key: 'Ranger',
      image: 'Ranger',
      type: 'SupportPerks',
      description: "Over the extensive amount of time you have spent in the wilderness, you have learned to create a number of items and tools necessary for survival. Also, when you make a tent, it is automatically upgraded to a safe house.",
      modifies: [],
      modifiedBy: [],
      notes: ["Getting it is done through dialogue with an unstable woman who will attack you if you don't say the right things.", 'With this perk, you get a Safe House when you build a tent, instead of a standard tent. The 10-tent limit per character applies normally to Safe Houses too. So if you build 5 standard tents, then get this perk and build 5 more tents, the last 5 tents will be Safe Houses and the first 5 tents will remain standard tents. Safe Houses each have a workbench unlike a typical tent which has no workbench.'],
      requirements: 'Outdoorsman ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Ranger'
    },
    Scout: {
      name: 'Scout',
      key: 'Scout',
      image: 'Scout',
      type: 'SupportPerks',
      description: "You have improved your ability to see distant locations, increasing the size of explorations on the World Map by one square in each direction.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Outdoorsman ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Scout'
    },
    Explorer: {
      name: 'Explorer',
      key: 'Explorer',
      image: 'Explorer',
      type: 'SupportPerks',
      description: "The mark of the Explorer is to search out new and interesting locations.",
      modifies: [],
      modifiedBy: [],
      notes: ['Explorer is a support perk that increases your World Map traveling speed by allowing you to ignore half of the terrain-related speed penalties. It also reveals all zones on The World map, including water zones and islands.', 'The perk cannot be gained unless the player has a large majority of locations marked on their World Map, and has visited all 4 train stations.'],
      requirements: 'Outdoorsman ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Explorer'
    },
    Pathfinder: {
      name: 'Pathfinder',
      key: 'Pathfinder',
      image: 'Pathfinder',
      type: 'SupportPerks',
      description: "The Pathfinder is able to find the shortest route available. With this perk, your travel speed on the World Map increases by 25%",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Outdoorsman ≥ 150%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Pathfinder'
    },
    LightStep: {
      name: 'Light Step',
      key: 'LightStep',
      image: 'LightStep',
      type: 'SupportPerks',
      description: "You are agile, lucky, and always careful. This Perk halves your chances of setting off a trap.",
      modifies: [],
      modifiedBy: [],
      notes: ["If you have the Childkiller Karma perk you won't be able to talk to children and get this support perk.", 'If you want to hunt for random lockers, plan to get this perk. With it, you can run over many bear traps without triggering them, which makes looting random lockers much less troublesome.'],
      requirements: 'Traps ≥ 50%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Light_Step'
    },
    DemolitionExpert: {
      name: 'Demolition Expert',
      key: 'DemolitionExpert',
      image: 'DemolitionExpert',
      type: 'SupportPerks',
      description: "You are an expert when it comes to the fine art of handling explosives. They always go off when they're supposed to, as well as causing extra damage.",
      modifies: [],
      modifiedBy: [],
      notes: ['Demolition Expert increases the blast radius of explosives by 1 and does not actually affect damage. Demolition Expert does not affect grenades.'],
      requirements: 'Traps ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Demolition_Expert'
    },
    Negotiator: {
      name: 'Negotiator',
      key: 'Negotiator',
      image: 'Negotiator',
      type: 'SupportPerks',
      description: "You are a notorious negotiator. Not only can you Barter with the best of them but you can also talk your way into or out of nearly anything. With this perk even your worst Reputations count as neutral.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Barter ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Negotiator'
    },
    SexAppeal: {
      name: 'Sex Appeal',
      key: 'SexAppeal',
      image: 'SexAppeal',
      type: 'SupportPerks',
      description: "Your raw sexual magnetism affects your relationship with the opposite sex in your favor. All reputations in such relationships are better for 250 points.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Speech ≥ 75%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Sex_Appeal'
    },
    MagneticPersonality: {
      name: 'Magnetic Personality',
      key: 'MagneticPersonality',
      image: 'MagneticPersonality',
      type: 'SupportPerks',
      description: "Everyone enjoys your company! Your Party Points increase by 50.",
      modifies: [],
      modifiedBy: [],
      notes: ['Magnetic Personality also adds +1 to your party size (Acts like +1CH for World Map party checks.)', 'This perk is useless if you tagged Loner trait!'],
      requirements: 'Speech ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Magnetic_Personality'
    },
    Speaker: {
      name: 'Speaker',
      key: 'Speaker',
      image: 'Speaker',
      type: 'SupportPerks',
      description: "Being a speaker means that your Followers’ loyalty decreases at half the normal rate.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Speech ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Speaker'
    },
    StealthGirl: {
      name: 'Stealth Girl',
      key: 'StealthGirl',
      image: 'StealthGirl',
      type: 'SupportPerks',
      description: "Your intuitive understanding of the inner mechanism of Stealth Boys and Motion Sensor allows you to use them for twice as long.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Sneak ≥ 100% & Repair ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Stealth_Girl'
    },
    Thief: {
      name: 'Thief',
      key: 'Thief',
      image: 'Thief',
      type: 'SupportPerks',
      description: "The blood of a thief runs through your veins. You are granted a Luck/50 chance of no timeout when unsuccessfully attempting to steal. A well-rounded thief is a live thief.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Steal ≥ 100%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Thief'
    },
    Harmless: {
      name: 'Harmless',
      key: 'Harmless',
      image: 'Harmless',
      type: 'SupportPerks',
      description: "Your innocent demeanor makes stealing from people a little easier.",
      modifies: [],
      modifiedBy: [],
      notes: ['Harmless is a support perk that halves the amount of reputation that you lose when unsuccessfully attempting to steal.'],
      requirements: '	Charisma ≥ 6 & Steal ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Harmless'
    },
    Pickpocket: {
      name: 'Pickpocket',
      key: 'Pickpocket',
      image: 'Pickpocket',
      type: 'SupportPerks',
      description: "Pickpocket makes stealing much easier by reducing both size and facing modifiers by 50%.",
      modifies: [],
      modifiedBy: [],
      notes: ['Pickpocket is by far the most useful perk that a thief can have.'],
      requirements: 'Steal ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Pickpocket'
    },
    MasterThief: {
      name: 'Master Thief',
      key: 'MasterThief',
      image: 'MasterThief',
      type: 'SupportPerks',
      description: "A Master Thief is proficient at stealing. He is able to attempt his thievery twice as often as his less skilled counterparts.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Steal ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Master_Thief'
    },
    TreasureHunter: {
      name: 'Treasure Hunter',
      key: 'TreasureHunter',
      image: 'TreasureHunter',
      type: 'SupportPerks',
      description: "Treasure Hunter is a support perk that enables you to find more items in the lockers that sometimes appear in random encounters than you would have been able to find without it. It gives a bonus 25% more items found from encounter containers.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Lockpick ≥ 125%',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Treasure_Hunter'
    },
    AlcoholRaisedHP1: {
      name: 'Alcohol Raised HP Rank 1',
      key: 'AlcoholRaisedHP1',
      image: 'AlcoholRaisedHP',
      type: 'SupportPerks',
      description: "Excessive drinking affected your health in a surprising way, you gained +2 HP.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Charisma ≥ 3',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Alcohol_Raised_HP'
    },
    AlcoholRaisedHP2: {
      name: 'Alcohol Raised HP Rank 2',
      key: 'AlcoholRaisedHP2',
      image: 'AlcoholRaisedHP',
      type: 'SupportPerks',
      description: "Excessive drinking affected your health in a surprising way, you gained +2 HP.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Charisma ≥ 4',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Alcohol_Raised_HP'
    },
  },
  Implants: {
    ST: {
      name: 'Hypertrophy Accelerator',
      key: 'ST',
      image: 'ST',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Strength Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Strength ≤ 9',
      bonuses: [
        '+1 to Strength'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Hypertrophy_Accelerator'
    },
    PE: {
      name: 'Optics Enhancer',
      key: 'PE',
      image: 'PE',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Perception Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Perception ≤ 9',
      bonuses: [
        '+1 to Perception'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Optics_Enhancer'
    },
    EN: {
      name: 'Nociception Regulator',
      key: 'EN',
      image: 'EN',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Endurance Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Endurance ≤ 9',
      bonuses: [
        '+1 to Endurance'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Nociception_Regulator'
    },
    CH: {
      name: 'Empathy Synthesizer',
      key: 'CH',
      image: 'CH',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Charisma Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Charisma ≤ 9',
      bonuses: [
        '+1 to Charisma'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Empathy_Synthesizer'
    },
    IN: {
      name: 'Logic Co-Processor',
      key: 'IN',
      image: 'IN',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Intelligence Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Intelligence ≤ 9',
      bonuses: [
        '+1 to Intelligence'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Logic_Co-Processor'
    },
    AG: {
      name: 'Reflex Booster',
      key: 'AG',
      image: 'AG',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Agility Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Agility ≤ 9',
      bonuses: [
        '+1 to Agility'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Reflex_Booster'
    },
    LK: {
      name: 'Probability Calculator',
      key: 'LK',
      image: 'LK',
      type: 'Implants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Luck Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: 'Luck ≤ 9',
      bonuses: [
        '+1 to Luck'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Probability_Calculator'
    },
  },
  CombatImplants: {
    Dermal1: {
      name: 'Dermal Impact Armor',
      key: 'Dermal1',
      image: 'Dermal',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Dermal Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+2% Normal Damage Resistance',
        '+2% Explode Damage Resistance',
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dermal_Impact_Armor'
    },
    Phoenix1: {
      name: 'Phoenix Armor Implants',
      key: 'Phoenix1',
      image: 'Phoenix',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Phoenix Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+2% Laser Damage Resistance',
        '+2% Fire Damage Resistance',
        '+2% Plasma Damage Resistance',
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Phoenix_Armor_Implants',
    },
    Nemean1: {
      name: 'Nemean Armor',
      key: 'Nemean1',
      image: 'Nemean',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Nemean Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+10 Hit Points'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Nemean_Armor',
    },
    Dermal2: {
      name: 'Dermal Impact Assault Enhancement',
      key: 'Dermal2',
      image: 'Dermal',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Dermal Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+2% Normal Damage Resistance',
        '+1 Normal Damage Threshold',
        '+2% Explode Damage Resistance',
        '+1 Explode Damage Threshold',
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Dermal_Impact_Assault_Enhancement',
    },
    Phoenix2: {
      name: 'Phoenix Assault Enhancement',
      key: 'Phoenix2',
      image: 'Phoenix',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Phoenix Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+2% Laser Damage Resistance',
        '+1 Laser Damage Threshold',
        '+2% Fire Damage Resistance',
        '+1 Fire Damage Threshold',
        '+2% Plasma Damage Resistance',
        '+1 Plasma Damage Threshold',
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Phoenix_Assault_Enhancement',
    },
    Nemean2: {
      name: 'Nemean Armor (2)',
      key: 'Nemean2',
      image: 'Nemean',
      type: 'CombatImplants',
      description: "Go to Dr Fung in San Francisco.",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Nemean Implant',
        'Combat Armor',
        '100 000 Caps'
      ],
      requirements: '',
      bonuses: [
        '+10 Hit Points'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Nemean_Armor',
    },
  },
  Books: {
    SmallGuns: {
      name: 'Small Guns',
      key: 'SmallGuns',
      image: 'SmallGuns',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    FirstAid: {
      name: 'First Aid',
      key: 'FirstAid',
      image: 'FirstAid',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    Science: {
      name: 'Science',
      key: 'Science',
      image: 'Science',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    Repair: {
      name: 'Repair',
      key: 'Repair',
      image: 'Repair',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    Barter: {
      name: 'Barter',
      key: 'Barter',
      image: 'Barter',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    Outdoorsman: {
      name: 'Outdoorsman',
      key: 'Outdoorsman',
      image: 'Outdoorsman',
      type: 'Books',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
  },
  Drugs: {
    Buffout: {
      name: 'Buffout',
      key: 'Buffout',
      image: 'Buffout',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Combine with Nuka Cola to compensate for the lost Agility',
      ],
      requirements: '',
      bonuses: [
        '+2 to Strength',
        '+2 to Endurance',
      ],
      penalties: [
        '-1 to Agility'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Buffout'

    },
    NukaCola: {
      name: 'Nuka Cola',
      key: 'NukaCola',
      image: 'NukaCola',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Usually combined with Buffout to negate its penalty on Agility',
      ],
      requirements: '',
      bonuses: [
        '+1 to Agility'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Nuka-Cola'

    },
    Psycho: {
      name: 'Psycho',
      key: 'Psycho',
      image: 'Psycho',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Combine with Cigarettes to compensate for the lost Perception',
      ],
      requirements: '',
      bonuses: [
        '+15 to Normal Damage Resistance',
        '+1 to Strength',
        '+1 to Endurance'
      ],
      penalties: [
        '-2 to Perception'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Psycho'

    },
    Cigarettes: {
      name: 'Cigarettes',
      key: 'Cigarettes',
      image: 'Cigarettes',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Usually combined with other drugs to compensate for the lost Perception'
      ],
      requirements: '',
      bonuses: [
        '+1 to Perception'
      ],
      penalties: [
        '-1 to Charisma'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Cigarettes'

    },
    Beer: {
      name: 'Beer',
      key: 'Beer',
      image: 'Beer',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Combine with Cigarettes to compensate for the lost Perception'
      ],
      requirements: '',
      bonuses: [
        '+5% to Normal Damage Resistance',
        '+1 to Charisma'
      ],
      penalties: [
        '-2 to Perception'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Beer'

    },
    Jet: {
      name: 'Jet',
      key: 'Jet',
      image: 'Jet',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Severe Damage Resistance penalty'
      ],
      requirements: '',
      bonuses: [
        '+2 to Maximum Action Points'
      ],
      penalties: [
        '-1 to Charisma',
        '-2 to Strength',
        '-15 to Damage Resistance'
      ],
      wiki: 'https://www.fonline-reloaded.net/wiki/Jet'

    },
    RadX: {
      name: 'Rad-X',
      key: 'RadX',
      image: 'RadX',
      type: 'Drugs',
      description: "Rad-X is taken prior to exposure as a preventative measure - it boosts a character's Radiation Resistance.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+25 to Radiation Resistance'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Rad-X'

    },
    Mentats: {
      name: 'Mentats',
      key: 'Mentats',
      image: 'Mentats',
      type: 'Drugs',
      description: "Mentats are special breath mint Drugs that enhance mental capabilities, temporarily increasing Intelligence and Charisma.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [
        '+2 to Intelligence',
        '+2 to Charisma'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Mentats'

    },
    CharismaBoost: {
      name: 'Charisma Boost',
      key: 'CharismaBoost',
      image: 'CharismaBoost',
      type: 'Drugs',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        "Used to maximize the character's Party Points and Party Size",
        "This 'drug' will be removed from the planner once statted gear is implemented"
      ],
      requirements: '',
      bonuses: [
        '+1 to Charisma (Armor)',
        '+1 to Charisma (Mirrored Shades)'
      ],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Charisma'

    },
  },
  Professions: {},
  CharStats: {
    HitPoints: {
      name: 'Hit Points',
      key: 'HitPoints',
      image: 'HitPoints',
      type: 'CharStats',
      description: "Hit Points (or HP) define the amount of damage your character can take before dying.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Hit_Points'

    },
    ArmorClass: {
      name: 'Armor Class',
      key: 'ArmorClass',
      image: 'ArmorClass',
      type: 'CharStats',
      description: "Modifies the chance to hit this particular character.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Armor_Class'

    },
    ActionPoints: {
      name: 'Action Points',
      key: 'ActionPoints',
      image: 'ActionPoints',
      type: 'CharStats',
      description: "Action Points (AP) are the core of the combat system in the Fallout series. Each player Character performs actions per time in Real-Time mode, and actions per turn in Turn-Based mode. Actions are thus limited by how many Action Points you have, which regenerate depending on your combat mode. Walking, shooting, reloading, healing, picking locks, recovering from a knockdown, picking up and dropping items, are some examples of actions, each of which has an AP cost. A Character's AP is determined by their build, and Agility plays a major role.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Action_Points'

    },
    CarryWeight: {
      name: 'Carry Weight',
      key: 'CarryWeight',
      image: 'CarryWeight',
      type: 'CharStats',
      description: "A character's Carry weight defines the amount of equipment they can carry, measured in kilograms.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Carry_Weight'

    },
    MeleeDamage: {
      name: 'Melee Damage',
      key: 'MeleeDamage',
      image: 'MeleeDamage',
      type: 'CharStats',
      description: "The damage dealt using close combat weapons. Or your bare fists.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Close_Combat'

    },
    DamageResistance: {
      name: 'Damage Resistance',
      key: 'DamageResistance',
      image: 'DamageResistance',
      type: 'CharStats',
      description: "Damage Resistance (DR) is a derived statistic in the SPECIAL character system that reduces damage done to a critter.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Damage_Resistance'

    },
    PoisonResistance: {
      name: 'Poison Resistance',
      key: 'PoisonResistance',
      image: 'PoisonResistance',
      type: 'CharStats',
      description: "Poison resistance reduces the duration of character's poisoning.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Poisoned'

    },
    RadResistance: {
      name: 'Rad Resistance',
      key: 'RadResistance',
      image: 'RadResistance',
      type: 'CharStats',
      description: "Radiation resistance lowers the percentage of background radiation that affects the player. It can be temporarily boosted by using Rad-X.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Radiated'

    },
    Sequence: {
      name: 'Sequence',
      key: 'Sequence',
      image: 'Sequence',
      type: 'CharStats',
      description: "It's an ordered list of events. Characters with higher sequence can act sooner in Turn Based combat.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Sequence'

    },
    HealingRate: {
      name: 'Healing Rate',
      key: 'HealingRate',
      image: 'HealingRate',
      type: 'CharStats',
      description: "Healing Rate is a Character's ability to regenerate HP over time. Some lost Hit Points regenerate every minute (or 30 seconds if you have Fast Metabolism) based on a percentage of the Character's total health, rounded down, but no less than 3 points.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Healing_Rate'

    },
    CriticalChance: {
      name: 'Critical Chance',
      key: 'CriticalChance',
      image: 'CriticalChance',
      type: 'CharStats',
      description: "Critical Chance is the probability of scoring a Critical Hit on a target.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Critical_Chance'

    },
    PartyPoints: {
      name: 'Party Points',
      key: 'PartyPoints',
      image: 'PartyPoints',
      type: 'CharStats',
      description: "Party Points determine the number of followers a character can lead.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Followers'

    },
    MaximumPerks: {
      name: 'Maximum Perks',
      key: 'MaximumPerks',
      image: 'MaximumPerks',
      type: 'CharStats',
      description: "The total amount of Perks a character can obtain. Modified by the Skilled trait.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: ''

    },
    SkillPointsPerLevel: {
      name: 'Skill Points/Level',
      key: 'SkillPointsPerLevel',
      image: 'SkillPoints/Level',
      type: 'CharStats',
      description: "The amount of Skill Points a character gets upon gaining a level.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Skill_Points'

    },
    HitPointsPerLevel: {
      name: 'Hit Points/Level',
      key: 'HitPointsPerLevel',
      image: 'HitPoints/Level',
      type: 'CharStats',
      description: "The amount of Hit Points a character gets upon gaining a level.",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: '',
      bonuses: [],
      penalties: [],
      wiki: 'https://www.fonline-reloaded.net/wiki/Hit_Points'

    },
  },
  MysteryBoosts: {
    HubChef: {
      name: 'Hub Chef',
      key: 'HubChef',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: 'Charisma >= 4 & Intelligence >= 4',
      bonuses: ['+2 Hit-Points'],
      penalties: [],
      wiki: ''
    },
    FatherTully: {
      name: 'Father Tully',
      key: 'FatherTully',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: 'Intelligence >= 7 & Speech >= 100',
      bonuses: ['+2 Hit-Points', '+3 Laser DR'],
      penalties: [],
      wiki: ''
    },
    Mom: {
      name: 'Mom',
      key: 'Mom',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: 'Charisma >= 4 & Speech >= 100',
      bonuses: ['+5 Hit-Points'],
      penalties: [],
      wiki: ''
    },
    SadFarmer: {
      name: 'Sad Farmer',
      key: 'SadFarmer',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: '',
      bonuses: ['+15% Close Combat (flat)'],
      penalties: [],
      wiki: ''
    },
    LaserBoost: {
      name: 'Laser [disabled]',
      key: 'LaserBoost',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Laser boost is now part of Father Tully boost!',
        'This mystery boost will be removed on the next update!',
        'The boost is disabled but still here in order not to break builds that had already tagged it!',
      ],
      requirements: '',
      bonuses: ['+3 Laser DR'],
      penalties: [],
      wiki: ''
    },
    RaidersDie: {
      name: "Raiders' Die",
      key: 'RaidersDie',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: 'Gambling < 50',
      bonuses: ['+13 Gambling'],
      penalties: [],
      wiki: ''
    },
    Francis: {
      name: 'Francis',
      key: 'Francis',
      image: '',
      type: 'MysteryBoosts',
      description: "",
      modifies: [],
      modifiedBy: [],
      notes: [
        'Mystery boosts are mysterious!',
        'Info about some of the boosts may be awarded at game events!',
        'Some players might know stuff - ask around!'
      ],
      requirements: 'Strength >= 9 & Close Combat >= 125',
      bonuses: ['+4 Melee Damage'],
      penalties: [],
      wiki: ''
    },
    BecomeAMutant: {
      name: 'Become a Mutant',
      key: 'BecomeAMutant',
      image: '',
      type: 'MysteryBoosts',
      description: "Check the Wiki link below!",
      modifies: [],
      modifiedBy: [],
      notes: [],
      requirements: 'Intelligence >= 5',
      bonuses: ['+50 Hit Points', '+5 Normal DR', '+15 Rad Resistance', '+15 Poison Resistance'],
      penalties: ['CANNOT RUN!!!', 'Walking speed is slower than usual'],
      wiki: 'https://www.fonline-reloaded.net/wiki/Become_a_Mutant'
    },
  }
};
