<template>
  <div class="update-info">
    <div class="info-panel-header update green-bottom-border">
      New version is available.
    </div>
    <div class="info-panel-description update">
      <div style="font-size: 16px;">
        {{ 'An update of the app has been downloaded. Just click the button below to apply the latest changes.' }}
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px;">
        <button class="update-button" @click="$emit('updateApp')">Update App</button>
      </div>
    </div>
    <div class="info-panel-image">
      <img
        src="@/assets/img/icons/update-green-pipboy.png"
        width="90"
        height="90"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
 name: "UpdateExists",
 emits: ["updateApp"]
})
</script>

<style scoped>
.info-panel-header {
  font-size: 25px;
  text-align: left;
  margin-top: 5px;
  margin-left: 15px;
  width: 92%;
  border-bottom: 2px ridge black;
  color: black;
}
.info-panel-description {
  color: black;
  text-align: justify;
  width: 65%;
  margin-top: 10px;
  padding-left: 15px;
  font-family: "Fixedsys Excelsior 3.01", Helvetica, Arial;
  font-size: 14px;
}
.info-panel-image {
  position: absolute;
  top: 50px;
  right: 25px;
}
.update-info {
  width: 92%;
  background-color: rgba(59, 68, 57, 0.707);
  border: 2px ridge rgb(59, 216, 35);
  border-radius: 5px;
}
.update {
  color: #85fc5a;
}
.green-bottom-border {
  border-bottom: 2px ridge #85fc5a;
}
.update-button {
  position: relative;
  margin: auto;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 35px;
  color: white;
  letter-spacing: 1px;
  text-shadow: 1px 1px black;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(80, 168, 80, 0.445);
}
.update-button:hover {
  background-color: rgba(80, 168, 80, 0.884);
}
</style>