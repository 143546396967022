<template>
  <div style="display: inline;" title="Refresh App" @click="reload">
    <svg viewBox="0 0 24 24" id="refresh-icon-svg">
      <path fill="#85fc5a" d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { useStore } from "vuex"
export default defineComponent({
 name: "RefreshButton",
 setup() {
   const store = useStore()
   const reload = () => {
     window.location.reload()
   }
   return {
     state: store.state,
     reload
   }
 }
})
</script>

<style scoped>
#refresh-icon-svg {
  width: 24px;
  height: 24px;
}
#refresh-icon-svg:hover {
  cursor: pointer;
}
#refresh-icon-svg:hover > path {
  fill: #eeee99;
}
</style>