
import { useStore } from "vuex";
import { defineComponent, onMounted, ref } from "vue";
import CharacterSave from "../shared/SaveMenu/CharacterSave.vue";

export default defineComponent({
  components: { 
    CharacterSave,
  },
  name: "LoadMenu",
  setup() {
    const store = useStore();
    const panelState = ref('default')

    const closePanel = () => {
      store.dispatch('showDefaultCharStats')
    }

    onMounted(() => {

      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      panelState,
      closePanel
    };
  },
});
"https://geck.onrender.com/#/19613aa17935/7b4131373i3d3g3e507c61506750-21l-2986a506r50_29106850-614506p50-29o6z569592727383-532_211o-27z_2914_2a3d_2d1h_2e1k_2i2p_2c1z_282b74757d7e7f7g7h7i7o7t"
