const getThreshold = (value: number): { cost: number; thres: number } => {
  if (value > 200) {
    return { cost: 6, thres: 300 }
  } else if (value > 175) {
    return { cost: 5, thres: 200 }
  } else if (value > 150) {
    return { cost: 4, thres: 175 }
  } else if (value > 125) {
    return { cost: 3, thres: 150 }
  } else if (value > 100) {
    return { cost: 2, thres: 125 }
  } else {
    return { cost: 1, thres: 100 }
  }
}

export default getThreshold