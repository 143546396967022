
import { defineComponent } from "vue";
import "./build.css";

export default defineComponent({
  name: "Header",
  props: {
    msg: String,
  },
});
