<template>
  <div class="professions-wrapper">
    <button class="image-btn">
      <img
        :title="getProfessionData('SmallGuns').title"
        :class="getProfessionData('SmallGuns').style"
        :src="require(`@/assets/img/professions/${getProfessionData('SmallGuns').image}.png`)"
        alt=""
        @click="tagProfession('SmallGuns')"
      />
      <span class="profession-number">{{ getProfessionData('SmallGuns').level }}</span>
    </button>
    <button class="image-btn">
      <img
        :title="getProfessionData('BigGuns').title"
        :class="getProfessionData('BigGuns').style"
        :src="require(`@/assets/img/professions/${getProfessionData('BigGuns').image}.png`)"
        alt=""
        @click="tagProfession('BigGuns')"
      />
      <span class="profession-number">{{ getProfessionData('BigGuns').level }}</span>
    </button>
    <button class="image-btn">
      <img
        :title="getProfessionData('EnergyExpert').title"
        :class="getProfessionData('EnergyExpert').style"
        :src="require(`@/assets/img/professions/${getProfessionData('EnergyExpert').image}.png`)"
        alt=""
        @click="tagProfession('EnergyExpert')"
      />
      <span class="profession-number">{{ getProfessionData('EnergyExpert').level }}</span>
    </button>
    <button class="image-btn">
      <img
        :title="getProfessionData('DemolitionExpert').title"
        :class="getProfessionData('DemolitionExpert').style"
        :src="require(`@/assets/img/professions/${getProfessionData('DemolitionExpert').image}.png`)"
        alt=""
        @click="tagProfession('DemolitionExpert')"
      />
      <span class="profession-number">{{ getProfessionData('DemolitionExpert').level }}</span>
    </button>
    <button class="image-btn">
      <img
        :title="getProfessionData('Armorer').title"
        :class="getProfessionData('Armorer').style"
        :src="require(`@/assets/img/professions/${getProfessionData('Armorer').image}.png`)"
        alt=""
        @click="tagProfession('Armorer')"
      />
      <span class="profession-number">{{ getProfessionData('Armorer').level }}</span>
    </button>
    <button class="image-btn">
      <img
        :title="getProfessionData('Doctor').title"
        :class="getProfessionData('Doctor').style"
        :src="require(`@/assets/img/professions/${getProfessionData('Doctor').image}.png`)"
        alt=""
        @click="tagProfession('Doctor')"
      />
      <span class="profession-number">{{ getProfessionData('Doctor').level }}</span>
    </button>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {
  name: 'Professions',
  setup () {
    const store = useStore()
    const getProfessionData = (profession) => {
      const data = {
        level: +store.state.build.Professions[profession + 1].tagged + +store.state.build.Professions[profession + 2].tagged,
        available: store.state.build.Professions[profession + 1].available || store.state.build.Professions[profession + 2].available,
        style: 'image-profession',
        image: store.state.build.Professions[profession + 1].tagged ? `${profession}2` : `${profession}1`,
        title: store.state.build.Professions[profession + 1].tagged ? store.state.build.Professions[profession + 2].name : store.state.build.Professions[profession + 1].name,
      }
      if (data.available && !data.level) {
        data.style += ' available'
      } else if (data.level) {
        if (data.available) {
          data.style += ' available'
        } else {
          data.style += ' tagged'
        }
      } else {
        data.style += ' disabled'
      }
      return data
    }
    const tagProfession = (profession) => {
      const postfix = store.state.build.Professions[profession + 1].available
        ?
        '1'
        :
        store.state.build.Professions[profession + 2].available
        ?
        '2'
        :
        '0'
      if (+postfix) {
        const data = {
          profession: profession + postfix
        }
        store.dispatch('handleIncomingCommand', {
        command: 'takeProfession',
        data: data
      });
      } else {
        return
      }
    }
    return {
      state: store.state,
      getProfessionData,
      tagProfession
    }
  }
};
</script>
<style scoped>
.professions-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.image-btn {
  display: flex;
  justify-content: space-between;
  width: 30px;
  max-width: 30px;
  max-height: 28px;
  padding: 0px;
  margin: 0;
  margin-left: 6px;
  margin-right: 6px;
  background-color: transparent;
  border: none;
  position: relative;
}
.image-profession {
  height: 100%;
  width: 100%;
  margin: auto;
  -webkit-filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
}
.image-btn:focus {
  outline: none;
  box-shadow: none;
}
.profession-number {
  cursor: default;
  position: absolute;
  bottom: -6px;
  left: 2px;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 16px;
  color: rgb(236, 236, 236);
  -webkit-text-stroke: 1px black;
}
.available:hover {
  outline: none;
  box-shadow: none;
  height: 115%;
  width: 115%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
.available {
  cursor: pointer;
  -webkit-filter: grayscale(10%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(10%) drop-shadow(2px 2px 2px #222);
}
.tagged {
  cursor: default;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 5px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 5px #222);
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
</style>