
import { useStore } from "vuex"
import { defineComponent } from "vue"
import { defaultStatNamesMap, getItemPrefixData } from "@/data/gear-utils"
export default defineComponent({
 name: "CraftedItem",
 emits: [
    'resetView'
  ],
 props: ['item', 'itemIndex'],
 components: {},
 setup(props, context) {
   const store = useStore()
   const statNamesMap = { ...defaultStatNamesMap }
   const equipItem = (slot: string, key: string, item: any = undefined) => {
      const data = {
        slot,
        key,
        item
      }
      store.dispatch('handleIncomingCommand', {
        command: 'equipItem',
        data: data
      });
      context.emit('resetView')
      store.dispatch('showDefaultCharStats')
    }
   const deleteItem = (item: any, index: number) => {
      const data = {
        item,
        index
      }
      store.dispatch('deleteItemFromInventory', data)
    }   
    return {
      state: store.state,
      getItemPrefixData,
      statNamesMap,
      equipItem,
      deleteItem
   }
 },
})
