
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import "./build.css";

export default defineComponent({
  name: "SPECIAL",
  setup() {
    const store = useStore();
    const descriptions = {
      1: {
        desc: "Very Bad",
        color: "#de9d9d",
      },
      2: {
        desc: "Bad",
        color: "#e2ad92",
      },
      3: {
        desc: "Poor",
        color: "#e0bf93",
      },
      4: {
        desc: "Fair",
        color: "#e2d487",
      },
      5: {
        desc: "Average",
        color: "#d9e084",
      },
      6: {
        desc: "Good",
        color: "#c2e266",
      },
      7: {
        desc: "Very Good",
        color: "#aff35b",
      },
      8: {
        desc: "Great",
        color: "#8cf74d",
      },
      9: {
        desc: "Excellent",
        color: "#62f740",
      },
      10: {
        desc: "Heroic",
        color: "#2ef800",
      },
    };
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const modifySPECIAL = (modifier: string, statName: string) => {
      showInfo("SPECIAL", statName);
      store.dispatch('handleIncomingCommand', {
        command: 'modifySpecial',
        data: { modifier, statName }
      });
    };
    const getStatData = (stat: string) => {
      const data = {
        value: store.state.build.SPECIAL[stat].value,
        desc: descriptions[store.state.build.SPECIAL[stat].value].desc,
        color: descriptions[store.state.build.SPECIAL[stat].value].color,
      };

      return data;
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      SPECIAL: store.state.build.SPECIAL,
      modifySPECIAL,
      descriptions,
      showInfo,
      getStatData,
    };
  },
});
