
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import "./home.css";

export default defineComponent({
  name: "LinkedBuild",
  components: {},
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const loadingStatus = ref('')
    
    const goToCharacter = () => {
      store.dispatch('resetLoadingData')
      router.push('/')
    }
    onMounted(() => {
      store.dispatch('loadFromRouteParams', route.params)
      loadingStatus.value = store.state.system.loading.status
    })

    return {
      state: store.state,
      route,
      goToCharacter,
      loadingStatus
    }
  }
});
