<template>
  <div class="level-up-container">
    <a href="" id="dlJSON" style="display: none;"></a>
    <!-- <div title="Download JSON build file">
      <svg class="download-icon-img" viewBox="0 0 24 24" @click="dlJSON" v-if="showSettings">
        <path d="M5,3H7V5H5V10A2,2 0 0,1 3,12A2,2 0 0,1 5,14V19H7V21H5C3.93,20.73 3,20.1 3,19V15A2,2 0 0,0 1,13H0V11H1A2,2 0 0,0 3,9V5A2,2 0 0,1 5,3M19,3A2,2 0 0,1 21,5V9A2,2 0 0,0 23,11H24V13H23A2,2 0 0,0 21,15V19A2,2 0 0,1 19,21H17V19H19V14A2,2 0 0,1 21,12A2,2 0 0,1 19,10V5H17V3H19M12,15A1,1 0 0,1 13,16A1,1 0 0,1 12,17A1,1 0 0,1 11,16A1,1 0 0,1 12,15M8,15A1,1 0 0,1 9,16A1,1 0 0,1 8,17A1,1 0 0,1 7,16A1,1 0 0,1 8,15M16,15A1,1 0 0,1 17,16A1,1 0 0,1 16,17A1,1 0 0,1 15,16A1,1 0 0,1 16,15Z" />
        <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z" />
      </svg>
    </div> -->
    <div title="Show Load Menu">
      <svg class="settings-icon-img" viewBox="0 0 24 24" @click="showSettings = !showSettings" v-if="!showSettings">
        <path d="M15 17V14H18V12L22 15.5L18 19V17H15M17 18V21H14V23L10 19.5L14 16V18H17M12 8C9.79 8 8 9.8 8 12C8 13.91 9.35 15.54 11.21 15.92L16 11.86C15.93 9.71 14.16 8 12 8M12 14C10.9 14 10 13.11 10 12S10.9 10 12 10 14 10.9 14 12 13.11 14 12 14M21.66 8.73L19.66 5.27C19.54 5.05 19.28 4.96 19.05 5.05L16.56 6.05C16.05 5.64 15.5 5.31 14.87 5.05L14.5 2.42C14.46 2.18 14.25 2 14 2H10C9.75 2 9.54 2.18 9.5 2.42L9.13 5.07C8.5 5.33 7.96 5.66 7.44 6.07L5 5.05C4.77 4.96 4.5 5.05 4.39 5.27L2.39 8.73C2.26 8.94 2.31 9.22 2.5 9.37L4.57 11L4.5 12L4.57 13L2.46 14.63C2.26 14.78 2.21 15.06 2.34 15.27L4.34 18.73C4.45 19 4.74 19.11 5 19L5 19L7.5 18C7.74 18.19 8 18.37 8.26 18.53L9.91 17.13C9.14 16.8 8.46 16.31 7.91 15.68L5.5 16.68L4.73 15.38L6.8 13.8C6.4 12.63 6.4 11.37 6.8 10.2L4.69 8.65L5.44 7.35L7.85 8.35C8.63 7.45 9.68 6.82 10.85 6.57L11.25 4H12.75L13.12 6.62C14.29 6.86 15.34 7.5 16.12 8.39L18.53 7.39L19.28 8.69L17.2 10.2C17.29 10.46 17.36 10.73 17.4 11H19.4L21.5 9.37C21.72 9.23 21.78 8.95 21.66 8.73M12 8C9.79 8 8 9.8 8 12C8 13.91 9.35 15.54 11.21 15.92L16 11.86C15.93 9.71 14.16 8 12 8M12 14C10.9 14 10 13.11 10 12S10.9 10 12 10 14 10.9 14 12 13.11 14 12 14M12 8C9.79 8 8 9.8 8 12C8 13.91 9.35 15.54 11.21 15.92L16 11.86C15.93 9.71 14.16 8 12 8M12 14C10.9 14 10 13.11 10 12S10.9 10 12 10 14 10.9 14 12 13.11 14 12 14Z" />
      </svg>
    </div>
    <div title="Hide Load Menu">
      <svg class="settings-icon-img" viewBox="0 0 24 24" @click="showSettings = !showSettings" v-if="showSettings">
        <path d="M3,16.74L7.76,12L3,7.26L7.26,3L12,7.76L16.74,3L21,7.26L16.24,12L21,16.74L16.74,21L12,16.24L7.26,21L3,16.74M12,13.41L16.74,18.16L18.16,16.74L13.41,12L18.16,7.26L16.74,5.84L12,10.59L7.26,5.84L5.84,7.26L10.59,12L5.84,16.74L7.26,18.16L12,13.41Z" />
      </svg>
    </div>
    <div>
      <div class="settings-container" v-if="showSettings">
        <div class="settings-title">Load Level</div>
        <div class="static-levels-container">
          <button
            class="load-level-button"
             v-for="(build, index) in getLoadLevelData().static" :key="index"
            @click="loadBuild('static', index)"
          >
              {{ 'Level ' + getLoadLevelData().static[index].info.currentLevel }}
          </button>
        </div>
        <div class="dynamic-levels-container">
          <button
            class="load-level-button"
             v-for="(build, index) in getLoadLevelData().dynamic" :key="index"
            @click="loadBuild('dynamic', index)"
          >
              {{ 'Level ' + getLoadLevelData().dynamic[index].info.currentLevel }}
          </button>
        </div>
      </div>
      <div class="level-up-skillpoints-container" v-if="!showSettings">
        <div class="level-up-skillpoints-name">SKILL POINTS</div>
        <div class="level-up-skillpoints-value">
          {{ getLevelData().skillPoints }}
        </div>
      </div>
      <div class="level-up-level-container" v-if="!showSettings">
        <!-- <img class="settings-icon-img" src="@/assets/img/icons/gear-outline.png" alt="" /> -->
        <div class="level-up-level-name">LEVEL</div>
        <div class="level-up-level-value">{{ getLevelData().level }}</div>
      </div>
      <div v-if="!showSettings">
        <button
          class="level-up-button"
          @click="levelUp()"
        >
          Level Up
        </button>
      </div>
      <div v-if="!showSettings" class="level-up-modifier-button-container">
        <button
          class="level-up-modifier-button"
          :style="
            multiplier === 1 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(1)"
        >
          x1
        </button>
        <button
          v-if="showNextPerkButton()"
          :class="checkForAvailablePerk() ? 'level-up-modifier-button-disabled' : 'level-up-modifier-button'"
          :disabled="checkForAvailablePerk()"
          :style="
            multiplier === 5 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(5)"
        >
          x5
        </button>
        <button
          v-if="showNextPerkButton()"
          :class="checkForAvailablePerk() ? 'level-up-modifier-button-disabled' : 'level-up-modifier-button'"
          :disabled="checkForAvailablePerk()"
          :style="
            multiplier === 10 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(10)"
        >
          x10
        </button>
        <button
          v-if="showNextPerkButton()"
          :class="checkForAvailablePerk() ? 'level-up-modifier-button-disabled' : 'level-up-modifier-button'"
          :disabled="checkForAvailablePerk()"
          :style="
            multiplier === 100 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(100)"
        >
          x100
        </button>
        <button
          v-if="state.build.info.currentLevel < 24"
          :class="checkForAvailablePerk()
          ?
          multiplier === -1
          ?
          'level-up-modifier-button-disabled next-perk skip-perk blink'
          :
          'level-up-modifier-button-disabled next-perk'
          :
          'level-up-modifier-button next-perk'"
          :disabled="checkForAvailablePerk()"
          :style="
            multiplier === -1 ? 'background-color: rgb(174, 180, 176);' : ''
          "
          @click="toggleMultiplier(-1)"
        >
          {{checkForAvailablePerk() && multiplier === -1 ? 'CHOOSE PERK' : 'To Next Perk'}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, defineComponent } from "vue";
import { useStore } from "vuex";
import LS from '@/utils/localStorageHandler'
export default defineComponent({
  name: "Level Up",
  setup() {
    const store = useStore();
    const multiplier = ref(1);
    const showSettings = ref(false);
    const showNextPerkButton = () => {
      return store.state.build.info.currentLevel > 23
    }
    const checkForAvailablePerk = () => {
      return store.state.build.info.currentPerk.available
    }
    const toggleMultiplier = (value) => {
      multiplier.value = value;
    };
    const getLevelData = () => {
      return {
        skillPoints: store.state.build.info.availableSkillPoints,
        level: store.state.build.info.currentLevel,
      };
    };
    const getLoadLevelData = () => {
      const data = LS.getCurrentBuild(store.state.system.storage)
      const trimStatic = data.static.length > 3 ? [...data.static].slice(0,3) : data.static
      return {
        static: trimStatic,
        dynamic: data.dynamic
      }
    };
    const loadBuild = (type, slot) => {
      const data = LS.getCurrentBuild(store.state.system.storage)
      if (data[type][slot]) {
        store.dispatch('handleIncomingCommand', {
        command: 'loadBuild',
        data: data[type][slot]
      });
        showSettings.value = false
      }
    }
    const levelUp = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'levelUp',
        data: { multiplier: multiplier.value }
      });
    };

    const dlJSON = () => {
      const date = new Date().toLocaleString().split(' ').join('').replace(',', '__').replaceAll('/', '_').replaceAll(':', '_')
      const fileName = 'build_' + date + '.json'
      const staticBuilds = LS.getCurrentBuild(store.state.system.storage)
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(staticBuilds));
      const dlAnchorElem = document.getElementById('dlJSON');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', fileName);
      dlAnchorElem.click();
    }
    watch(() => store.state.build.info.currentLevel, (to) => {
      if (to === 24) {
        multiplier.value = 1
      }
    })
    return {
      state: store.state,
      multiplier,
      showNextPerkButton,
      checkForAvailablePerk,
      toggleMultiplier,
      getLevelData,
      getLoadLevelData,
      levelUp,
      showSettings,
      loadBuild,
      dlJSON
    };
  },
});
</script>
<style scoped>
.level-up-container {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  /* margin-top: 5px; */
  background-color: rgba(192, 188, 188, 0.219);
  /* border: 1px solid black; */
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 5px;
  height: 88px;
  width: 95%;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
}
.level-up-skillname {
  display: inline-block;
  width: 100%;
  height: 16px;
  font-size: 16px;
  text-shadow: 2px 2px 3px rgb(44, 43, 43);
  margin-top: 3px;
  margin-bottom: 3px;
}
.level-up-minus,
.level-up-plus {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 25px;
  /* height: 26px; */
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 26px;
  font-weight: 700;
  color: rgb(107, 179, 111);
  text-shadow: 1px 1px rgb(48, 41, 41);
  /* -webkit-text-stroke: 1px black; */
  /* border: 1px solid black; */
  /* border-radius: 50%; */
}
/* .level-up-progress {
  display: block;
  margin: auto;
  width: 95%;
  height: 10px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: rgba(80, 168, 80, 0.747);
} */
.level-up-modifier-button {
  display: inline-block;
  cursor: pointer;
  width: 23%;
  padding: 0;
  margin-top: 5px;
  margin-left: 1px;
  margin-right: 1px;
  color: #85fc5a;
  text-align: center;
  text-shadow: 1px 1px rgb(48 41 41);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.245);
  /* border: 1px solid black; */
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
}
.level-up-modifier-button-disabled {
  display: inline-block;
  cursor: default;
  width: 23%;
  padding: 0;
  margin-top: 5px;
  margin-left: 1px;
  margin-right: 1px;
  color: rgb(194, 194, 194);
  text-align: center;
  text-shadow: 1px 1px rgb(48 41 41);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.245);
  /* border: 1px solid black; */
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
}
.next-perk {
  width: 70%;
}
.skip-perk {
  color: rgba(255, 123, 83, 0.61);
}
.disabled-btn:hover {
  background-color: rgb(202, 0, 0);
}
.level-up-modifier-button:hover {
  background-color: rgb(174, 180, 176);
}
.level-up-modifier-button:first-child {
  border-radius: 4px 0px 0px 4px;
}
.level-up-modifier-button:last-child {
  border-radius: 0px 4px 4px 0px;
}
.level-up-modifier-button-disabled:last-child {
  border-radius: 0px 4px 4px 0px;
}
.level-up-skillpoints-container,
.level-up-level-container {
  display: inline-block;
  position: relative;
  color: white;
  margin: auto;
  margin-left: 2px;
  width: 46%;
  font-size: 12px;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
}
.settings-icon-img {
  z-index: 1;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
  fill: #85fc5a;
}
.settings-icon-img:hover {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
  fill: #d4f7c8;
}
.download-icon-img {
  z-index: 1;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  fill: #85fc5a;
}
.download-icon-img:hover {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  fill: #d4f7c8;
}
.level-up-button {
  cursor: pointer;
  margin: auto;
  width: 95%;
  height: 22px;
  line-height: 18px;
  color: white;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.551);
  border-radius: 5px;
  background-color: rgba(80, 168, 80, 0.61);
  -webkit-text-stroke: 1px black;
}
.level-up-button:hover {
  background-color: rgba(80, 168, 80, 0.884);
  border: 1px solid rgb(255, 255, 255);

}
/* .reset-button:hover {
  background-color: rgba(80, 168, 80, 0.884);
} */

.undo-level-up-button {
  margin: auto;
  margin-left: 5px;
  width: 27%;
  height: 22px;
  color: white;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: rgba(255, 123, 83, 0.61);
}
.undo-level-up-button:hover {
  background-color: rgba(255, 184, 162, 0.61);
}
.undo-level-up-img {
  height: 14px;
  width: 14px;
}
.level-up-skillpoints-name,
.level-up-level-name {
  width: 100%;
  z-index: 0;
  color: #85fc5a;
}
.level-up-skillpoints-value,
.level-up-level-value {
  width: 100%;
  font-size: 22px;
  height: 24px;
  /* border: 1px solid white; */
  margin-bottom: 3px;
  -webkit-text-stroke: 1px black;
}
.settings-title {
  margin-bottom: 6px;
}
.static-levels-container,
.dynamic-levels-container {
  display: flex;
  justify-content: space-around;
  margin: 3px;
}
.load-level-button {
  padding: 0;
  border: none;
  background-color: transparent;
  width: 40px;
  height: 30px;
  color: #85fc5a;
  font-family: "Fixedsys Excelsior 3.01", Helvetica, Arial;
  text-shadow: 1px 1px rgb(48, 41, 41);
}
.load-level-button:focus {
  box-shadow: none;
  outline: none;
}
.load-level-button:hover {
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  width: 40px;
  height: 30px;
  color: khaki;
}
</style>