<template>
  <div
    :class="checkTagged() ? 'checkbox checked' : 'checkbox'"
    @click="tag(category, attribute)"
  ></div>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Checkbox",
  props: ["category", "attribute"],
  setup(props) {
    const store = useStore();
    const tag = (category, attribute) => {
      store.dispatch('handleIncomingCommand', {
        command: category === "Skills" ? 'tagSkill' : 'tagTrait',
        data: attribute
      });
    };
    const checkTagged = () => {
      return store.state.build[props.category][props.attribute].tagged;
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      build: store.state.build,
      tag,
      checkTagged,
    };
  },
});
</script>
<style scoped>
.checkbox {
  cursor: pointer;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  width: 9px;
  height: 9px;
  border: 1px solid white;
  border-radius: 50%;
}
.checked {
  background-color: rgb(199, 198, 198);
}
</style>