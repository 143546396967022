
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import Checkbox from "@/components/desktop/subcomponents/Checkbox.vue";
import "./build.css";

export default defineComponent({
  name: "Skills",
  components: {
    Checkbox,
  },
  setup() {
    const store = useStore();
    const taggedSkills = computed(() => store.state.build.info.taggedSkills)
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const tagSkill = (skill: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'tagSkill',
        data: skill
      });
    }
    const getSkillData = (skill: string) => {
      const data = {
        value: store.state.build.Skills[skill].value,
        tagged: store.state.build.Skills[skill].tagged,
      };
      return data;
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      Skills: store.state.build.Skills,
      taggedSkills,
      showInfo,
      tagSkill,
      getSkillData,
    };
  },
});
