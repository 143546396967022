
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import "./build.css";

export default defineComponent({
  name: "Pool",
  setup() {
    const store = useStore();
    const charPoints = computed(() => store.state.build.SPECIAL.PL.value)
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    return {
      state: store.state,
      showInfo,
      charPoints
    };
  },
});
