
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import "./build.css";
import update from '@/mixins/update'
import UpdateExists from "../shared/InfoPanel/UpdateExists.vue";
import VersionInfo from "../shared/InfoPanel/VersionInfo.vue";
import DefaultContent from "../shared/InfoPanel/DefaultContent.vue";
import VersionButton from "../shared/InfoPanel/VersionButton.vue";
import CommunityLinks from "../shared/CommunityLinks.vue";
import RefreshButton from "../shared/InfoPanel/RefreshButton.vue";

export default defineComponent({
  components: { UpdateExists, VersionInfo, DefaultContent, VersionButton, CommunityLinks, RefreshButton },
  name: "InfoPanel",
  mixins: [update],
  setup() {
    const store = useStore();
    const showVersionInfo = ref(false)
    const showInfo = (category: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category }
      });
    };
    return {
      state: store.state,
      showVersionInfo,
      showInfo
    };
  },
});
