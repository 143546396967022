<template>
  <div
    class="table-wrapper"
  >
    <table>
      <tr>
        <td class="no-border"></td>
        <th scope="col" colspan="2" class="th-border" style="font-size: 16px;">Head</th>
        <th scope="col" colspan="2" class="th-border" style="font-size: 16px;">Body</th>
      </tr>
      <tr>
        <td>
          <div>
            <span style="color: #85fc5a;">AC:</span>
            {{ Math.round(state.build.CharStats.ArmorClass.value) }}
          </div>
        </td>
        <th scope="col">DT</th>
        <th scope="col">DR</th>
        <th scope="col">DT</th>
        <th scope="col">DR</th>
      </tr>
      <tr>
        <th scope="row" class="no-border">Normal</th>
        <td>{{ getResistanceData.advanced.head.NoDT }}</td>
        <td>{{ getResistanceData.advanced.head.NoDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.NoDT }}</td>
        <td>{{ getResistanceData.advanced.body.NoDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Laser</th>
        <td>{{ getResistanceData.advanced.head.LaDT }}</td>
        <td>{{ getResistanceData.advanced.head.LaDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.LaDT }}</td>
        <td>{{ getResistanceData.advanced.body.LaDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Fire</th>
        <td>{{ getResistanceData.advanced.head.FiDT }}</td>
        <td>{{ getResistanceData.advanced.head.FiDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.FiDT }}</td>
        <td>{{ getResistanceData.advanced.body.FiDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Plasma</th>
        <td>{{ getResistanceData.advanced.head.PlDT }}</td>
        <td>{{ getResistanceData.advanced.head.PlDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.PlDT }}</td>
        <td>{{ getResistanceData.advanced.body.PlDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Explode</th>
        <td>{{ getResistanceData.advanced.head.ExDT }}</td>
        <td>{{ getResistanceData.advanced.head.ExDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.ExDT }}</td>
        <td>{{ getResistanceData.advanced.body.ExDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Electrical</th>
        <td>{{ getResistanceData.advanced.head.ElDT }}</td>
        <td>{{ getResistanceData.advanced.head.ElDR }}<span class="percent-span">%</span></td>
        <td>{{ getResistanceData.advanced.body.ElDT }}</td>
        <td>{{ getResistanceData.advanced.body.ElDR }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Radiation</th>
        <td colspan="4">{{ getResistanceData.radiation }}<span class="percent-span">%</span></td>
      </tr>
      <tr>
        <th scope="row" class="no-border">Poison</th>
        <td colspan="4">{{ getResistanceData.poison }}<span class="percent-span">%</span></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, defineComponent } from "vue"
export default defineComponent({
 name: "ResistanceTable",
 components: {},
 setup() {
   const store = useStore()
   const getResistanceData = computed(() => {
     return {
       advanced: store.state.build.AdvancedDR,
       radiation: store.state.build.CharStats.RadResistance.value,
       poison: store.state.build.CharStats.PoisonResistance.value
     }
   })
    return {
      state: store.state,
      getResistanceData
   }
 },
})
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  position: relative;
}
.percent-span {
  margin-left: 3px;
}
table {
  background-color: rgba(192, 188, 188, 0.219);
  width: 95%;
  margin: auto;
  row-gap: 0px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 3px;
}
tr, td {
  height: 17px;
}
td {
  border: 1px solid rgba(255, 255, 255, 0.285);
  border-radius: 3px;
  font-size: 16px;
  text-shadow: 1px 1px 3px black;
  line-height: 16px;
  font-weight: normal;
  color: white;
}
th {
  background: transparent;
  font-weight: normal;
  width: 90px;
}
/* .th-border {

} */
tr:nth-child(2n + 3) {
  background: rgba(45, 54, 38, 0.37);
}
.no-border {
  border: 1px transparent;
  border-radius: 3px;
}
</style>