
import { useStore } from "vuex";
import { defineComponent, onMounted } from "vue";
import "./level-phase-containers.css";

export default defineComponent({
  name: "Perks",
  setup() {
    const store = useStore();
    const getButtonStyle = (category: string, perk: string) => {
      const perkData = store.state.build[category][perk];
      const currentPerk = store.state.build.info.currentPerk;
      if (perkData.tagged) {
        return "perk-button perk-tagged";
      } else if (perkData.available) {
        if ((category === "Perks" && currentPerk.available) || ['SupportPerks', 'MysteryBoosts'].includes(category)) {
          return "perk-button perk-available";
        } else {
          return "perk-button perk-not-available";
        }
      } else {
        return "perk-button perk-not-available";
      }
    };
    const tagPerk = (category: string, perk: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'tagPerk',
        data: { category, perk }
      });
    };
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const getPerkWarningStyle = () => {
      const currentLevel = store.state.build.info.currentLevel
      const nextPerkAt = store.state.build.info.currentPerk.nextAtLevel
      const skilled = store.state.build.Traits.Skilled.tagged
      const diff = nextPerkAt - currentLevel

      if (diff === 1) {
        return 'color: red;'
      } else if (diff === 2) {
        return 'color: orange;'
      } else if (diff === 3) {
        return skilled ? 'color: yellow' : 'color: #85fc5a;'
      } else if (diff === 4) {
        return 'color: #85fc5a;'
      } else {
        return 'color: red;'
      }
    }
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      getButtonStyle,
      tagPerk,
      showInfo,
      getPerkWarningStyle
    };
  },
});
