<template>
  <div class="update-info">
    <div class="info-panel-header update green-bottom-border">
      <div style="display: flex; justify-content: space-between">
        <span>{{ 'Current Version: ' + latestUpdate.version }}</span>
        <span>{{ latestUpdate.date }}</span>
      </div>
    </div>
    <div class="info-panel-description update">
      <div class="ul-container">
        <ul>
          <li v-for="item in latestUpdate.items" :key="item">{{ item }}</li>
        </ul>
      </div>
    </div>
    <div class="info-panel-image">
      <img
        src="@/assets/img/icons/update-green-pipboy.png"
        width="90"
        height="90"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { defineComponent } from "vue"
export default defineComponent({
  name: "VersionInfo",
  components: {},
  setup() {
    const store = useStore()
    const latestUpdate = store.state.system.versionLog[store.state.system.versionLog.length - 1]
    return {
      state: store.state,
      latestUpdate
    }
  },
})
</script>

<style scoped>
.info-panel-header {
  font-size: 25px;
  text-align: left;
  margin-top: 5px;
  margin-left: 15px;
  width: 92%;
  border-bottom: 2px ridge black;
  color: black;
}
.info-panel-description {
  color: black;
  text-align: justify;
  width: 65%;
  margin-top: 10px;
  padding-left: 15px;
  font-family: "Fixedsys Excelsior 3.01", Helvetica, Arial;
  font-size: 14px;
}
.info-panel-image {
  position: absolute;
  top: 50px;
  right: 25px;
}
.update-info {
  width: 92%;
  background-color: rgba(59, 68, 57, 0.707);
  border: 2px ridge rgb(59, 216, 35);
  border-radius: 5px;
}
.update {
  color: #85fc5a;
}
.green-bottom-border {
  border-bottom: 2px ridge #85fc5a;
}
ul {
  text-align: left;
  direction: ltr;
  padding-inline-start: 20px;
  margin-block-start: .5em;
}
li {
  margin-bottom: 6px;
}
.ul-container {
  direction: rtl;
  max-height: 126px;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 182, 74);
  border: 1px transparent;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(179, 230, 172);
}
::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}
::-moz-scrollbar-button, ::-webkit-scrollbar-button {
  width: 0px;
}

</style>