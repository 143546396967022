
import { useStore } from "vuex";
import { defineComponent, onMounted, ref } from "vue";
import "./build.css";
import CharacterTable from "../level/charStats/CharacterTable.vue";
import CharStatsTable from "../level/charStats/CharStatsTable.vue";
import ResistanceTable from "../level/charStats/ResistanceTable.vue";
import GearTable from "../level/charStats/GearTable.vue";
import EquipPanel from "../level/charStats/EquipPanel.vue";

export default defineComponent({
  components: { 
    CharacterTable, 
    CharStatsTable, 
    ResistanceTable, 
    GearTable,
    EquipPanel
  },
  name: "CharStats",
  setup() {
    const store = useStore();
    const panelState = ref('default')
    const equipGear = (slot: string) => {
      panelState.value = slot ? slot : 'default'
    }
    const getStatData = (stat: string) => {
      return {
        name: store.state.build.CharStats[stat].name,
        value: store.state.build.CharStats[stat].value,
      };
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      CharStats: store.state.build.CharStats,
      getStatData,
      panelState,
      equipGear
    };
  },
});
