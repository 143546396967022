<template>
  <div class="boosts-wrapper">
    <div class="buttons-panel-text">
      <span :class="marshalBoostTagged() ? '' : 'blink'">
        {{ getText() }}
      </span>
    </div>
    <div
      class="buttons-div"
      :style="state.build.info.marshalBoost ? 'margin-top: 5px;' : ''"
    >
      <div class="buttons-wrapper">
        <button
          class="image-btn"
          v-for="boost in Object.keys(Boosts)"
          :title="state.build.Boosts[boost].tagged ? 'Boosted ' + state.build.Boosts[boost].name + ' +10%' : state.build.Boosts[boost].available ? 'Boost ' + state.build.Boosts[boost].name : state.build.Boosts[boost].name + ' boost is no longer available'"
          :disabled="!state.build.Boosts[boost].available"
          :key="boost"
          @click="tagBoost(boost)"
        >
          <img
            :class="
              state.build.Boosts[boost].available
                ? 'image-boost'
                : state.build.Boosts[boost].tagged
                ? 'tagged-image-boost'
                : 'disabled-image-boost'
            "
            :src="getBoostImage(boost)"
            alt=""
          />
        </button>
      </div>
    </div>
    <div class="marshal-div">
      <img class="image-marshal" src="@/assets/img/boosts/marshal.gif" alt="" />
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "Boosts",
  setup() {
    const store = useStore();
    const marshalBoostTagged = () => {
      return store.state.build.info.marshalBoost;
    };
    const getText = () => {
      const boosts = Object.keys(store.state.build.Boosts);
      let text = "Marshal's 10% boost!";
      boosts.forEach((boost) => {
        if (store.state.build.Boosts[boost].tagged) {
          text = `${store.state.build.Boosts[boost].name} +10%`;
        }
      });
      return text;
    };
    const getBoostImage = (boost) => {
      return require(`@/assets/img/boosts/${boost}.png`);
    };
    const tagBoost = (boost) => {
      const data = {
        skill: boost,
        modifier: "boost",
        multiplier: 1,
      };
      store.dispatch('handleIncomingCommand', {
        command: 'modifySkill',
        data: data
      });
    };
    return {
      state: store.state,
      Boosts: store.state.build.Boosts,
      marshalBoostTagged,
      getText,
      getBoostImage,
      tagBoost,
    };
  },
};
</script>
<style scoped>
.boosts-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.buttons-div {
  display: inline-block;
  width: 75%;
}
.buttons-wrapper {
  cursor: default;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.marshal-div {
  display: inline-block;
  width: 20%;
}
.image-marshal {
  width: 70%;
}
.buttons-panel-text {
  width: 100%;
  font-size: 12px;
  text-shadow: 1px 1px rgb(48, 41, 41);
  max-height: 12px;
  color: antiquewhite;
}
.image-btn {
  cursor: default;
  width: 30%;
  max-width: 30%;
  max-height: 27px;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: 1px transparent;
}
.image-boost {
  height: 100%;
  width: 100%;
  margin: auto;
  -webkit-filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
}
.tagged-image-boost {
  cursor: default;
  height: 80%;
  width: 80%;
  border: 1px solid rgb(134, 134, 134);
  border-radius: 5px;
  margin: auto;
  -webkit-filter: contrast(120%) brightness(1.3) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.3) drop-shadow(1px 1px 1px #222);
  border: 1px solid rgba(255, 255, 255, 0.245);
  background-color: rgba(94, 121, 87, 0.596);
  padding: 3px;
}
.disabled-image-boost {
  cursor: default;
  height: 70%;
  width: 70%;
  margin: auto;
  -webkit-filter: grayscale(80%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(80%) drop-shadow(2px 2px 2px #222);
}
.image-btn:focus {
  outline: none;
  box-shadow: none;
}
.image-boost:hover {
  outline: none;
  box-shadow: none;
  height: 115%;
  width: 115%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
</style>