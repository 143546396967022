
import { useStore } from "vuex";
import { defineComponent, onMounted, ref, watch } from "vue";
import CharacterLoad from "../shared/LoadMenu/CharacterLoad.vue";
import CharacterPresets from '../../../data/presets';

export default defineComponent({
  components: { 
    CharacterLoad
  },
  name: "LoadMenu",
  setup() {
    const store = useStore();
    const panelState = ref('default')
    const characters = ref([])
    const presets = ref(CharacterPresets)
    const activeSection = ref("Saved");
    const toggleActiveSection = (value: string) => {
      activeSection.value = value;
    };
    const closePanel = () => {
      store.dispatch('showDefaultCharStats')
    }
    watch(() => store.state.library, () => {
      characters.value = Object.values(store.state.library);
    })
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });

      characters.value = Object.values(store.state.library || {});
    });
    return {
      state: store.state,
      panelState,
      characters,
      presets,
      closePanel,
      activeSection,
      toggleActiveSection
    };
  },
});
