
import { useStore } from "vuex"
import { defineComponent, ref } from "vue"
import { Inventory } from "@/data/items/gear/Inventory";
import { defaultStatNamesMap, getItemPrefixData } from "@/data/gear-utils"
import InventoryItem from "../../shared/InventoryItem.vue";
export default defineComponent({
  name: "Equip Panel",
  props: ['gearSlot'],
  emits: [
    'equip',
    'resetView'
  ],
  components: { InventoryItem },
  setup(props, context) {
    const store = useStore()
    const activeTab = ref('Basic')
    const toggleActiveTab = (tab) => {
      activeTab.value = tab
    }
    const getGearImage = (gear: string) => {
      let img;
      try {
        img = require(`@/assets/img/gear/${props.gearSlot}/${gear}.gif`);
      } catch (e) {
        img = require("@/assets/img/gear/body/VaultSuit.gif");
      }
      return img;
    };
    const statNamesMap = { ...defaultStatNamesMap }
    const equipItem = (slot: string, key: string, item: any = undefined) => {
      const data = {
        slot,
        key,
        item
      }
      store.dispatch('handleIncomingCommand', {
        command: 'equipItem',
        data: data
      });
      context.emit('equip')
    }
    const resetView = () => {
      context.emit('equip')
      context.emit('resetView')
    }
    const craftItem = (item: any) => {
      store.dispatch('setItemForCrafting', item)
      store.dispatch('toggleCraftMenu')
    }
    const deleteItem = (item: any, index: number) => {
      const data = {
        item,
        index
      }
      store.dispatch('deleteItemFromInventory', data)
    }
    return {
      state: store.state,
      activeTab,
      statNamesMap,
      toggleActiveTab,
      getGearImage,
      equipItem,
      craftItem,
      deleteItem,
      items: Inventory[props.gearSlot],
      getItemPrefixData,
      resetView
    }
  },
})
