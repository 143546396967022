
import { useStore } from "vuex";
import { defineComponent } from "vue";
import "./build.css";

export default defineComponent({
  name: "Footer",
  setup() {
    const store = useStore();
    const disableSubmit = () => {
      return !(
        store.state.build.info.taggedSkills === 3 &&
        store.state.build.SPECIAL.PL.value === 0
      );
    };
    const resetBuild = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'resetBuild',
        data: null
      });
    };
    const createChar = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'createChar',
        data: null
      });
    };
    return {
      state: store.state,
      disableSubmit,
      resetBuild,
      createChar,
    };
  },
});
