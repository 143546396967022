
import { useStore } from "vuex";
import { defineComponent, onMounted } from "vue";
import './styles/app.css'
import './styles/vue-transitions.css'
import './styles/box-shadow-transitions.css'

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    const store = useStore();

    // Wait until innerheight changes, for max 120 frames
    function orientationChanged() {
      const timeout = 120;
      return new window.Promise(function(resolve) {
        const go = (i, height0) => {
          window.innerHeight != height0 || i >= timeout ?
            resolve(true) :
            window.requestAnimationFrame(() => go(i + 1, height0));
        };
        go(0, window.innerHeight);
      });
    }

    const handleScreenData = () => {
      const data = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      }
      store.dispatch('handleScreenData', data)
    }
    onMounted(() => {
      const latestVersion = store.state.system.versionLog[store.state.system.versionLog.length - 1].version
      latestVersion ? document.title = 'g.e.c.k. v' + latestVersion : null

      window.resizeTo(1400, 790)

      store.dispatch('handleIncomingCommand', {
        command: 'equipDefaultGear',
        data: null
      })
      store.dispatch('handleIncomingCommand', {
        command: 'loadSavedData',
        data: null
      })

      window.addEventListener('resize', handleScreenData)

      window.addEventListener('orientationchange', function () {
        orientationChanged().then(function() {
          // Profit
          handleScreenData()
        });
      });
      window.addEventListener('keydown', function (event) {
        if (event.key === 'Shift' && !store.state.ui.lockInfoPanel) {
          store.state.ui.lockInfoPanel = true
          // console.log('SHIFT IS PRESSED')
        }
      });
      window.addEventListener('keyup', function (event) {
        if (event.key === 'Shift') {
          store.state.ui.lockInfoPanel = false
          // console.log('SHIFT IS RELEASED')
        }
      });
    })

    return {
      state: store.state,
    };
  },
});
