import { createStore } from 'vuex'
import newBuild from '@/data/default-build'
import methods from '@/data/methods'
import infoPanelData from '@/data/info-build'
import getThresholdData from '@/utils/getSkillThreshold'
import LS from '@/utils/localStorageHandler'
import { Inventory } from '@/data/items/gear/Inventory'
import versionLog from '@/data/version-log'
import dict from '@/data/dict'
import logUtils from '@/utils/logUtils'

const sessionBuild = LS.getLastSessionBuild()
LS.initLocalStorage()
const savedBuild = LS.getLastBuild();
const library = LS.getLibrary();
const inventory = LS.getInventory();
const defaultBuild = JSON.parse(JSON.stringify(newBuild));

export default createStore({
  state: {
    library: library,
    inventory: inventory,
    build: sessionBuild ? sessionBuild : savedBuild ? savedBuild : defaultBuild,
    craftedItem: {},
    infoPanel: { ...infoPanelData.Default.InfoPanel },
    ui: {
      showPerks: false,
      showSupportPerks: true,
      showCharSubPanel: false,
      showLoadMenu: false,
      showSaveMenu: false,
      lockInfoPanel: false,
      activeCharStatsView: 'charStats',
      activePopup: 'infoPopup',
      progressBarStyle: {
        activeStyle: 'default',
        default: {
          active: true,
          next: 'currentAndMax'
        },
        currentAndMax: {
          active: false,
          next: 'percent'
        },
        percent: {
          active: false,
          next: 'none'
        },
        none: {
          active: false,
          next: 'default'
        },
      },
    },
    system: {
      loading: {
        active: false,
        error: '',
        status: '',
        log: [],
        limit: -1
      },
      storage: sessionBuild ? sessionStorage : localStorage,
      minifyPanels: {
        info: false,
        skills: false,
        charStats: false
      },
      screen: {
        innerWidth: 0,
        innerHeight: 0
      },
      versionLog: versionLog
    }
  },
  // ------------------------------------------------------------------------------
  // -------------------------------G-E-T-T-E-R-S----------------------------------
  // ------------------------------------------------------------------------------
  getters: {
    getAvatar(state) {
      let name = 'VaultSuit'
      const avatar = state.build.EquippedGear.body?.image
      const mutant = state.build.MysteryBoosts.BecomeAMutant?.tagged

      if (mutant && avatar) {
        if (avatar === 'VaultSuit') {
          name = 'Mutant'
        } else {
          const armors = [
            'CombatArmor',
            'NCRRangerArmor',
            'DesertCombatArmor',
            'CombatArmorMark2',
            'BrotherhoodArmor',
            'EnclaveArmor',
            'PowerArmor',
            'HardenedPowerArmor',
            'AdvancedPowerArmor',
            'AdvancedPowerArmorMark2'
          ]
          if (armors.includes(avatar)) {
            name = 'MutantHeavyArmor'
          } else {
            name = 'MutantLightArmor'
          }
        }
      } else if (avatar) {
        name = avatar
      }
      let img
      try {
        img = require(`@/assets/img/gear/avatars/${name}.gif`);
      } catch (e) {
        if (mutant) {
          img = require("@/assets/img/gear/avatars/Mutant.gif");
        } else {
          img = require("@/assets/img/gear/avatars/VaultSuit.gif");
        }
      }
      return {
        name,
        avatar: avatar || 'VaultSuit',
        image: img,
      };
    },
    getStepSkillChanges(state) {
      return Object.keys(state.build.Skills)
        .reduce((acc, skill) => {
          if (state.build.Skills[skill].stepSkillValue > 0) {
            acc[skill] = state.build.Skills[skill].stepSkillValue
          }
          return acc;
        }, {})
    },
    generateBuildString(state) {
      const special = [...Object.entries(state.build.SPECIAL)]
        .map((stat: any[]) => parseInt(stat[1].baseValue, 10).toString(36))
        .join('')
        .slice(0, -1)

      const skills = [...Object.entries(state.build.Skills)]
        .filter((skill: any[]) => skill[1].tagged)
        .map((skill: any[]) => dict.encode.Skills[skill[0]])
        .join('');

      const traits = [...Object.entries(state.build.Traits)]
        .filter((trait: any[]) => trait[1].tagged)
        .map((trait: any[]) => dict.encode.Traits[trait[0]])
        .join('');

      return `${special}${skills}${traits}`
    },
    generateDrugString(state) {
      const drugsList = [...dict.drugsList]
      const drugString = '1' + drugsList.map(drug => state.build.Drugs[drug].tagged ? '1' : '0').join('')

      return parseInt(drugString, 2).toString(36)
    },
    generateGearString(state) {
      const headString = dict.encode.HeadGear[state.build.EquippedGear.head?.image]
      const bodyString = dict.encode.BodyGear[state.build.EquippedGear.body?.image]

      return `${headString || 1}${bodyString || 1}`
    },
    generateLevelString(state, getters) {
      const commandString = logUtils.encodeCommandLog(state.build.info.log)
      const levelString = getters['generateGearString'] + getters['generateDrugString'] + '.' + commandString

      return levelString
    }
  },
  // ------------------------------------------------------------------------------
  // -----------------------------M-U-T-A-T-I-O-N-S--------------------------------
  // ------------------------------------------------------------------------------
  mutations: {
    // ---------------------------
    // ----------SYSTEM-----------
    // ---------------------------
    loadLibrary(state, library) {
      state.library = library
    },
    showDefaultCharStats(state) {
      state.ui.activeCharStatsView = 'charStats'
    },
    saveScreenData(state, data) {
      state.system.screen.innerWidth = data.innerWidth
      state.system.screen.innerHeight = data.innerHeight
      if (data.innerWidth < 380) {
        // console.log('data.innerWidth < 380', data.innerWidth)
      } else if (data.innerWidth < 450) {
        // console.log('data.innerWidth < 450', data.innerWidth)
      } else if (data.innerWidth < 600) {
        // console.log('data.innerWidth < 600', data.innerWidth)
      } else {
        // console.log('data.innerWidth >= 600', data.innerWidth)
      }
    },
    addToLog(state, data) {
      state.build.info.log.push(data)
    },
    processTempModifySkillCommands(state) {
      const skillValuesMap = new Map();

      state.build.info.tempModifySkillCommands
        .forEach((command) => {
          if (skillValuesMap.has(command.skill)) {
            const newValue = skillValuesMap.get(command.skill) + parseInt(command.modifier + command.multiplier)
            skillValuesMap.set(command.skill, newValue)
          } else {
            const value = parseInt(command.modifier + command.multiplier)
            skillValuesMap.set(command.skill, value)
          }
        });

      const valuesArray = [...skillValuesMap.entries()];
      valuesArray.forEach(skill => {
        const skillName = skill[0];
        const multiplierValue = skill[1];

        if (multiplierValue !== 0) {
          state.build.info.log.push({
            command: 'modifySkill',
            data: {
              skill: skillName,
              modifier: '+',
              multiplier: multiplierValue
            }
          })
        }
      })
      state.build.info.tempModifySkillCommands = [];
    },
    setLoading(state, loadingData) {
      state.system.loading = {
        ...state.system.loading,
        ...loadingData
      }
    },
    addToLoadingLog(state, data: string[]) {
      state.system.loading.log = [...state.system.loading.log, ...data]
    },
    resetLoadingData(state) {
      state.system.loading = {
        active: false,
        error: '',
        status: '',
        log: [],
        limit: -1
      }
    },
    useSessionStorage(state) {
      state.system.storage = sessionStorage
    },
    // ---------------------------
    // -----------GAME------------
    // ---------------------------
    resetBuild(state) {
      state.build = JSON.parse(JSON.stringify(newBuild))
    },
    loadBuild(state, build) {
      state.build = build
    },
    createChar(state) {
      const perkStep = state.build.Traits.Skilled.tagged ? 4 : 3
      state.build.info.phase = 'level'
      state.build.info.currentLevel = 1
      state.build.info.currentPerk.step = perkStep
      state.build.info.currentPerk.nextAtLevel = perkStep

      Object.keys(state.build.Skills).forEach(skill => {
        if (state.build.Skills[skill].tagged) {
          state.build.info.Skills.push(skill)
        }
      })
    },
    mutateSpecial(state, data) {
      const { statName, statValue, pool } = data
      state.build.SPECIAL[statName].baseValue = statValue
      state.build.SPECIAL.PL.baseValue = pool
    },
    selectSkill(state, skillName) {
      Object.keys(state.build.Skills).forEach(skill => {
        state.build.Skills[skill].selected = false
      })
      state.build.Skills[skillName].selected = true
    },
    modifySkill(state, data) {
      const { skill, modifier, stepSP, availableSP, change, spentSP } = data
      if (modifier === 'book') {
        state.build.Books[skill].gainedSkillValue = data.bookSkillValue
        state.build.Books[skill].read = data.booksRead
        state.build.Books[skill].available = false
      } else if (modifier === 'boost') {
        Object.keys(state.build.Boosts).forEach(boost => {
          state.build.Boosts[boost].available = false
          if (boost === skill) {
            state.build.Boosts[boost].tagged = true
            state.build.info.marshalBoost = true
          }
        })
      } else {
        state.build.Skills[skill].stepSkillValue += stepSP
        state.build.Skills[skill].change = change
        state.build.info.availableSkillPoints = availableSP
        if (modifier === '+') {
          state.build.Skills[skill].spentSP += spentSP
        } else if (modifier === '-') {
          state.build.Skills[skill].spentSP = spentSP
        }
      }
    },
    addStepSkillValues(state) {
      const changes = {}

      Object.keys(state.build.Skills)
        .forEach(skill => {
          if (state.build.Skills[skill].stepSkillValue > 0) {
            changes[skill] = state.build.Skills[skill].stepSkillValue
          }
          state.build.Skills[skill].gainedSkillValue += state.build.Skills[skill].stepSkillValue
          state.build.Skills[skill].stepSkillValue = 0
        })
    },
    handleLevel(state, data) {
      const { multiplier } = data
      state.build.info.currentLevel += multiplier
      state.build.info.availableSkillPoints += multiplier * methods.CharStats.SkillPointsPerLevel.updateStat(state.build)
    },
    handleAvailablePerk(state, data) {
      const { available, tagged, nextAtLevel } = data
      state.build.info.currentPerk.available = available
      state.build.info.currentPerk.tagged = tagged
      state.build.info.currentPerk.nextAtLevel += nextAtLevel
      if (available) {
        state.ui.showPerks = true
      }
    },
    tagPerk(state, data) {
      const { category, perk } = data
      state.build[category][perk].tagged = true
      state.build[category][perk].available = false
      state.build[category][perk].taggedAt = state.build.info.currentLevel
      if (category === 'Perks') {
        state.build.info.currentPerk.available = false
        state.build.info.currentPerk.tagged = true
        // state.ui.showPerks = false
      }
    },
    toggleDrug(state, data) {
      const { drug } = data
      state.build.Drugs[drug].tagged = !state.build.Drugs[drug].tagged
    },
    changeProgressBarStyle(state) {
      let allowMutation = true
      Object.keys(state.ui.progressBarStyle).forEach(setting => {
        if (state.ui.progressBarStyle[setting].active && allowMutation && setting !== 'activeStyle') {
          state.ui.progressBarStyle[setting].active = false
          const next = state.ui.progressBarStyle[setting].next
          state.ui.progressBarStyle[next].active = true
          state.ui.progressBarStyle.activeStyle = setting
          allowMutation = false
        }
      })
    },
    implantSPECIAL(state, data) {
      const { stat } = data
      state.build.SPECIAL[stat].implanted = true
    },
    implantCombat(state, data) {
      const { implant } = data
      state.build.CombatImplants[implant].tagged = true
    },
    takeProfession(state, data) {
      const { profession } = data
      state.build.Professions[profession].tagged = true
    },
    saveBuild(state, data) {
      const saveData = {
        ...data,
        build: { ...state.build },
        uiSettings: state.ui
      }
      // call LS function - saveBuildLS(saveData)
      LS.saveBuildLS(saveData, state.system.storage)
    },
    resetCurrentBuildLS(state) {
      LS.resetCurrentBuild(state.system.storage)
    },
    handleHitPoints(state, data) {
      const { multiplier } = data
      const diff = state.build.info.currentPerk.nextAtLevel - state.build.info.currentLevel
      if (multiplier === -1 && diff > 1) {
        state.build.CharStats.HitPoints.gainedValue += diff * (state.build.SPECIAL.EN.value / 2)
      } else if (multiplier >= 1) {
        state.build.CharStats.HitPoints.gainedValue += multiplier * (state.build.SPECIAL.EN.value / 2)
      }
    },
    updateBuildDetails(state, data) {
      const { field, value } = data
      state.build.info[field] = value
    },
    equipItem(state, data) {
      const { slot, key, item } = data
      if (item) {
        state.build.EquippedGear[slot] = item
      } else {
        state.build.EquippedGear[slot] = Inventory[slot][key]
      }
    },
    equipDefaultGear(state) {
      if (!state.build.EquippedGear.head && !state.build.EquippedGear.body) {
        state.build.EquippedGear.head = Inventory.head.NoHelmet
        state.build.EquippedGear.body = Inventory.body.VaultSuit
      }
    },
    setItemForCrafting(state, item) {
      state.craftedItem = item
    },
    updateInventory(state) {
      state.inventory = LS.getInventory()
    }
  },
  // ------------------------------------------------------------------------------
  // --------------------------------A-C-T-I-O-N-S---------------------------------
  // ------------------------------------------------------------------------------
  // 19613aa.8HDS.EM8
  actions: {
    // ---------------------------
    // ----------SYSTEM-----------
    // ---------------------------
    loadSavedData({ commit }) {
      const library = LS.getLibrary();
      commit('loadLibrary', library)
    },
    handleScreenData({ commit }, data) {
      commit('saveScreenData', data)
    },

    handleIncomingCommand({ dispatch }, { command, data }) {
      dispatch(command, data);
    },
    showDefaultCharStats({ commit }) {
      commit('showDefaultCharStats')
    },
    toggleLoadMenu({ state }) {
      state.ui.activeCharStatsView = 'loadMenu';
    },
    toggleSaveMenu({ state }) {
      state.ui.activeCharStatsView = 'saveMenu';
    },
    toggleInventoryMenu({ state }) {
      state.ui.activeCharStatsView = 'inventoryMenu';
    },
    toggleCraftMenu({ state }) {
      state.ui.activeCharStatsView = 'craftMenu';
    },
    loadSavedCharacter({ state }, char: { [key: string]: string }) {
      state.build.info.loadedSave = char;
    },
    loadFromRouteParams({ state, dispatch, commit }, params = {}) {
      // const start = window.performance.now()
      commit('setLoading', { active: true })

      const { b, l } = params
      if (b) {
        dispatch('loadByBuildString', b)
      }

      if (l && state.system.loading.active) {
        const { gear, drugs, commandLog } = logUtils.decodeLevelString(l)
        dispatch('createChar', b)
        const [helmetKey, armorKey] = gear

        dispatch('equipItem', { slot: 'head', key: helmetKey })
        dispatch('equipItem', { slot: 'body', key: armorKey })

        drugs.forEach(drugKey => {
          commit('toggleDrug', { drug: drugKey })
        })

        dispatch('loadByCommandLog', commandLog)
        dispatch('refreshStats')
      }
      // const end = window.performance.now()
      // console.log(`Build loaded in ${(end - start)} milliseconds`)
      commit('setLoading', { active: false })
    },
    loadToLevel({ state, dispatch, commit }, level: number) {
      // const start = window.performance.now()
      commit('setLoading', { active: true })

      const buildString = state.build.info.loadedSave.buildString;
      const levelString = state.build.info.loadedSave.levelString;
      dispatch('loadByBuildString', buildString);

      if (level !== 0) {
        dispatch('createChar', buildString)
        if (level > 1) {
          commit('setLoading', { limit: level })
          const { gear, drugs, commandLog } = logUtils.decodeLevelString(levelString)
          const [helmetKey, armorKey] = gear


          dispatch('equipItem', { slot: 'head', key: helmetKey })
          dispatch('equipItem', { slot: 'body', key: armorKey })

          drugs.forEach(drugKey => {
            commit('toggleDrug', { drug: drugKey })
          })

          dispatch('loadByCommandLog', commandLog)
          dispatch('refreshStats')
        }
      }
      dispatch('showDefaultCharStats')
      commit('setLoading', { active: false })

      // const end = window.performance.now()
      // console.log(`Build loaded in ${(end - start)} milliseconds`)
    },
    resetLoadingData({ commit }) {
      commit('resetLoadingData')
    },

    generateBuildString({ state, getters }) {
      state.build.info.buildString = getters['generateBuildString'];
    },
    loadByBuildString({ state, commit, dispatch }, data: string) {

      const { SPECIAL, Skills, Traits } = logUtils.decodeBuildString(data);

      if (SPECIAL[0] === 'error') {
        commit('setLoading', { status: 'error', active: false, error: 'Unable to decode SPECIAL!' })
        commit('addToLoadingLog', [
          {
            text: 'Critical error! Unable to decode SPECIAL!',
            type: 'error'
          }
        ])
      } else if (Skills[0] === 'error') {
        commit('setLoading', { status: 'error', active: false, error: 'Unable to decode Skills!' })
        commit('addToLoadingLog', [
          {
            text: 'Critical error! Unable to decode Skills!',
            type: 'error'
          }
        ])
      } else if (Traits[0] === 'error') {
        commit('setLoading', { status: 'error', active: false, error: 'Unable to decode Traits!' })
        commit('addToLoadingLog', [
          {
            text: 'Critical error! Unable to decode Traits!',
            type: 'error'
          }
        ])
      } else {
        commit('resetBuild');
        commit('useSessionStorage')

        Skills.forEach(skill => dispatch('tagSkill', skill))
        Traits.forEach(trait => dispatch('tagTrait', trait))

        // TODO - improve the reliability of SPECIAL iteration
        Object
          .keys(state.build.SPECIAL)
          .forEach((stat: string, index: number) => {
            if (stat !== 'PL') {
              state.build.SPECIAL[stat].baseValue = +SPECIAL[index];
            } else {
              state.build.SPECIAL.PL.baseValue = 0;
            }
          })



        commit('setLoading', { status: 'success' })
        commit('addToLoadingLog', [
          {
            text: 'SPECIAL loaded successfully!',
            type: 'success'
          },
          {
            text: 'Skills loaded successfully!',
            type: 'success'
          },
          {
            text: 'Traits loaded successfully!',
            type: 'success'
          },
        ])

        dispatch('refreshStats')
      }

    },
    loadByCommandLog({ state, commit, dispatch }, commandLog: any[] = []) {
      let error = ''
      for (let i = 0; i < commandLog.length; i++) {
        if (state.system.loading.limit !== -1 && state.system.loading.limit <= state.build.info.currentLevel) {
          dispatch('resetLoadingData')
          break
        }
        const { command, data } = commandLog[i];
        if (!command || command === 'error' || command === '') {
          error = 'Unable to decode command at index ' + i
          break;
        } else if (command === 'skip') {
          continue;
        } else {
          dispatch(command, data)
          // check for commands after the level limit
          if (state.system.loading.limit !== -1) {
            if (command === 'levelUp' && state.system.loading.limit <= state.build.info.currentLevel && i < commandLog.length - 1) {
              const remainingCommands = commandLog.length - (i + 1)
              const commandsArray = []
              for (let j = 0; j < remainingCommands; j++) {
                const followingCommand = commandLog[i + j + 1];
                if (followingCommand.command === 'levelUp') {
                  break
                } else {
                  commandsArray.push(followingCommand)
                }
              }

              if (commandsArray.length) {
                commandsArray.forEach(cmd => dispatch(cmd.command, cmd.data))
              }
            }
          }
        }
      }
      dispatch('refreshStats')
      if (error.length) {
        commit('addToLoadingLog', [
          {
            text: 'An error occurred while executing leveling commands! Character is partially loaded!',
            type: 'warning'
          },
        ])
        commit('setLoading', { status: 'warning', error })
      } else {
        commit('setLoading', { status: 'success' })
        commit('addToLoadingLog', [
          {
            text: 'Character was loaded successfully!',
            type: 'success'
          },
        ])
      }
    },
    saveCharacter({ state, getters, dispatch }, inputData) {
      if (state.build.info.buildString.length === 0) {
        dispatch('generateBuildString')
      }

      // add any current (temp) skill modifications to log
      dispatch('addStepSkillValues')

      const character = {
        buildString: state.build.info.buildString,
        levelString: getters['generateLevelString'],
        level: state.build.info.currentLevel,
        HP: state.build.CharStats.HitPoints.value,
        avatar: getters['getAvatar'].name,
        ...inputData
      }
      LS.saveInLibrary(character)
      dispatch('loadSavedData')
      dispatch('showDefaultCharStats')
    },
    deleteCharacter({ dispatch }, charName: string) {
      LS.deleteInLibrary(charName)
      dispatch('loadSavedData')
    },
    // ---------------------------
    // -----------GAME------------
    // ---------------------------
    resetBuild({ commit, dispatch }) {
      commit('resetBuild')
      commit('resetCurrentBuildLS')
      dispatch('equipDefaultGear')
      dispatch('refreshStats')
    },
    loadBuild({ commit, dispatch }, build) {
      commit('loadBuild', build)
      dispatch('refreshStats')
    },
    createChar({ state, commit, dispatch }) {
      dispatch('generateBuildString')
      commit('addToLog', { command: 'createChar', data: state.build.info.buildString })
      // save build in LS/currentBuild.static
      commit('saveBuild', { type: 'static', slot: 0 })
      // create and move char into phase 'level'
      commit('createChar')
      // save build in LS/currentBuild.static
      commit('saveBuild', { type: 'static', slot: 1 })
      // save build in LS/currentBuild.dynamic
      commit('saveBuild', { type: 'dynamic', slot: 0 })
      // refresh stats
      dispatch('refreshStats')

      if (state.system.loading.active) {
        commit('addToLoadingLog', [
          {
            text: 'Character created successfully!',
            type: 'success'
          }
        ])
      }
    },
    levelUp({ state, commit, dispatch }, data) {
      // handle stepSkillValues
      dispatch('addStepSkillValues')

      const lastCommand = state.build.info.log[state.build.info.log.length - 1];

      if (lastCommand && lastCommand.command === 'levelUp' && lastCommand.data.multiplier !== -1) {
        lastCommand.data.multiplier += data.multiplier;
      } else {
        commit('addToLog', { command: 'levelUp', data });
      }
      // handle Hit-Points
      if (state.build.info.currentLevel < 24) {
        commit('handleHitPoints', data)
      }
      // handle Level & Skill Points
      dispatch('handleLevel', data)
      // handle available Perk
      dispatch('handleAvailablePerk')
      // save build in LS/currentBuild.dynamic
      commit('saveBuild', { type: 'dynamic', slot: 0 })
      // save level 24 build in LS/currentBuild.static
      if (state.build.info.currentLevel === 24) {
        commit('saveBuild', { type: 'static', slot: 2 })
      } else if (state.build.info.currentLevel > 24) {
        commit('saveBuild', { type: 'static', slot: 3 })
      }
      // refresh stats
      dispatch('refreshStats')
    },
    refreshStats({ state }) {
      // const start = window.performance.now()
      Object.keys(state.build).forEach(category => {
        if (['SPECIAL', 'CharStats', 'Skills'].includes(category)) {
          Object.keys(state.build[category]).forEach(stat => {
            state.build[category][stat].value = methods[category][stat].updateStat(state.build)
            if (category === 'SPECIAL' && stat !== 'PL') {
              state.build.SPECIAL[stat].onDrugs = methods.SPECIAL[stat].onDrugs(state.build)
            }
          })
        }
        if (['Perks', 'SupportPerks', 'Boosts', 'CombatImplants', 'Professions', 'MysteryBoosts'].includes(category)) {
          Object.keys(state.build[category]).forEach(stat => {
            state.build[category][stat].available = methods[category][stat]?.updateStat(state.build)
          })
        }
        if (category === 'AdvancedDR') {
          Object.keys(state.build.AdvancedDR).forEach(bodySlot => {
            Object.keys(state.build.AdvancedDR[bodySlot]).forEach(stat => {
              state.build.AdvancedDR[bodySlot][stat] = methods.AdvancedDR[bodySlot][stat](state.build)
            })
          })
        }
      })
      LS.saveLastBuild(state.build, state.system.storage)
      // const end = window.performance.now()
      // console.log(`Stats refreshed in ${(end - start)} milliseconds`)
    },
    updateInfoPanel({ state }, { category, stat }) {
      if (!state.ui.lockInfoPanel) {
        if (stat) {
          state.infoPanel = { ...infoPanelData[category][stat] }
        } else {
          state.infoPanel = { ...infoPanelData.Default.InfoPanel }
        }
      }
    },
    modifySpecial({ state, commit, dispatch }, { statName, modifier }) {
      let statValue = state.build.SPECIAL[statName].baseValue;
      let pool = state.build.SPECIAL.PL.baseValue;

      if (modifier === '+') {
        if (pool <= 0) {
          return;
        }
        if (statName === 'IN' && statValue === 9 && state.build.Traits.Bonehead.tagged) {
          return;
        }
        if (statValue < 10) {
          statValue += 1;
          pool -= 1;
        }
      } else if (modifier === '-') {
        if (statValue <= 1) {
          return;
        }
        if (statName === 'ST' && statValue === 5 && state.build.Traits.Bruiser.tagged) {
          return;
        }
        statValue -= 1;
        pool += 1;
      }
      const mutationData = {
        statName,
        statValue,
        pool
      }
      commit('mutateSpecial', mutationData)
      dispatch("refreshStats")
    },
    tagSkill({ state, dispatch }, skill) {
      if (skill === 'Scavenging') {
        return
      }
      const tagged = state.build.Skills[skill].tagged
      const taggedSkills = state.build.info.taggedSkills
      if (tagged) {
        state.build.Skills[skill].tagged = false
        state.build.info.taggedSkills -= 1
      } else if (!tagged && taggedSkills < 3) {
        state.build.Skills[skill].tagged = true
        state.build.info.taggedSkills += 1
      } else {
        return
      }
      dispatch('refreshStats')
    },
    tagTrait({ state, dispatch }, trait) {
      const tagged = state.build.Traits[trait].tagged
      const taggedTraits = state.build.info.taggedTraits
      const updateTraitsArray = (trait: string, add = true) => {
        if (add) {
          state.build.info.Traits.push(state.build.Traits[trait].name)
        } else {
          const traitName = state.build.Traits[trait].name
          state.build.info.Traits = state.build.info.Traits.filter(trait => trait !== traitName)
        }
      }

      if (trait === 'Bruiser') {
        const ST = state.build.SPECIAL.ST.baseValue
        if (tagged) {
          if (ST < 5) {
            return
          } else {
            state.build.Traits[trait].tagged = false
            state.build.info.taggedTraits -= 1
            state.build.SPECIAL.ST.baseValue -= 4
            updateTraitsArray(trait, false)
          }
        } else if (taggedTraits < 2) {
          state.build.Traits[trait].tagged = true
          state.build.info.taggedTraits += 1
          updateTraitsArray(trait)
          if (ST <= 6) {
            state.build.SPECIAL.ST.baseValue += 4
          } else {
            state.build.SPECIAL.ST.baseValue += 10 - ST
            state.build.SPECIAL.PL.baseValue += 4 - (10 - ST)
          }
        }
      } else if (trait === 'Bonehead') {
        const IN = state.build.SPECIAL.IN.baseValue
        if (tagged) {
          state.build.Traits[trait].tagged = false
          state.build.info.taggedTraits -= 1
          state.build.SPECIAL.IN.baseValue += 1
          updateTraitsArray(trait, false)
        } else if (taggedTraits < 2 && IN > 1) {
          state.build.Traits[trait].tagged = true
          state.build.info.taggedTraits += 1
          state.build.SPECIAL.IN.baseValue -= 1
          updateTraitsArray(trait)
        }
      } else {
        if (tagged) {
          state.build.Traits[trait].tagged = false
          state.build.info.taggedTraits -= 1
          updateTraitsArray(trait, false)
        } else if (taggedTraits < 2) {
          state.build.Traits[trait].tagged = true
          state.build.info.taggedTraits += 1
          updateTraitsArray(trait)
        }
      }
      dispatch('refreshStats')
    },
    tagPerk({ state, dispatch, commit }, data) {

      const perk = state.build[data.category][data.perk]
      if (data.category === 'Perks') {
        const allowed = state.build.info.currentPerk
        if (perk.available && !perk.tagged && allowed.available && !allowed.tagged) {
          dispatch('addStepSkillValues')
          commit('tagPerk', data)
          commit('addToLog', { command: 'tagPerk', data })
          dispatch('refreshStats')
        }
      } else if (perk.available && !perk.tagged && data.category === 'SupportPerks') {
        dispatch('addStepSkillValues')
        commit('tagPerk', data)
        commit('addToLog', { command: 'tagPerk', data })
        dispatch('refreshStats')
      } else if ((perk.available || state.system.loading.active) && !perk.tagged && data.category === 'MysteryBoosts') {
        dispatch('addStepSkillValues')
        commit('tagPerk', data)
        commit('addToLog', { command: 'tagPerk', data })
        dispatch('refreshStats')
      }

    },
    selectSkill({ commit }, skill) {
      if (skill !== 'Scavenging') {
        commit('selectSkill', skill)
      }
    },
    modifySkill({ state, dispatch, commit }, data) {
      const { skill, modifier, multiplier } = data
      const tagged = state.build.Skills[skill].tagged
      const maxSkillValue = state.build.Skills[skill].maxValue
      let spentSkillPoints = state.build.Skills[skill].spentSP
      let tempSkillValue = state.build.Skills[skill].value
      let unspentSP = state.build.info.availableSkillPoints
      let stepSkillValue = state.build.Skills[skill].stepSkillValue
      let change = state.build.Skills[skill].change
      const mutationData = {
        skill,
        modifier,
        stepSP: 0,
        availableSP: 0,
        change: 0,
        spentSP: '',
        bookSkillValue: 0,
        booksRead: 0
      }
      let usefulIterations = 0;
      let updateData = false;
      const valueIncrement = tagged ? 2 : 1;
      for (let i = 0; i < multiplier; i++) {
        const skillCost = getThresholdData(tempSkillValue)
        if (modifier === '+') {
          let stepSP = 0
          if (unspentSP >= skillCost.cost && tempSkillValue < maxSkillValue) {
            tempSkillValue += valueIncrement;
            stepSP += valueIncrement;
            unspentSP -= skillCost.cost;

            mutationData.spentSP += `${skillCost.cost}`;
            mutationData.stepSP += stepSP
            mutationData.availableSP = unspentSP
            if (tempSkillValue > maxSkillValue) {
              mutationData.change = tempSkillValue - maxSkillValue;
            }
            updateData = true;
            usefulIterations++;
          } else {
            break;
          }
        } else if (modifier === '-') {
          let stepSP = 0
          if (stepSkillValue > 0) {
            const lastCost = Number(spentSkillPoints.slice(-1))
            if (change) {
              tempSkillValue -= 1
              change = 0
              mutationData.change = 0
            } else {
              tempSkillValue -= valueIncrement
            }
            stepSP -= valueIncrement;
            stepSkillValue -= valueIncrement;
            unspentSP += lastCost;
            spentSkillPoints = spentSkillPoints.slice(0, -1)
            mutationData.spentSP = spentSkillPoints
            mutationData.stepSP += stepSP
            mutationData.availableSP = unspentSP

            updateData = true;
            usefulIterations++;
          } else {
            break;
          }
        } else if (modifier === 'book') {
          if (tempSkillValue < maxSkillValue) {
            if (i === 0) {
              commit('addToLog', { command: 'modifySkill', data })
            }

            const bookValue = Math.floor(6 / skillCost.cost)
            if (bookValue === 1) {
              tempSkillValue += tagged ? bookValue * 2 : bookValue
              mutationData.bookSkillValue += tagged ? bookValue * 2 : bookValue
              mutationData.booksRead += 1
              updateData = true
            } else {
              let bookPoints = 6
              for (let i = 0; i < 6; i++) {
                const { cost } = getThresholdData(tempSkillValue)
                const increment = tagged ? 2 : 1
                if (bookPoints < cost || tempSkillValue >= maxSkillValue) {
                  break;
                } else {
                  bookPoints -= cost
                  tempSkillValue += increment
                  mutationData.bookSkillValue += increment
                  updateData = true
                }
              }
              if (updateData) {
                mutationData.booksRead += 1
              }
            }
          }
        } else if (modifier === 'boost' && !state.build.info.marshalBoost) {
          updateData = true
          commit('addToLog', { command: 'modifySkill', data })
          dispatch('addStepSkillValues')
        }
      }


      if (updateData) {
        if (['+', '-'].includes(modifier) && usefulIterations > 0) {
          const modifiedCommandData = {
            ...data,
            multiplier: usefulIterations
          }
          state.build.info.tempModifySkillCommands.push(modifiedCommandData)
        }
        updateData = false;
        commit('modifySkill', mutationData)
        dispatch('refreshStats')
      }
    },
    addStepSkillValues({ commit }) {
      commit('processTempModifySkillCommands')
      commit('addStepSkillValues')
    },
    handleLevel({ state, commit }, data) {
      const { multiplier } = data
      if (multiplier === -1) {
        const perkStep = state.build.info.currentPerk.step
        const diff = state.build.info.currentPerk.nextAtLevel - state.build.info.currentLevel
        let newMultiplier = 0
        if (diff) {
          newMultiplier = diff
        } else {
          newMultiplier = perkStep
        }
        commit('handleLevel', { multiplier: newMultiplier })
      } else {
        commit('handleLevel', data)
      }
    },
    handleAvailablePerk({ state, commit }) {
      const currentLevel = state.build.info.currentLevel
      const step = state.build.info.currentPerk.step
      const mutationData = {
        available: false,
        tagged: false,
        nextAtLevel: 0
      }
      let updateData = false
      if (currentLevel < step) {
        return
      } else if (currentLevel === step) {
        // enable first perk
        mutationData.available = true
        mutationData.tagged = false
        mutationData.nextAtLevel = step
        updateData = true
      } else if (currentLevel % step === 0 && currentLevel < 25) {
        // enable next perk
        mutationData.available = true
        mutationData.tagged = false
        mutationData.nextAtLevel = step
        updateData = true
      } else if (currentLevel >= 30) {
        mutationData.available = false
        updateData = true
      }
      if (updateData) {
        // send mutation data
        updateData = false
        commit('handleAvailablePerk', mutationData)
      }
    },
    toggleDrug({ dispatch, commit }, data) {
      commit('toggleDrug', data)
      dispatch('refreshStats')
    },
    changeProgressBarStyle({ commit }) {
      commit('changeProgressBarStyle')
    },
    implantSPECIAL({ dispatch, commit }, data) {

      commit('implantSPECIAL', data)
      commit('addToLog', { command: 'implantSPECIAL', data })
      dispatch('refreshStats')
    },
    implantCombat({ dispatch, commit }, data) {

      commit('implantCombat', data)
      commit('addToLog', { command: 'implantCombat', data })
      dispatch('refreshStats')
    },
    takeProfession({ dispatch, commit }, data) {
      commit('takeProfession', data)
      commit('addToLog', { command: 'takeProfession', data })
      dispatch('refreshStats')
    },
    updateBuildDetails({ commit }, data) {
      commit('updateBuildDetails', data)
    },
    equipItem({ dispatch, commit }, data) {

      commit('equipItem', data)
      dispatch('refreshStats')
    },
    equipDefaultGear({ state, dispatch }) {
      if (!state.build.EquippedGear.head) {
        dispatch('equipItem', { slot: 'head', key: 'NoHelmet' })
      }
      if (!state.build.EquippedGear.body) {
        dispatch('equipItem', { slot: 'body', key: 'VaultSuit' })
      }
      // commit('equipDefaultGear')
    },
    setItemForCrafting({ commit }, item) {
      commit('setItemForCrafting', item)
    },
    saveCraftedItem({ state, dispatch, commit }, item) {
      LS.saveInInventory(item)
      state.craftedItem = {}
      commit('updateInventory')
      dispatch('showDefaultCharStats')
    },
    deleteItemFromInventory({ commit }, data) {
      const { item, index } = data
      LS.deleteInInventory(item.slot, index)
      commit('updateInventory')
    }
  },
  modules: {
  }
})