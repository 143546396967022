const defaultBuild = {
  static: [],
  dynamic: [],
  last: {}
}

const defaultLibrary = {};
const prefix = 'geck_';

const initLocalStorage = () => {
  const savedBuild = localStorage.getItem(prefix + 'currentBuild')
  const savedLibrary = localStorage.getItem(prefix + 'library')
  const sessionBuild = sessionStorage.getItem(prefix + 'currentBuild')

  if (!savedBuild) {
    localStorage.setItem(prefix + 'currentBuild', JSON.stringify(defaultBuild))
  }

  if (!savedLibrary) {
    localStorage.setItem(prefix + 'library', JSON.stringify(defaultLibrary))
  }

  if (!sessionBuild) {
    sessionStorage.setItem(prefix + 'currentBuild', JSON.stringify(defaultBuild))
  }


  console.log('localStorage initialized!')
}
const saveLastBuild = (build, storage = localStorage) => {
  const currentBuild = JSON.parse(storage.getItem(prefix + 'currentBuild'))
  if (currentBuild) {
    currentBuild.last = build
    storage.setItem(prefix + 'currentBuild', JSON.stringify(currentBuild))
  }
}
const saveBuildLS = (data, storage = localStorage) => {
  const { type, slot, build } = data
  saveLastBuild(build, storage)
  const currentBuild = JSON.parse(storage.getItem(prefix + 'currentBuild'))
  if (type === 'static') {
    currentBuild.static[slot] = build
  } else if (type === 'dynamic') {
    currentBuild.dynamic.push(build)
    if (currentBuild.dynamic.length > 3) {
      currentBuild.dynamic.shift()
    }
  } else {
    return
  }
  storage.setItem(prefix + 'currentBuild', JSON.stringify(currentBuild))
}

const getLastSessionBuild = () => {
  const currentBuild = JSON.parse(sessionStorage.getItem(prefix + 'currentBuild'))
  return currentBuild && currentBuild.last && currentBuild.last.info ? currentBuild.last : false
}
const getCurrentBuild = (storage = localStorage) => {
  return JSON.parse(storage.getItem(prefix + 'currentBuild'))
}
const getLastBuild = (storage = localStorage) => {
  const currentBuild = JSON.parse(storage.getItem(prefix + 'currentBuild'))
  return currentBuild && currentBuild.last && currentBuild.last.info ? currentBuild.last : false
}

const resetCurrentBuild = (storage = localStorage) => {
  storage.setItem(prefix + 'currentBuild', JSON.stringify(defaultBuild))
}

// * NEW
const getLibrary = () => {
  const library = JSON.parse(localStorage.getItem(prefix + 'library'))

  if (library === null) {
    localStorage.setItem(prefix + 'library', JSON.stringify(defaultLibrary))
    return {}
  }
  return JSON.parse(localStorage.getItem(prefix + 'library'))
}

const saveInLibrary = (save: any) => {
  const library = JSON.parse(localStorage.getItem(prefix + 'library')) || {};

  library[save.name] = save;
  localStorage.setItem(prefix + 'library', JSON.stringify(library))
}
const deleteInLibrary = (key: string) => {
  const library = JSON.parse(localStorage.getItem(prefix + 'library')) || {};

  delete library[key];
  localStorage.setItem(prefix + 'library', JSON.stringify(library))
}

const getInventory = () => {
  return JSON.parse(localStorage.getItem(prefix + 'inventory')) || {
    head: [],
    body: [],
    hand: []
  };
}
const saveInInventory = (item: any) => {
  const inventory = JSON.parse(localStorage.getItem(prefix + 'inventory')) || {
    head: [],
    body: [],
    hand: []
  };

  inventory[item.slot].push(item)
  localStorage.setItem(prefix + 'inventory', JSON.stringify(inventory))
}
const deleteInInventory = (slot, index: number) => {
  const inventory = JSON.parse(localStorage.getItem(prefix + 'inventory')) || {
    head: [],
    body: [],
    hand: []
  };

  if (index < inventory[slot].length) {
    inventory[slot].splice(index, 1)

    localStorage.setItem(prefix + 'inventory', JSON.stringify(inventory))
  }
}

export default {
  initLocalStorage,
  saveLastBuild,
  saveBuildLS,
  getLastSessionBuild,
  getCurrentBuild,
  getLastBuild,
  resetCurrentBuild,
  getLibrary,
  saveInLibrary,
  deleteInLibrary,
  getInventory,
  saveInInventory,
  deleteInInventory
}