<template>
  <div
    :class="checkSelected() ? 'checkbox checked' : 'checkbox'"
    @click="select()"
  ></div>
</template>
<script>
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Selectbox",
  props: ["attribute"],
  setup(props) {
    const store = useStore();
    const select = () => {
      store.dispatch('handleIncomingCommand', {
        command: 'selectSkill',
        data: props.attribute
      });
    };
    const checkSelected = () => {
      return store.state.build.Skills[props.attribute].selected;
    };
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      build: store.state.build,
      select,
      checkSelected,
    };
  },
});
</script>
<style scoped>
.checkbox {
  cursor: pointer;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  width: 9px;
  height: 9px;
  border: 1px solid white;
  border-radius: 50%;
}
.checked {
  background-color: rgb(199, 198, 198);
}
</style>