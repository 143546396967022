<template>
  <div class="books-wrapper">
    <button
      class="image-btn"
      :title="state.build.Books.SmallGuns.read ? `${state.build.Books.SmallGuns.read} Small Guns books read (Small Guns skill +${state.build.Books.SmallGuns.gainedSkillValue}%)` : 'Read 10 Small Guns books'"
      :disabled="!state.build.Books.SmallGuns.available"
      @click="readBook('SmallGuns')"
    >
      <img
        :class="
          state.build.Books.SmallGuns.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/SmallGunsBook.png"
        alt=""
      />
    </button>
    <button
      class="image-btn"
      :title="state.build.Books.FirstAid.read ? `${state.build.Books.FirstAid.read} First Aid books read (First Aid skill +${state.build.Books.FirstAid.gainedSkillValue}%)` : 'Read 10 First Aid books'"
      :disabled="!state.build.Books.FirstAid.available"
      @click="readBook('FirstAid')"
    >
      <img
        :class="
          state.build.Books.FirstAid.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/FirstAidBook.png"
        alt=""
      />
    </button>
    <button
      class="image-btn"
      :title="state.build.Books.Outdoorsman.read ? `${state.build.Books.Outdoorsman.read} Outdoorsman books read (Outdoorsman skill +${state.build.Books.Outdoorsman.gainedSkillValue}%)` : 'Read 10 Outdoorsman books'"
      :disabled="!state.build.Books.Outdoorsman.available"
      @click="readBook('Outdoorsman')"
    >
      <img
        :class="
          state.build.Books.Outdoorsman.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/OutdoorsmanBook.png"
        alt=""
      />
    </button>
    <button
      class="image-btn"
      :title="state.build.Books.Science.read ? `${state.build.Books.Science.read} Science books read (Science skill +${state.build.Books.Science.gainedSkillValue}%)` : 'Read 10 Science books'"
      :disabled="!state.build.Books.Science.available"
      @click="readBook('Science')"
    >
      <img
        :class="
          state.build.Books.Science.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/ScienceBook.png"
        alt=""
      />
    </button>
    <button
      class="image-btn"
      :title="state.build.Books.Repair.read ? `${state.build.Books.Repair.read} Repair books read (Repair skill +${state.build.Books.Repair.gainedSkillValue}%)` : 'Read 10 Repair books'"
      :disabled="!state.build.Books.Repair.available"
      @click="readBook('Repair')"
    >
      <img
        :class="
          state.build.Books.Repair.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/RepairBook.png"
        alt=""
      />
    </button>
    <button
      class="image-btn"
      :title="state.build.Books.Barter.read ? `${state.build.Books.Barter.read} Barter books read (Barter skill +${state.build.Books.Barter.gainedSkillValue}%)` : 'Read 10 Barter books'"
      :disabled="!state.build.Books.Barter.available"
      @click="readBook('Barter')"
    >
      <img
        :class="
          state.build.Books.Barter.available
            ? 'image-book'
            : 'disabled-image-book'
        "
        src="@/assets/img/books/BarterBook.png"
        alt=""
      />
    </button>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "Books",
  setup() {
    const store = useStore();
    const readBook = (book) => {
      const data = {
        skill: book,
        modifier: "book",
        multiplier: 10,
      };
      store.dispatch('handleIncomingCommand', {
        command: 'selectSkill',
        data: book
      });
      store.dispatch('handleIncomingCommand', {
        command: 'modifySkill',
        data: data
      });
    };
    return {
      state: store.state,
      readBook,
    };
  },
};
</script>
<style scoped>
.books-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.image-btn {
  display: flex;
  justify-content: space-between;
  width: 42px;
  max-width: 45px;
  max-height: 27px;
  padding: 0px;
  margin: 0;
  background-color: transparent;
  border: none;
}
.image-book {
  height: 100%;
  width: 100%;
  margin: auto;
  -webkit-filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(30%) drop-shadow(2px 2px 2px #222);
  border: 1px transparent;
}
.disabled-image-book {
  height: 70%;
  width: 70%;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 5px;
  margin: auto;
  -webkit-filter: contrast(120%) brightness(1.3) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.3) drop-shadow(1px 1px 1px #222);
  border: 1px solid rgba(255, 255, 255, 0.245);
  background-color: rgba(94, 121, 87, 0.596);
  padding: 3px;
}
.disabled-image-book:hover {
  -webkit-filter: contrast(120%) brightness(2) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(2) drop-shadow(1px 1px 1px #222);
  cursor: default;
}
.image-btn:focus {
  outline: none;
  box-shadow: none;
}
.image-book:hover {
  outline: none;
  box-shadow: none;
  height: 115%;
  width: 115%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
</style>