
import { useStore } from "vuex";
import { defineComponent } from "vue";
import CraftPanel from "../level/charStats/CraftPanel.vue";

export default defineComponent({
  components: {
    CraftPanel
  },
  name: "CraftMenu",
  setup() {
    const store = useStore();

    const closePanel = () => {
      store.dispatch('showDefaultCharStats')
    }
    return {
      state: store.state,
      closePanel
    };
  },
});

