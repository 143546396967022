
import { defineComponent } from "vue";
import { useStore } from "vuex";
import Checkbox from "@/components/desktop/subcomponents/Checkbox.vue";
import "./build.css";

export default defineComponent({
  name: "Traits",
  components: {
    Checkbox,
  },
  setup() {
    const store = useStore();
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const tagTrait = (trait: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'tagTrait',
        data: trait
      });
    }
    const getTraitData = (trait: string) => {
      return store.state.build.Traits[trait].tagged
    };
    const traitsLeft = [
      "FastMetabolism",
      "Bruiser",
      "OneHander",
      "Finesse",
      "Kamikaze",
      "HeavyHanded",
      "FastShot",
    ];
    const traitsRight = [
      "BloodyMess",
      "Jinxed",
      "GoodNatured",
      "ChemReliant",
      "Bonehead",
      "Skilled",
      "Loner",
    ];
    return {
      state: store.state,
      Traits: store.state.build.Traits,
      tagTrait,
      traitsLeft,
      traitsRight,
      showInfo,
      getTraitData
    };
  },
});
