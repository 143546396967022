
import { defineComponent } from "vue";
import { useStore } from "vuex";
import "../level/level-phase-containers.css";

export default defineComponent({
  name: "NavMenu Left",
  setup() {
    const store = useStore()
    const latestUpdate = store.state.system.versionLog[store.state.system.versionLog.length - 1]

    return {
      state: store.state,
      latestUpdate
    }
  }
});
