
import { useStore } from "vuex"
import { computed, defineComponent } from "vue"
import { getItemPrefixData } from "@/data/gear-utils"
export default defineComponent({
 name: "CharacterTable",
 emits: ["equip"],
 components: {},
 setup() {
   const store = useStore()
    const getStatData = (stat: string) => {
      return {
        name: store.state.build.CharStats[stat].name,
        value: store.state.build.CharStats[stat].value,
      };
    };
    const getEquippedGearData = computed(() => {
     return {
       w1: null,
       w2: null,
       head: store.state.build.EquippedGear.head ? store.state.build.EquippedGear.head : null,
       body: store.state.build.EquippedGear.body ? store.state.build.EquippedGear.body : null
     }
   })
   const getGearImage = (slot: string, gear: string) => {
      let img;
      try {
        img = require(`@/assets/img/gear/${slot}/${gear}.gif`);
      } catch (e) {
        img = require("@/assets/img/gear/body/VaultSuit.gif");
      }
      return img;
    };
    return {
      state: store.state,
      getStatData,
      getEquippedGearData,
      getGearImage,
      getItemPrefixData
   }
 },
})
