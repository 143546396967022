
import { useStore } from "vuex"
import { defineComponent, watch, ref } from "vue"
export default defineComponent({
  name: "DefaultContent",
  components: {},
  setup() {
    const store = useStore()
    const infoPanelData = ref({ ...store.state.infoPanel });
    const getImage = (type: string, image: string) => {
      let img;
      try {
        img = require(`@/assets/img/info-panel/${type}/${image}.png`);
      } catch (e) {
        img = require("@/assets/img/info-panel/Default/Info Panel.png");
      }
      return img;
    };
    watch(() => store.state.infoPanel, (to) => {
        infoPanelData.value = { ...to };
      }
    );
    return {
      state: store.state,
      infoPanelData,
      getImage
    }
  },
})
