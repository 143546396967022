export const defaultStatNamesMap = {
  ST: 'Strength',
  PE: 'Perception',
  EN: 'Endurance',
  CH: 'Charisma',
  IN: 'Intelligence',
  AG: 'Agility',
  LK: 'Luck',
  ActionPoints: 'Action Points',
  HealingRate: 'Healing Rate',
  CritChance: 'Critical Chance',
  CritPower: 'Critical Power',
  NoDR: 'Normal DR',
  NoDT: 'Normal DT',
  LaDR: 'Laser DR',
  LaDT: 'Laser DT',
  FiDR: 'Fire DR',
  FiDT: 'Fire DT',
  PlDR: 'Plasma DR',
  PlDT: 'Plasma DT',
  ExDR: 'Explode DR',
  ExDT: 'Explode DT',
  ElDR: 'Electrical DR',
  ElDT: 'Electrical DT',
  RadResistance: 'Rad Resistance',
  PoisonResistance: 'Poison Resistance',
  CarryWeight: 'Carry Weight',
}
export const defaultBonusStats = {
  ST: '0',
  PE: '0',
  EN: '0',
  CH: '0',
  IN: '0',
  AG: '0',
  LK: '0',
  ActionPoints: '0',
  HealingRate: '0',
  CritChance: '0',
  CritPower: '0',
  NoDR: '0',
  NoDT: '0',
  LaDR: '0',
  LaDT: '0',
  FiDR: '0',
  FiDT: '0',
  PlDR: '0',
  PlDT: '0',
  ExDR: '0',
  ExDT: '0',
  ElDR: '0',
  ElDT: '0',
  RadResistance: '0',
  PoisonResistance: '0',
  CarryWeight: '0',
}

export const getItemPrefixData = (usedStatsCount: number) => {
  const data = {
    prefix: '',
    color: ''
  }
  switch (usedStatsCount) {
    case 0:
      data.prefix = 'Basic'
      data.color = 'gray'
      break
    case 1:
      data.prefix = 'Quality'
      data.color = 'darkgray'
      break
    case 2:
      data.prefix = 'Improved'
      data.color = '#d4d4d4'
      break
    case 3:
      data.prefix = 'Superior'
      data.color = 'white'
      break
    case 4:
      data.prefix = 'Advanced'
      data.color = '#00f3fa'
      break
    case 5:
      data.prefix = 'Unique'
      data.color = '#ffc200'
      break
    default:
      data.prefix = ''
      data.color = ''
  }

  return data
}