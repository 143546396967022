
import { defineComponent } from "vue";
import { useStore } from "vuex";
import CombatImplants from "./CombatImplants.vue";
import "./level-phase-containers.css";

export default defineComponent({
  name: "Combat Implants",
  components: {
    CombatImplants,
  },
  setup() {
    const store = useStore();
    return {
      state: store.state,
    };
  },
});
