<template>
  <div class="combat-implants-wrapper">
    <div class="combat-implants-titles">
      <div class="combat-implant-title" @mouseover="handleInfoPanelData('Dermal')">DERMAL</div>
      <div class="combat-implant-title" @mouseover="handleInfoPanelData('Phoenix')">PHOENIX</div>
      <div class="combat-implant-title" @mouseover="handleInfoPanelData('Nemean')">NEMEAN</div>
    </div>
    <button
      class="image-btn"
      @mouseover="handleInfoPanelData('Dermal')"
    >
      <img
        :class="getCombatImplantData('Dermal').style"
        src="@/assets/img/implants/dermal.png"
        alt=""
        @click="implantCombat('Dermal')"
      />
      <span class="implant-number">{{ getCombatImplantData('Dermal').level }}</span>
    </button>
    <button
      class="image-btn"
      @mouseover="handleInfoPanelData('Phoenix')"
    >
      <img
        :class="getCombatImplantData('Phoenix').style"
        src="@/assets/img/implants/phoenix.gif"
        alt=""
        @click="implantCombat('Phoenix')"
      />
      <span class="implant-number">{{ getCombatImplantData('Phoenix').level }}</span>
    </button>
    <button
      class="image-btn"
      @mouseover="handleInfoPanelData('Nemean')"
    >
      <img
        :class="getCombatImplantData('Nemean').style"
        src="@/assets/img/implants/nemean.gif"
        alt=""
        @click="implantCombat('Nemean')"
      />
      <span class="implant-number">{{ getCombatImplantData('Nemean').level }}</span>
    </button>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {
  name: 'Combat Implants',
  setup () {
    const store = useStore()
    const showInfo = (category, stat) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const getCombatImplantData = (implant) => {
      const data = {
        level: +store.state.build.CombatImplants[implant + 1].tagged + +store.state.build.CombatImplants[implant + 2].tagged,
        available: store.state.build.CombatImplants[implant + 1].available || store.state.build.CombatImplants[implant + 2].available,
        style: 'image-combat-implant'
      }
      if (data.available && !data.level) {
        data.style += ' available'
      } else if (data.level) {
        if (data.available) {
          data.style += ' available'
        } else {
          data.style += ' implanted'
        }
      } else {
        data.style += ' disabled'
      }
      return data
    }
    const implantCombat = (implant) => {
      const postfix = store.state.build.CombatImplants[implant + 1].available ? '1' : store.state.build.CombatImplants[implant + 2].available ? '2' : '0'
      if (+postfix) {
        const data = {
          implant: implant + postfix
        }
        store.dispatch('handleIncomingCommand', {
        command: 'implantCombat',
        data: data
      });
        showInfo('CombatImplants', implant + 2)
      } else {
        return
      }
    }
    const handleInfoPanelData = (implant) => {
      const implantLevel = getCombatImplantData(implant).level
      if (implantLevel) {
        showInfo('CombatImplants', implant + 2)
      } else {
        showInfo('CombatImplants', implant + 1)
      }
    }
    return {
      state: store.state,
      getCombatImplantData,
      implantCombat,
      showInfo,
      handleInfoPanelData
    }
  }
};
</script>
<style scoped>
.combat-implants-wrapper {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(192, 188, 188, 0.219);
  /* border: 1px solid black; */
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 6px;
}
.combat-implants-titles {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 14.4px;
  margin-bottom: 2px;
}
.combat-implant-title {
  width: 33%;
  max-height: 12px;
  text-align: center;
  font-size: 12px;
  color: #85fc5a;
  text-shadow: 1px 1px rgb(48, 41, 41);
  opacity: 0.8;
}
.image-btn {
  cursor: default;
  float: center;
  position: relative;
  width: 30px;
  height: 23px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: transparent;
  border: none;
}
.implant-number {
  cursor: default;
  position: absolute;
  bottom: -6px;
  right: -5px;
  font-family: "Gothic821 Cn BT", Helvetica, Arial;
  font-size: 16px;
  color: rgb(236, 236, 236);
  -webkit-text-stroke: 1px black;
}
.image-combat-implant {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(60%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(60%) drop-shadow(2px 2px 2px #222);
}
.image-btn:focus {
  outline: none;
  box-shadow: none;
}
.available:hover {
  outline: none;
  box-shadow: none;
  height: 115%;
  width: 115%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
.available {
  cursor: pointer;
  -webkit-filter: grayscale(10%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(10%) drop-shadow(2px 2px 2px #222);
}
.implanted {
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 5px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 5px #222);
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
/* .available:hover {
  height: 90%;
  width: 90%;
  -webkit-filter: contrast(140%) brightness(2) drop-shadow(1px 1px 1px #222);
  filter: contrast(140%) brightness(2) drop-shadow(1px 1px 1px #222);
} */
</style>