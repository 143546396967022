import { Inventory } from '@/data/items/gear/Inventory';
export default {
  info: {
    phase: 'create',
    name: '',
    class: '',
    buildString: '',
    log: [],
    tempModifySkillCommands: [],
    tempLevelUpCommands: [],
    taggedSkills: 0,
    Skills: [],
    taggedTraits: 0,
    Traits: [],
    availableSkillPoints: 0,
    currentPerk: {
      available: false,
      tagged: false,
      nextAtLevel: 0,
      step: 0
    },
    currentLevel: 0,
    marshalBoost: false,
    loadedSave: {}
  },
  EquippedGear: {
    head: { ...Inventory.head.NoHelmet },
    body: { ...Inventory.body.VaultSuit },
    handSlot1: null,
    handSlot2: null,
  },
  SPECIAL: {
    ST: {
      name: 'ST',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    PE: {
      name: 'PE',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    EN: {
      name: 'EN',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    CH: {
      name: 'CH',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    IN: {
      name: 'IN',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    AG: {
      name: 'AG',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    LK: {
      name: 'LK',
      value: 5,
      baseValue: 5,
      onDrugs: 0,
      minValue: 1,
      maxValue: 10,
      implanted: false,
    },
    PL: {
      name: 'PL',
      value: 5,
      baseValue: 5,
      minValue: 0,
    },
  },
  Skills: {
    SmallGuns: {
      name: 'Small Guns',
      short: 'SG',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    BigGuns: {
      name: 'Big Guns',
      short: 'BG',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    EnergyWeapons: {
      name: 'Energy Weapons',
      short: 'EW',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    CloseCombat: {
      name: 'Close Combat',
      short: 'CC',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    Scavenging: {
      name: 'Scavenging',
      short: 'SV',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 100,
      tagged: false,
      selected: false
    },
    Throwing: {
      name: 'Throwing',
      short: 'TH',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    FirstAid: {
      name: 'First Aid',
      short: 'FA',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 200,
      tagged: false,
      selected: false
    },
    Doctor: {
      name: 'Doctor',
      short: 'DR',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 200,
      tagged: false,
      selected: false
    },
    Sneak: {
      name: 'Sneak',
      short: 'SN',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 270,
      tagged: false,
      selected: false
    },
    Lockpick: {
      name: 'Lockpick',
      short: 'LP',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 150,
      tagged: false,
      selected: false
    },
    Steal: {
      name: 'Steal',
      short: 'ST',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 150,
      tagged: false,
      selected: false
    },
    Traps: {
      name: 'Traps',
      short: 'TR',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 150,
      tagged: false,
      selected: false
    },
    Science: {
      name: 'Science',
      short: 'SC',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 125,
      tagged: false,
      selected: false
    },
    Repair: {
      name: 'Repair',
      short: 'RE',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 125,
      tagged: false,
      selected: false
    },
    Speech: {
      name: 'Speech',
      short: 'SP',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 300,
      tagged: false,
      selected: false
    },
    Barter: {
      name: 'Barter',
      short: 'BA',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 150,
      tagged: false,
      selected: false
    },
    Gambling: {
      name: 'Gambling',
      short: 'GA',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 150,
      tagged: false,
      selected: false
    },
    Outdoorsman: {
      name: 'Outdoorsman',
      short: 'OD',
      value: 0,
      baseValue: 0,
      stepSkillValue: 0,
      gainedSkillValue: 0,
      change: 0,
      spentSP: '',
      maxValue: 175,
      tagged: false,
      selected: false
    },
  },
  Traits: {
    FastMetabolism: {
      name: 'Fast Metabolism',
      tagged: false,
    },
    Bruiser: {
      name: 'Bruiser',
      tagged: false,
    },
    OneHander: {
      name: 'One Hander',
      tagged: false,
    },
    Finesse: {
      name: 'Finesse',
      tagged: false,
    },
    Kamikaze: {
      name: 'Kamikaze',
      tagged: false,
    },
    HeavyHanded: {
      name: 'Heavy Handed',
      tagged: false,
    },
    FastShot: {
      name: 'Fast Shot',
      tagged: false,
    },
    BloodyMess: {
      name: 'Bloody Mess',
      tagged: false,
    },
    Jinxed: {
      name: 'Jinxed',
      tagged: false,
    },
    GoodNatured: {
      name: 'Good Natured',
      tagged: false,
    },
    ChemReliant: {
      name: 'Chem Reliant',
      tagged: false,
    },
    Bonehead: {
      name: 'Bonehead',
      tagged: false,
    },
    Skilled: {
      name: 'Skilled',
      tagged: false,
    },
    Loner: {
      name: 'Loner',
      tagged: false,
    },
  },
  Perks: {
    MoreCritical: {
      name: 'More Critical',
      tagged: false,
      available: false,
      level: 3,
      taggedAt: 0
    },
    QuickPockets: {
      name: 'Quick Pockets',
      tagged: false,
      available: false,
      level: 3,
      taggedAt: 0
    },
    AdrenalineRush: {
      name: 'Adrenaline Rush',
      tagged: false,
      available: false,
      level: 3,
      taggedAt: 0
    },
    QuickRecovery: {
      name: 'Quick Recovery',
      tagged: false,
      available: false,
      level: 3,
      taggedAt: 0
    },
    WeaponHandling: {
      name: 'Weapon Handling',
      tagged: false,
      available: false,
      level: 3,
      taggedAt: 0
    },
    InYourFace: {
      name: 'In Your Face',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    EvenMoreCriticals: {
      name: 'Even More Criticals',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    SilentRunning: {
      name: 'Silent Running',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    Toughness: {
      name: 'Toughness',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    Sharpshooter: {
      name: 'Sharpshooter',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    Pyromaniac: {
      name: 'Pyromaniac',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    CloseCombatMaster: {
      name: 'Close Combat Master',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    BonusRangedDamage: {
      name: 'Bonus Ranged Damage',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    EvenTougher: {
      name: 'Even Tougher',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    Stonewall: {
      name: 'Stonewall',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    Medic: {
      name: 'Medic',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    HeaveHo: {
      name: 'HeaveHo',
      tagged: false,
      available: false,
      level: 9,
      taggedAt: 0
    },
    GainAgility: {
      name: 'Gain Agility',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainCharisma: {
      name: 'Gain Charisma',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainEndurance: {
      name: 'Gain Endurance',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainIntelligence: {
      name: 'Gain Intelligence',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainLuck: {
      name: 'Gain Luck',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainPerception: {
      name: 'Gain Perception',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    GainStrength: {
      name: 'Gain Strength',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    BetterCriticals: {
      name: 'Better Criticals',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    Ghost: {
      name: 'Ghost',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    Lifegiver1: {
      name: 'Lifegiver 1',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    ActionBoy1: {
      name: 'Action Boy',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    ActionBoy2: {
      name: 'Action Boy',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    Lifegiver2: {
      name: 'Lifegiver 2',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    Livewire: {
      name: 'Livewire',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    ManОfSteel: {
      name: 'Man Оf Steel',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    FieldMedic: {
      name: 'Field Medic',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    MoreRangedDamage: {
      name: 'More Ranged Damage',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    SilentDeath: {
      name: 'Silent Death',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    IronLimbs: {
      name: 'Iron Limbs',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    Dodger1: {
      name: 'Dodger 1',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
    Dodger2: {
      name: 'Dodger 2',
      tagged: false,
      available: false,
      level: 18,
      taggedAt: 0
    },
    Lifegiver3: {
      name: 'Lifegiver 3',
      tagged: false,
      available: false,
      level: 18,
      taggedAt: 0
    },
    BonusRateОfAttack: {
      name: 'Bonus Rate Оf Attack',
      tagged: false,
      available: false,
      level: 18,
      taggedAt: 0
    },
  },
  SupportPerks: {
    // Awareness
    // PackRat
    // StrongBack
    // MrFixit
    // Dismantler
    // DeadManWalking
    // SwiftLearner
    // FasterHealing
    // RadResistance
    // Educated
    // Snakeater
    // GeckoSkinning
    // CautiousNature
    // Ranger
    // Scout
    // Explorer
    // Pathfinder
    // LightStep
    // DemolitionExpert
    // Negotiator
    // SexAppeal
    // MagneticPersonality
    // Speaker
    // StealthGirl
    // Thief
    // Harmless
    // Pickpocket
    // MasterThief
    // TreasureHunter
    Awareness: {
      name: 'Awareness',
      tagged: true,
      available: false,
      level: 0,
      taggedAt: 0
    },
    PackRat: {
      name: 'Pack Rat',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    StrongBack: {
      name: 'Strong Back',
      tagged: false,
      available: false,
      level: 6,
      taggedAt: 0
    },
    MrFixit: {
      name: 'Mr. Fixit',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Dismantler: {
      name: 'Dismantler',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    DeadManWalking: {
      name: 'Dead Man Walking',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    SwiftLearner: {
      name: 'Swift Learner',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    FasterHealing: {
      name: 'Faster Healing',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    RadResistance: {
      name: 'Rad Resistance',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Educated: {
      name: 'Educated',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Snakeater: {
      name: 'Snakeater',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    GeckoSkinning: {
      name: 'Gecko Skinning',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    CautiousNature: {
      name: 'Cautious Nature',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Ranger: {
      name: 'Ranger',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Scout: {
      name: 'Scout',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Explorer: {
      name: 'Explorer',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Pathfinder: {
      name: 'Pathfinder',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    LightStep: {
      name: 'Light Step',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    DemolitionExpert: {
      name: 'Demolition Expert',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Negotiator: {
      name: 'Negotiator',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    SexAppeal: {
      name: 'Sex Appeal',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    MagneticPersonality: {
      name: 'Magnetic Personality',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Speaker: {
      name: 'Speaker',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    StealthGirl: {
      name: 'Stealth Girl',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Thief: {
      name: 'Thief',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Harmless: {
      name: 'Harmless',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    Pickpocket: {
      name: 'Pickpocket',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    MasterThief: {
      name: 'Master Thief',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    TreasureHunter: {
      name: 'Treasure Hunter',
      tagged: false,
      available: false,
      level: 0,
      taggedAt: 0
    },
    AlcoholRaisedHP1: {
      name: 'Alcohol Raised HP 1',
      tagged: false,
      available: false,
      level: 12,
      taggedAt: 0
    },
    AlcoholRaisedHP2: {
      name: 'Alcohol Raised HP 2',
      tagged: false,
      available: false,
      level: 15,
      taggedAt: 0
    },
  },
  Implants: {
    ST: {
      name: 'Hypertrophy Accelerator',
      tagged: false,
      available: false,
      level: 30
    },
    PE: {
      name: 'Optics Enhancer',
      tagged: false,
      available: false,
      level: 30
    },
    EN: {
      name: 'Nociception Regulator',
      tagged: false,
      available: false,
      level: 30
    },
    CH: {
      name: 'Empathy Synthesizer',
      tagged: false,
      available: false,
      level: 30
    },
    IN: {
      name: 'Logic Co-Processor',
      tagged: false,
      available: false,
      level: 30
    },
    AG: {
      name: 'Reflex Booster',
      tagged: false,
      available: false,
      level: 30
    },
    LK: {
      name: 'Probability Calculator',
      tagged: false,
      available: false,
      level: 30
    },
  },
  CombatImplants: {
    Dermal1: {
      name: 'Dermal Impact Armor',
      tagged: false,
      available: false,
      level: 30
    },
    Phoenix1: {
      name: 'Phoenix Armor Implants',
      tagged: false,
      available: false,
      level: 30
    },
    Nemean1: {
      name: 'Nemean Armor',
      tagged: false,
      available: false,
      level: 30
    },
    Dermal2: {
      name: 'Dermal Impact Assault Enhancement',
      tagged: false,
      available: false,
      level: 40
    },
    Phoenix2: {
      name: 'Phoenix Assault Enhancement',
      tagged: false,
      available: false,
      level: 40
    },
    Nemean2: {
      name: 'Nemean Armor',
      tagged: false,
      available: false,
      level: 40
    },
  },
  Books: {
    SmallGuns: {
      name: 'SmallGuns',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
    FirstAid: {
      name: 'FirstAid',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
    Science: {
      name: 'Science',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
    Repair: {
      name: 'Repair',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
    Barter: {
      name: 'Barter',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
    Outdoorsman: {
      name: 'Outdoorsman',
      available: true,
      read: 0,
      gainedSkillValue: 0,
    },
  },
  Boosts: {
    SmallGuns: {
      name: 'Small Guns',
      available: true,
      tagged: false
    },
    BigGuns: {
      name: 'Big Guns',
      available: true,
      tagged: false
    },
    EnergyWeapons: {
      name: 'Energy Weapons',
      available: true,
      tagged: false
    },
    CloseCombat: {
      name: 'Close Combat',
      available: true,
      tagged: false
    },
    Throwing: {
      name: 'Throwing',
      available: true,
      tagged: false
    },
  },
  Drugs: {
    Buffout: {
      name: 'Buffout',
      tagged: false,
    },
    NukaCola: {
      name: 'NukaCola',
      tagged: false,
    },
    Psycho: {
      name: 'Psycho',
      tagged: false,
    },
    Cigarettes: {
      name: 'Cigarettes',
      tagged: false,
    },
    Beer: {
      name: 'Beer',
      tagged: false,
    },
    Jet: {
      name: 'Jet',
      tagged: false,
    },
    RadX: {
      name: 'RadX',
      tagged: false,
    },
    Mentats: {
      name: 'Mentats',
      tagged: false,
    },
    CharismaBoost: {
      name: 'CharismaBoost',
      tagged: false,
    },
  },
  Professions: {
    SmallGuns1: {
      name: 'Gunsmith (Small Guns) 1',
      tagged: false,
      available: false,
    },
    BigGuns1: {
      name: 'Gunsmith (Big Guns) 1',
      tagged: false,
      available: false,
    },
    EnergyExpert1: {
      name: 'Energy Expert 1',
      tagged: false,
      available: false,
    },
    DemolitionExpert1: {
      name: 'Demolition Expert 1',
      tagged: false,
      available: false,
    },
    Armorer1: {
      name: 'Armor Expert 1',
      tagged: false,
      available: false,
    },
    Doctor1: {
      name: 'Doctor Expert 1',
      tagged: false,
      available: false,
    },
    SmallGuns2: {
      name: 'Gunsmith (Small Guns) 2',
      tagged: false,
      available: false,
    },
    BigGuns2: {
      name: 'Gunsmith (Big Guns) 2',
      tagged: false,
      available: false,
    },
    EnergyExpert2: {
      name: 'Energy Expert 2',
      tagged: false,
      available: false,
    },
    DemolitionExpert2: {
      name: 'Demolition Expert 2',
      tagged: false,
      available: false,
    },
    Armorer2: {
      name: 'Armor Expert 2',
      tagged: false,
      available: false,
    },
    Doctor2: {
      name: 'Doctor Expert 2',
      tagged: false,
      available: false,
    },
  },
  CharStats: {
    HitPoints: {
      name: 'Hit Points',
      short: 'HP',
      gainedValue: 0,
      value: 0
    },
    ArmorClass: {
      name: 'Armor Class',
      short: 'AC',
      value: 0
    },
    ActionPoints: {
      name: 'Action Points',
      short: 'AP',
      value: 0
    },
    MeleeDamage: {
      name: 'Melee Damage',
      short: 'MD',
      value: 0
    },
    DamageResistance: {
      name: 'Damage Resistance',
      short: 'DR',
      value: 0
    },
    PoisonResistance: {
      name: 'Poison Resistance',
      short: 'PR',
      value: 0
    },
    RadResistance: {
      name: 'Rad Resistance',
      short: 'RR',
      value: 0
    },
    CarryWeight: {
      name: 'Carry Weight',
      short: 'CW',
      value: 0
    },
    Sequence: {
      name: 'Sequence',
      short: 'SEQ',
      value: 0
    },
    HealingRate: {
      name: 'Healing Rate',
      short: 'HR',
      value: 0
    },
    CriticalChance: {
      name: 'Critical Chance',
      short: 'CC',
      value: 0
    },
    PartyPoints: {
      name: 'Party Points',
      short: 'PP',
      value: 0
    },
    MaximumPerks: {
      name: 'Maximum Perks',
      short: 'MaxPerks',
      value: 0
    },
    SkillPointsPerLevel: {
      name: 'Skill Points/Level',
      short: 'SP/Lvl',
      value: 0
    },
    HitPointsPerLevel: {
      name: 'Hit Points/Level',
      short: 'HP/Lvl',
      value: 0
    },
    SightRange: {
      name: 'Sight Range',
      short: 'Sight Range',
      value: 0
    }
  },
  AdvancedDR: {
    head: {
      NoDR: 0,
      NoDT: 0,
      LaDR: 0,
      LaDT: 0,
      FiDR: 0,
      FiDT: 0,
      PlDR: 0,
      PlDT: 0,
      ExDR: 0,
      ExDT: 0,
      ElDR: 0,
      ElDT: 0,
    },
    body: {
      NoDR: 0,
      NoDT: 0,
      LaDR: 0,
      LaDT: 0,
      FiDR: 0,
      FiDT: 0,
      PlDR: 0,
      PlDT: 0,
      ExDR: 0,
      ExDT: 0,
      ElDR: 0,
      ElDT: 0,
    }
  },
  MysteryBoosts: {
    FatherTully: {
      name: 'Father Tully',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 30
    },
    Mom: {
      name: 'Mom',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 24
    },
    SadFarmer: {
      name: 'Sad Farmer',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 5
    },
    HubChef: {
      name: 'Hub Chef',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 12
    },
    RaidersDie: {
      name: "Raiders' Die",
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 0
    },
    Francis: {
      name: 'Francis',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 32
    },
    BecomeAMutant: {
      name: 'Become a Mutant',
      available: false,
      tagged: false,
      taggedAt: 0,
      level: 0
    },
  }
};
