
import { useStore } from "vuex";
import { defineComponent, onMounted } from "vue";
import "./level-phase-containers.css";
import Drugs from "./Drugs.vue";
import SelectSkill from "./SelectSkill.vue";
import LevelUp from "./LevelUp.vue";

export default defineComponent({
  name: "Actions",
  components: {
    Drugs,
    SelectSkill,
    LevelUp,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      CharStats: store.state.build.CharStats,
    };
  },
});
