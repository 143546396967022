
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import InventoryItem from "./InventoryItem.vue";

export default defineComponent({
  components: {
    InventoryItem
},
  name: "LoadMenu",
  setup() {
    const store = useStore();
    const panelState = ref('default')
    const activeSection = ref("Helmets");
    const toggleActiveSection = (value: string) => {
      activeSection.value = value;
    };
    const closePanel = () => {
      store.dispatch('showDefaultCharStats')
    }
    return {
      state: store.state,
      panelState,
      closePanel,
      activeSection,
      toggleActiveSection
    };
  },
});
