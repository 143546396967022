<template>
  <div class="drugs-container">
    <button class="image-btn" v-for="drug in Drugs" :key="drug" @mouseover="showInfo(drug)">
        <img
          :class="isTagged(drug) ? 'image-drug-tagged' : 'image-drug'"
          :src="getDrugImage(drug)"
          alt=""
          @click="toggleDrug(drug)"
        />
    </button>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {
  name: 'Drugs',
  setup () {
    const store = useStore();
    const showInfo = (drug) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category: 'Drugs', stat: drug }
      });
    };
    const isTagged = (drug) => {
      return store.state.build.Drugs[drug]?.tagged
    }
    const getDrugImage = (drug) => {
      return require(`@/assets/img/drugs/${drug}.png`)
    }
    const toggleDrug = (drug) => {
      store.dispatch('handleIncomingCommand', {
        command: 'toggleDrug',
        data: { drug }
      });
      
    }
    return {
      state: store.state,
      Drugs: Object.keys(store.state.build.Drugs).slice(0,-1),
      isTagged,
      getDrugImage,
      toggleDrug,
      showInfo
    }
  }
};
</script>
<style>
.drugs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(192, 188, 188, 0.219);
  border: 1px solid rgba(255, 255, 255, 0.245);
  border-radius: 5px;
  height: 110px;
  padding-top: 6px;
}
.image-btn {
  cursor: pointer;
  float: left;
  width: 47px;
  height: 34px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: transparent;
  border: none;
}
.image-drug {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(70%) drop-shadow(2px 2px 2px #222);
  filter: grayscale(70%) drop-shadow(2px 2px 2px #222);
  /* margin-left: 6px; */
  border: 1px transparent;
}
.image-drug-tagged {
  height: 80%;
  width: 80%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  /* margin-left: 6px; */
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.245);
  background-color: rgba(94, 121, 87, 0.404);
  padding: 3px;
}
.image-btn:focus {
  outline: none;
  box-shadow: none;
}
.image-drug:hover {
  outline: none;
  box-shadow: none;
  height: 115%;
  width: 115%;
  -webkit-filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(1.5) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
.image-drug-tagged:hover {
  outline: none;
  box-shadow: none;
  /* height: 95%;
  width: 95%; */
  -webkit-filter: contrast(120%) brightness(2) drop-shadow(1px 1px 1px #222);
  filter: contrast(120%) brightness(2) drop-shadow(1px 1px 1px #222);
  cursor: pointer;
}
</style>