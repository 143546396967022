<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 225 225"
    id="updates-icon-svg"
  >
    <path
      :fill="show ? '#85fc5a' : '#dbdb34'" stroke="black" stroke-width="1"
      d="M 105.00,29.21
        C 92.36,33.30 85.16,42.87 85.00,56.00
          85.00,56.00 85.00,167.00 85.00,167.00
          85.03,184.75 94.88,195.97 113.00,196.00
          113.00,196.00 177.00,196.00 177.00,196.00
          196.59,195.97 205.97,183.82 206.00,165.00
          206.00,165.00 206.00,61.00 206.00,61.00
          205.99,56.07 205.84,52.83 204.51,48.00
          201.92,38.57 193.70,30.96 184.00,29.21
          184.00,29.21 167.00,29.21 167.00,29.21
          167.00,29.21 143.00,29.21 143.00,29.21
          143.00,29.21 105.00,29.21 105.00,29.21 Z
        M 75.00,187.00
        C 75.00,187.00 75.00,169.00 75.00,169.00
          67.12,166.31 65.10,161.89 65.00,154.00
          65.00,154.00 65.00,72.00 65.00,72.00
          65.01,63.59 66.20,59.00 75.00,56.00
          75.00,56.00 75.00,38.00 75.00,38.00
          56.02,38.89 47.03,52.06 47.00,70.00
          47.00,70.00 47.00,155.00 47.00,155.00
          47.03,172.45 56.25,186.58 75.00,187.00 Z
        M 109.04,47.51
        C 114.36,46.55 126.02,47.00 132.00,47.00
          132.00,47.00 174.00,47.00 174.00,47.00
          176.84,47.00 180.39,46.79 182.95,48.17
          190.18,52.08 188.00,67.77 188.00,75.00
          188.00,75.00 188.00,163.00 188.00,163.00
          187.98,173.66 186.68,177.94 175.00,178.00
          175.00,178.00 116.00,178.00 116.00,178.00
          113.54,178.00 110.36,178.15 108.09,177.15
          100.80,173.91 103.00,159.76 103.00,153.00
          103.00,153.00 103.00,78.00 103.00,78.00
          103.00,73.17 102.37,55.45 103.85,52.09
          105.07,49.34 106.49,48.68 109.04,47.51 Z
        M 37.00,168.00
        C 37.00,168.00 37.00,57.00 37.00,57.00
          22.25,57.72 19.15,68.13 19.00,81.00
          19.00,81.00 19.00,145.00 19.00,145.00
          19.02,158.47 21.55,167.65 37.00,168.00 Z
        M 146.00,132.00
        C 144.33,126.29 143.15,109.42 143.00,103.00
          143.00,103.00 141.28,84.00 141.28,84.00
          140.97,81.68 140.98,78.36 139.26,76.60
          137.49,74.79 134.34,75.02 132.00,75.00
          129.49,74.98 123.64,74.46 122.04,76.60
          120.26,78.96 122.81,88.92 123.39,92.00
          123.39,92.00 131.57,135.00 131.57,135.00
          132.27,139.22 132.90,150.59 136.43,152.98
          137.97,154.02 140.21,153.96 142.00,154.00
          145.50,154.06 152.92,154.64 155.58,152.40
          158.93,149.59 159.53,141.24 160.13,137.00
          160.13,137.00 166.75,98.00 166.75,98.00
          166.75,98.00 169.12,83.00 169.12,83.00
          169.53,81.12 170.21,78.25 169.12,76.60
          167.69,74.91 164.89,75.04 163.00,75.00
          163.00,75.00 159.00,75.00 159.00,75.00
          156.81,75.04 154.28,74.96 152.58,76.60
          150.72,78.39 150.33,82.53 150.05,85.00
          150.05,85.00 148.09,105.00 148.09,105.00
          148.09,105.00 146.00,132.00 146.00,132.00 Z"
      />
  </svg>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
 name: "VersionButton",
 props: ['show']
})
</script>

<style scoped>
#updates-icon-svg {
  width: 24px;
  height: 24px;
}
#updates-icon-svg:hover {
  cursor: pointer;
}
#updates-icon-svg:hover > path {
  fill: #eeee99;
}
</style>