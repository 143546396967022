
import { defineComponent } from "vue";
import "./level-phase-containers.css";

export default defineComponent({
  name: "Header",
  props: {
    msg: String,
  },
});
