import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lp-navmenu-left-container box-shadow-transition" }
const _hoisted_2 = { class: "panel-bg" }
const _hoisted_3 = { class: "lp-info-panel-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, _toDisplayString('g.e.c.k. v' + _ctx.latestUpdate.version), 1)
    ])
  ]))
}