
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
// import Checkbox from "@/components/desktop/subcomponents/Checkbox.vue";
import "./level-phase-containers.css";
// import Books from "@/components/desktop/level/Books.vue";
import SkillsSubPanel from "./SkillsSubPanel.vue";
import Selectbox from "../subcomponents/Selectbox.vue";

export default defineComponent({
  name: "Skills",
  components: {
    // Checkbox,
    // Books,
    SkillsSubPanel,
    Selectbox,
  },

  setup() {
    const store = useStore();
    const showInfo = (category: string, stat: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'updateInfoPanel',
        data: { category, stat }
      });
    };
    const selectSkill = (skill: string) => {
      store.dispatch('handleIncomingCommand', {
        command: 'selectSkill',
        data: skill
      });
    }
    const getSkillValue = (skill) => {
      return store.state.build.Skills[skill].value
    }
    onMounted(() => {
      store.dispatch('handleIncomingCommand', {
        command: 'refreshStats',
        data: null
      });
    });
    return {
      state: store.state,
      Skills: store.state.build.Skills,
      showInfo,
      selectSkill,
      getSkillValue
    };
  },
});
