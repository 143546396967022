const encodeCommandsMap = {
  createChar: '1',
  modifySkill: '2',
  modifySkillbook: '3',
  modifySkillboost: '4',
  levelUp: '5',
  tagPerkPerks: '6',
  tagPerkSupportPerks: '7',
  tagPerkMysteryBoosts: '8',
  implantSPECIAL: '9',
  implantCombat: 'a',
  takeProfession: 'b',
}
const decodeCommandsMap = {
  '1': 'createChar',
  '2': 'modifySkill',
  '3': 'modifySkillbook',
  '4': 'modifySkillboost',
  '5': 'levelUp',
  '6': 'tagPerkPerks',
  '7': 'tagPerkSupportPerks',
  '8': 'tagPerkMysteryBoosts',
  '9': 'implantSPECIAL',
  'a': 'implantCombat',
  'b': 'takeProfession',
}

const encodeSkillsMap = {
  SmallGuns: '1',
  BigGuns: '2',
  EnergyWeapons: '3',
  CloseCombat: '4',
  Scavenging: '5',
  Throwing: '6',
  FirstAid: '7',
  Doctor: '8',
  Sneak: '9',
  Lockpick: 'a',
  Steal: 'b',
  Traps: 'c',
  Science: 'd',
  Repair: 'e',
  Speech: 'f',
  Barter: 'g',
  Gambling: 'h',
  Outdoorsman: 'i'
}
const decodeSkillsMap = {
  '1': 'SmallGuns',
  '2': 'BigGuns',
  '3': 'EnergyWeapons',
  '4': 'CloseCombat',
  '5': 'Scavenging',
  '6': 'Throwing',
  '7': 'FirstAid',
  '8': 'Doctor',
  '9': 'Sneak',
  'a': 'Lockpick',
  'b': 'Steal',
  'c': 'Traps',
  'd': 'Science',
  'e': 'Repair',
  'f': 'Speech',
  'g': 'Barter',
  'h': 'Gambling',
  'i': 'Outdoorsman'
}

const encodeSpecialMap = {
  ST: '1',
  PE: '2',
  EN: '3',
  CH: '4',
  IN: '5',
  AG: '6',
  LK: '7',
}
const decodeSpecialMap = {
  '1': 'ST',
  '2': 'PE',
  '3': 'EN',
  '4': 'CH',
  '5': 'IN',
  '6': 'AG',
  '7': 'LK',
}
const encodeTraitsMap = {
  FastMetabolism: '1',
  Bruiser: '2',
  OneHander: '3',
  Finesse: '4',
  Kamikaze: '5',
  HeavyHanded: '6',
  FastShot: '7',
  BloodyMess: '8',
  Jinxed: '9',
  GoodNatured: 'a',
  ChemReliant: 'b',
  Bonehead: 'c',
  Skilled: 'd',
  Loner: 'e',
}
const decodeTraitsMap = {
  '1': 'FastMetabolism',
  '2': 'Bruiser',
  '3': 'OneHander',
  '4': 'Finesse',
  '5': 'Kamikaze',
  '6': 'HeavyHanded',
  '7': 'FastShot',
  '8': 'BloodyMess',
  '9': 'Jinxed',
  'a': 'GoodNatured',
  'b': 'ChemReliant',
  'c': 'Bonehead',
  'd': 'Skilled',
  'e': 'Loner',
}
const encodeCombatImplantsMap = {
  Dermal1: '1',
  Phoenix1: '2',
  Nemean1: '3',
  Dermal2: '4',
  Phoenix2: '5',
  Nemean2: '6',
}
const decodeCombatImplantsMap = {
  '1': 'Dermal1',
  '2': 'Phoenix1',
  '3': 'Nemean1',
  '4': 'Dermal2',
  '5': 'Phoenix2',
  '6': 'Nemean2',
}
const encodeProfessionsMap = {
  SmallGuns1: '1',
  BigGuns1: '2',
  EnergyExpert1: '3',
  DemolitionExpert1: '4',
  Armorer1: '5',
  Doctor1: '6',
  SmallGuns2: '7',
  BigGuns2: '8',
  EnergyExpert2: '9',
  DemolitionExpert2: 'a',
  Armorer2: 'b',
  Doctor2: 'c',
}
const decodeProfessionsMap = {
  '1': 'SmallGuns1',
  '2': 'BigGuns1',
  '3': 'EnergyExpert1',
  '4': 'DemolitionExpert1',
  '5': 'Armorer1',
  '6': 'Doctor1',
  '7': 'SmallGuns2',
  '8': 'BigGuns2',
  '9': 'EnergyExpert2',
  'a': 'DemolitionExpert2',
  'b': 'Armorer2',
  'c': 'Doctor2',
}

const encodePerksMap = {
  MoreCritical: '1',
  QuickPockets: '2',
  AdrenalineRush: '3',
  QuickRecovery: '4',
  WeaponHandling: '5',
  InYourFace: '6',
  EvenMoreCriticals: '7',
  SilentRunning: '8',
  Toughness: '9',
  Sharpshooter: 'a',
  Pyromaniac: 'b',
  CloseCombatMaster: 'c',
  BonusRangedDamage: 'd',
  EvenTougher: 'e',
  Stonewall: 'f',
  Medic: 'g',
  HeaveHo: 'h',
  GainAgility: 'i',
  GainCharisma: 'j',
  GainEndurance: 'k',
  GainIntelligence: 'l',
  GainLuck: 'm',
  GainPerception: 'n',
  GainStrength: 'o',
  BetterCriticals: 'p',
  Ghost: 'q',
  Lifegiver1: 'r',
  ActionBoy1: 's',
  ActionBoy2: 't',
  Lifegiver2: 'u',
  Livewire: 'v',
  ManОfSteel: 'w',
  FieldMedic: 'x',
  MoreRangedDamage: 'y',
  SilentDeath: 'z',
  IronLimbs: '10',
  Dodger1: '11',
  Dodger2: '12',
  Lifegiver3: '13',
  BonusRateОfAttack: '14',
}
const decodePerksMap = {
  '1': 'MoreCritical',
  '2': 'QuickPockets',
  '3': 'AdrenalineRush',
  '4': 'QuickRecovery',
  '5': 'WeaponHandling',
  '6': 'InYourFace',
  '7': 'EvenMoreCriticals',
  '8': 'SilentRunning',
  '9': 'Toughness',
  'a': 'Sharpshooter',
  'b': 'Pyromaniac',
  'c': 'CloseCombatMaster',
  'd': 'BonusRangedDamage',
  'e': 'EvenTougher',
  'f': 'Stonewall',
  'g': 'Medic',
  'h': 'HeaveHo',
  'i': 'GainAgility',
  'j': 'GainCharisma',
  'k': 'GainEndurance',
  'l': 'GainIntelligence',
  'm': 'GainLuck',
  'n': 'GainPerception',
  'o': 'GainStrength',
  'p': 'BetterCriticals',
  'q': 'Ghost',
  'r': 'Lifegiver1',
  's': 'ActionBoy1',
  't': 'ActionBoy2',
  'u': 'Lifegiver2',
  'v': 'Livewire',
  'w': 'ManОfSteel',
  'x': 'FieldMedic',
  'y': 'MoreRangedDamage',
  'z': 'SilentDeath',
  '10': 'IronLimbs',
  '11': 'Dodger1',
  '12': 'Dodger2',
  '13': 'Lifegiver3',
  '14': 'BonusRateОfAttack',
}
const encodeSupportPerksMap = {
  Awareness: '1',
  PackRat: '2',
  StrongBack: '3',
  MrFixit: '4',
  Dismantler: '5',
  DeadManWalking: '6',
  SwiftLearner: '7',
  FasterHealing: '8',
  RadResistance: '9',
  Educated: 'a',
  Snakeater: 'b',
  GeckoSkinning: 'c',
  CautiousNature: 'd',
  Ranger: 'e',
  Scout: 'f',
  Explorer: 'g',
  Pathfinder: 'h',
  LightStep: 'i',
  DemolitionExpert: 'j',
  Negotiator: 'k',
  SexAppeal: 'l',
  MagneticPersonality: 'm',
  Speaker: 'n',
  StealthGirl: 'o',
  Thief: 'p',
  Harmless: 'q',
  Pickpocket: 'r',
  MasterThief: 's',
  TreasureHunter: 't',
  AlcoholRaisedHP1: 'u',
  AlcoholRaisedHP2: 'v',
}
const decodeSupportPerksMap = {
  '1': 'Awareness',
  '2': 'PackRat',
  '3': 'StrongBack',
  '4': 'MrFixit',
  '5': 'Dismantler',
  '6': 'DeadManWalking',
  '7': 'SwiftLearner',
  '8': 'FasterHealing',
  '9': 'RadResistance',
  'a': 'Educated',
  'b': 'Snakeater',
  'c': 'GeckoSkinning',
  'd': 'CautiousNature',
  'e': 'Ranger',
  'f': 'Scout',
  'g': 'Explorer',
  'h': 'Pathfinder',
  'i': 'LightStep',
  'j': 'DemolitionExpert',
  'k': 'Negotiator',
  'l': 'SexAppeal',
  'm': 'MagneticPersonality',
  'n': 'Speaker',
  'o': 'StealthGirl',
  'p': 'Thief',
  'q': 'Harmless',
  'r': 'Pickpocket',
  's': 'MasterThief',
  't': 'TreasureHunter',
  'u': 'AlcoholRaisedHP1',
  'v': 'AlcoholRaisedHP2',
}
const encodeMysteryBoostsMap = {
  FatherTully: '1',
  Mom: '2',
  SadFarmer: '3',
  HubChef: '4',
  RaidersDie: '5',
  Francis: '6',
  BecomeAMutant: '7',
}
const decodeMysteryBoostsMap = {
  '1': 'FatherTully',
  '2': 'Mom',
  '3': 'SadFarmer',
  '4': 'HubChef',
  '5': 'RaidersDie',
  '6': 'Francis',
  '7': 'BecomeAMutant',
}

const encodeHeadGear = {
  NoHelmet: '1',
  LeatherHelmet: '2',
  CombatLeatherHelmet: '3',
  LeatherArmorHelmet: '4',
  LeatherArmorHelmetMark2: '5',
  MetalMask: '6',
  MetalMaskMark2: '7',
  TeslaHelmet: '8',
  CombatHelmet: '9',
  CombatHelmetMark2: 'a',
  BrotherhoodCombatHelmet: 'b',
  NCRRangerHelmet: 'c',
  DesertCombatHelmet: 'd',
  EnclaveCombatHelmet: 'e',
  EnclaveCombatHelmetMark2: 'f',
  PowerArmorHelmet: 'g',
  AdvancedPowerArmorHelmet: 'h',
  Goggles: 'i',
  MirroredShades: 'j',
  Spectacles: 'k',
  BallGag: 'l'
}
const decodeHeadGear = {
  '1': 'NoHelmet',
  '2': 'LeatherHelmet',
  '3': 'CombatLeatherHelmet',
  '4': 'LeatherArmorHelmet',
  '5': 'LeatherArmorHelmetMark2',
  '6': 'MetalMask',
  '7': 'MetalMaskMark2',
  '8': 'TeslaHelmet',
  '9': 'CombatHelmet',
  'a': 'CombatHelmetMark2',
  'b': 'BrotherhoodCombatHelmet',
  'c': 'NCRRangerHelmet',
  'd': 'DesertCombatHelmet',
  'e': 'EnclaveCombatHelmet',
  'f': 'EnclaveCombatHelmetMark2',
  'g': 'PowerArmorHelmet',
  'h': 'AdvancedPowerArmorHelmet',
  'i': 'Goggles',
  'j': 'MirroredShades',
  'k': 'Spectacles',
  'l': 'BallGag'
}

const encodeBodyGear = {
  VaultSuit: '1',
  Robe: '2',
  PurpleRobe: '3',
  BlackRobe: '4',
  PoliceUniform: '5',
  Pants: '6',
  LeatherJacket: '7',
  CombatLeatherJacket: '8',
  LeatherArmor: '9',
  LeatherArmorMark2: 'a',
  ThermalLeatherArmor: 'b',
  MetalJacket: 'c',
  MetalArmor: 'd',
  MetalArmorMark2: 'e',
  TeslaArmor: 'f',
  BridgekeepersRobes: 'g',
  CombatArmor: 'h',
  NCRRangerArmor: 'i',
  DesertCombatArmor: 'j',
  CombatArmorMark2: 'k',
  BrotherhoodArmor: 'l',
  EnclaveArmor: 'm',
  PowerArmor: 'n',
  HardenedPowerArmor: 'o',
  AdvancedPowerArmor: 'p',
  AdvancedPowerArmorMark2: 'q'
}
const decodeBodyGear = {
  '1': 'VaultSuit',
  '2': 'Robe',
  '3': 'PurpleRobe',
  '4': 'BlackRobe',
  '5': 'PoliceUniform',
  '6': 'Pants',
  '7': 'LeatherJacket',
  '8': 'CombatLeatherJacket',
  '9': 'LeatherArmor',
  'a': 'LeatherArmorMark2',
  'b': 'ThermalLeatherArmor',
  'c': 'MetalJacket',
  'd': 'MetalArmor',
  'e': 'MetalArmorMark2',
  'f': 'TeslaArmor',
  'g': 'BridgekeepersRobes',
  'h': 'CombatArmor',
  'i': 'NCRRangerArmor',
  'j': 'DesertCombatArmor',
  'k': 'CombatArmorMark2',
  'l': 'BrotherhoodArmor',
  'm': 'EnclaveArmor',
  'n': 'PowerArmor',
  'o': 'HardenedPowerArmor',
  'p': 'AdvancedPowerArmor',
  'q': 'AdvancedPowerArmorMark2'
}


const encodeDrugs = {
  'Buffout': '1',
  'NukaCola': '2',
  'Psycho': '3',
  'Cigarettes': '4',
  'Beer': '5',
  'Jet': '6',
  'RadX': '7',
  'Mentats': '8',
  'CharismaBoost': '9',
}

const decodeDrugs = {
  '1': 'Buffout',
  '2': 'NukaCola',
  '3': 'Psycho',
  '4': 'Cigarettes',
  '5': 'Beer',
  '6': 'Jet',
  '7': 'RadX',
  '8': 'Mentats',
  '9': 'CharismaBoost',
}

const drugsList = [
  'Buffout',
  'NukaCola',
  'Psycho',
  'Cigarettes',
  'Beer',
  'Jet',
  'RadX',
  'Mentats',
  'CharismaBoost'
]

const dict = {
  encode: {
    Commands: encodeCommandsMap,
    SPECIAL: encodeSpecialMap,
    Skills: encodeSkillsMap,
    Traits: encodeTraitsMap,
    Perks: encodePerksMap,
    SupportPerks: encodeSupportPerksMap,
    MysteryBoosts: encodeMysteryBoostsMap,
    Professions: encodeProfessionsMap,
    CombatImplants: encodeCombatImplantsMap,
    HeadGear: encodeHeadGear,
    BodyGear: encodeBodyGear,
    Drugs: encodeDrugs
  },
  decode: {
    Commands: decodeCommandsMap,
    SPECIAL: decodeSpecialMap,
    Skills: decodeSkillsMap,
    Traits: decodeTraitsMap,
    Perks: decodePerksMap,
    SupportPerks: decodeSupportPerksMap,
    MysteryBoosts: decodeMysteryBoostsMap,
    Professions: decodeProfessionsMap,
    CombatImplants: decodeCombatImplantsMap,
    HeadGear: decodeHeadGear,
    BodyGear: decodeBodyGear,
    Drugs: decodeDrugs
  },
  drugsList
}

export default dict;